import React from "react";
import { X, Trash2, XIcon, MinusIcon, PlusIcon } from "lucide-react";

import { useMediaQuery } from "../../hooks/use-media-query";
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
} from "../../components/ui/drawer";
import LoadingSpinner from "../../components/LoadingSpinner";

const ZerodhaReviewModal = ({
  calculateTotalAmount,
  getLTPForSymbol,
  stockDetails,
  setStockDetails,
  setOpenZerodhaModel,
  loading,
  handleZerodhaRedirect,
  openZerodhaModel,
  getCartAllStocks,
}) => {
  const handleDecreaseStockQty = (symbol, tradeId) => {
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: Math.max(stock.quantity - 1, 0) }
        : stock
    );
    setStockDetails(newData);
  };

  const handleIncreaseStockQty = (symbol, tradeId) => {
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: stock.quantity + 1 }
        : stock
    );
    setStockDetails(newData);
  };

  const handleQuantityInputChange = (symbol, value, tradeId) => {
    const newQuantity = parseInt(value) || 0;
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: newQuantity }
        : stock
    );
    setStockDetails(newData);
  };

  const handleRemoveStock = (symbol, tradeId) => {
    setStockDetails(
      stockDetails.filter(
        (stock) => stock.tradingSymbol !== symbol || stock.tradeId !== tradeId
      )
    );
  };

  const isDesktop = useMediaQuery("(min-width: 1199px)");

  if (isDesktop) {
    return (
      <Dialog open={openZerodhaModel} onOpenChange={setOpenZerodhaModel}>
        <DialogContent className="lg:max-w-[65vw] w-full p-0 animate-modal">
          {/* <div
            onClick={() => {
              setOpenZerodhaModel(false);
              getCartAllStocks();
            }}
            className="bg-white absolute top-4 right-3 flex items-center justify-center "
          >
            <XIcon className="h-5 w-5 text-gray-900" />
          </div> */}
          <div className="flex flex-col  w-full  rounded-[8px] pt-4  h-[630px] lg:h-auto bg-[#ffffff] ">
            <div className="px-6 flex flex-row justify-between items-center">
              <div className="text-xl text-black font-semibold leading-[40px] font-sans">
                Review Zerodha Trade Details
              </div>
            </div>

            {/* Stock details table */}
            <div className="w-full h-full border-t-[1px] border-[#000000]/10  lg:h-[400px]  overflow-auto custom-scroll">
              <table className="w-full">
                {/* Table header */}
                <thead className="bg-[#f5f5f5]  sticky top-0 z-20 ">
                  <tr className="border-b-[1px]  border-[#000000]/10">
                    <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                      Stocks
                    </th>
                    <th className="text-[12px] min-w-[120px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                      Current Price (₹)
                    </th>
                    <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                      Order
                    </th>
                    <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                      Quantity
                    </th>
                    <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                      Order Type
                    </th>
                    <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                      Delete
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody>
                  {stockDetails.map((stock, i) => (
                    <tr
                      key={i}
                      className="border-b-[1px] border-[#000000]/10 last-of-type:border-none"
                    >
                      <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8">
                        {stock.tradingSymbol}
                      </td>
                      <td className="text-[15px] text-[#000000]/80 font-poppins font-normal text-center py-3 px-5">
                        {getLTPForSymbol(stock.tradingSymbol)}
                      </td>
                      <td
                        className={
                          stock?.transactionType?.toLowerCase() === "buy"
                            ? "text-[#16A085] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                            : stock?.transactionType?.toLowerCase() === "sell"
                            ? "text-[#E43D3D] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                            : "text-[#000000]/80 text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                        }
                      >
                        {stock.transactionType?.toLowerCase()}
                      </td>
                      <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-2 lg:px-5">
                        <div className="flex flex-row items-center justify-between text-[12px] text-[#000000]/80 font-poppins">
                          <button
                            onClick={() =>
                              handleDecreaseStockQty(
                                stock.tradingSymbol,
                                stock.tradeId
                              )
                            }
                            disabled={stock.quantity <= 1}
                            className="cursor-pointer disabled:cursor-not-allowed hover:bg-black hover:text-white px-1 rounded-[4px]"
                          >
                            -
                          </button>
                          <input
                            type="text"
                            value={stock.quantity}
                            onChange={(e) =>
                              handleQuantityInputChange(
                                stock.tradingSymbol,
                                e.target.value,
                                stock.tradeId
                              )
                            }
                            className="flex flex-1 items-center justify-center w-[45px] h-[28px] font-poppins text-[15px] mx-1 text-center border border-gray-300 rounded"
                          />
                          <button
                            onClick={() =>
                              handleIncreaseStockQty(
                                stock.tradingSymbol,
                                stock.tradeId
                              )
                            }
                            className="cursor-pointer hover:bg-black hover:text-white px-1 rounded-[4px]"
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5">
                        {stock.orderType}
                        {stock.orderType === "LIMIT" ||
                        stock.orderType === "STOP" ? (
                          <span>({stock.price})</span>
                        ) : null}
                      </td>
                      <td className="text-[15px] text-[#000000]/80 font-poppins font-medium flex flex-row justify-center text-center py-3 px-3 lg:py-4 lg:px-5">
                        <Trash2
                          size={18}
                          className="cursor-pointer hover:text-[#ff0000]"
                          onClick={() =>
                            handleRemoveStock(
                              stock.tradingSymbol,
                              stock.tradeId
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <DialogFooter className="sm:justify-between flex px-4 pt-4 pb-12  shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.06)]">
            <div className=" flex flex-row items-center">
              <div className=" text-[15px] leading-[22px] text-black font-semibold lg:text-base lg:leading-[40px] font-sans">
                Confirm Total Amount :
              </div>
              <div className="px-4 py-1 ml-6 lg:py-1 lg:px-8 text-lg text-[#000000]/80 font-bold border border-[#000000]/20 rounded-md">
                ₹{calculateTotalAmount()}
              </div>
            </div>

            <button
              className="w-[180px]  px-8 py-3 ml-4 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
              onClick={handleZerodhaRedirect}
            >
              {loading === true ? (
                <LoadingSpinner />
              ) : (
                <span className="text-[18px] font-medium text-[#ffffff] font-poopins ">
                  Place Order
                </span>
              )}
            </button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={openZerodhaModel} onOpenChange={setOpenZerodhaModel}>
      <DrawerContent className="focus:outline-none max-h-[550px] md:max-h-[690px]">
        <div
          onClick={() => {
            setOpenZerodhaModel(false);
            getCartAllStocks();
          }}
          className="absolute top-4 right-3 flex items-center justify-center "
        >
          <XIcon className="h-5 w-5 text-gray-900" />
        </div>

        <div className="pt-4 h-full overflow-y-auto">
          <div className="px-6 flex flex-row justify-between items-center">
            <div className="text-xl text-black font-semibold leading-[40px] font-sans">
              Review Zerodha Trade Details
            </div>
          </div>

          <div className="px-6 flex flex-row items-center">
            <div className="text-[15px] leading-[22px] text-black font-semibold lg:text-base lg:leading-[40px] font-sans">
              Confirm Total Amount :
            </div>
            <div className="px-4 py-1 ml-6 lg:py-2 lg:px-8 text-lg text-[#000000]/80 font-bold border border-[#000000]/20 rounded-md">
              ₹{calculateTotalAmount()}
            </div>
          </div>

          {/* Stock details table */}
          <div className="w-full border-t-[1px] border-[#000000]/10  h-[400px] mt-8 overflow-auto custom-scroll">
            {/* <table className="w-full">
              <thead className="bg-[#f5f5f5]  sticky top-0 z-20 ">
                <tr className="border-b-[1px] border-[#000000]/10">
                  <th className="text-[12px]  text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                    Stocks
                  </th>
                  <th className="text-[12px] min-w-[120px]  text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Current Price (₹)
                  </th>
                  <th className="text-[12px]  text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Order
                  </th>
                  <th className="text-[12px]  text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Quantity
                  </th>
                  <th className="text-[12px] min-w-[90px]  text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Order Type
                  </th>
                  <th className="text-[12px] min-w-[90px]  text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {stockDetails.map((stock, i) => (
                  <tr
                    key={i}
                    className="border-b-[1px] border-[#000000]/10 last-of-type:border-none"
                  >
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8">
                      {stock.tradingSymbol}
                    </td>
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-normal text-center py-3 px-5">
                      {getLTPForSymbol(stock.tradingSymbol)}
                    </td>
                    <td
                      className={
                        stock?.transactionType?.toLowerCase() === "buy"
                          ? "text-[#16A085] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                          : stock?.transactionType?.toLowerCase() === "sell"
                          ? "text-[#E43D3D] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                          : "text-[#000000]/80 text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                      }
                    >
                      {stock.transactionType?.toLowerCase()}
                    </td>
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-2 lg:px-5">
                      <div className="flex flex-row items-center justify-between text-[12px] text-[#000000]/80 font-poppins">
                        <button
                          onClick={() =>
                            handleDecreaseStockQty(
                              stock.tradingSymbol,
                              stock.tradeId
                            )
                          }
                          disabled={stock.quantity <= 1}
                          className="cursor-pointer disabled:cursor-not-allowed hover:bg-black hover:text-white px-1 rounded-[4px]"
                        >
                          -
                        </button>
                        <input
                          type="text"
                          value={stock.quantity}
                          onChange={(e) =>
                            handleQuantityInputChange(
                              stock.tradingSymbol,
                              e.target.value,
                              stock.tradeId
                            )
                          }
                          className="flex flex-1 items-center justify-center w-[45px] h-[28px] font-poppins text-[15px] mx-1 text-center border border-gray-300 rounded"
                        />
                        <button
                          onClick={() =>
                            handleIncreaseStockQty(
                              stock.tradingSymbol,
                              stock.tradeId
                            )
                          }
                          className="cursor-pointer hover:bg-black hover:text-white px-1 rounded-[4px]"
                        >
                          +
                        </button>
                      </div>
                    </td>
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5">
                      {stock.orderType}
                      {stock.orderType === "LIMIT" ||
                      stock.orderType === "STOP" ? (
                        <span>({stock.price})</span>
                      ) : null}
                    </td>
                    <td className="text-[15px] text-[#000000]/80 font-poppins font-medium flex flex-row justify-center text-center py-3 px-3 lg:py-4 lg:px-5">
                      <Trash2
                        size={18}
                        className="cursor-pointer hover:text-[#ff0000]"
                        onClick={() =>
                          handleRemoveStock(stock.tradingSymbol, stock.tradeId)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> */}

            {stockDetails.map((ele) => (
              <div className="flex items-center  border-b-[1px] border-[#000000]/10  py-4 px-4 bg-white font-poppins">
                <div className="flex flex-1 items-start flex-col space-y-[6px] overflow-hidden">
                  <div className="text-[14px] truncate w-full   text-[#000000]/80 font-poppins font-semibold">
                    {ele.tradingSymbol}
                  </div>
                  <div
                    className={`${
                      ele.transactionType?.toLowerCase() === "buy"
                        ? "bg-[#16A085]/10 text-[#16A085] "
                        : "bg-[#EA2D3F]/10 text-[#EA2D3F]"
                    } flex items-center px-2 py-[1px] rounded-[4px]`}
                  >
                    <span className=" text-[14px] lg:text-[12px]  px-1 py-0  font-poppins font-medium capitalize">
                      {ele.transactionType?.toLowerCase()}
                    </span>
                  </div>
                </div>

                <div className="flex items-center justify-between pr-4 text-[12px] text-[#000000]/80 font-poppins">
                  <button
                    onClick={() =>
                      handleDecreaseStockQty(ele.tradingSymbol, ele.tradeId)
                    }
                    disabled={ele.quantity <= 1}
                    className="cursor-pointer disabled:cursor-not-allowed hover:bg-black hover:text-white px-1 py-[2px] rounded-[3px]"
                  >
                    <MinusIcon className=" h-3 w-3" />
                  </button>

                  <input
                    type="text"
                    value={ele.quantity}
                    onChange={(e) =>
                      handleQuantityInputChange(
                        ele.tradingSymbol,
                        e.target.value,
                        ele.tradeId
                      )
                    }
                    className="flex flex-1 items-center justify-center w-[52px] h-[22px] font-poppins text-[15px] mx-1 text-center border border-gray-300 rounded"
                  />

                  <button
                    onClick={() =>
                      handleIncreaseStockQty(ele.tradingSymbol, ele.tradeId)
                    }
                    className="cursor-pointer hover:bg-black hover:text-white px-1 py-[2px] rounded-[3px]"
                  >
                    <PlusIcon className=" h-3 w-3" />
                  </button>
                </div>

                <div className="flex pl-4 flex-col items-end space-y-[6px]">
                  <div className="text-[14px]  text-[#000000] font-poppins font-medium">
                    ₹{getLTPForSymbol(ele.tradingSymbol)}
                  </div>
                  <div className="text-[14px]  text-[#000000] font-poppins font-medium capitalize">
                    {ele.orderType?.toLowerCase()}
                    {ele.orderType === "LIMIT" || ele.orderType === "STOP" ? (
                      <span>({ele.price})</span>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <DrawerFooter className="pt-2 flex flex-col  w-full shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.06)]">
          <div className="flex  items-center justify-between space-x-4">
            <div className="flex flex-col items-start ">
              <div className=" min-w-[110px] text-[10px] leading-[22px] text-black font-semibold lg:text-base lg:leading-[24px] font-sans">
                Total Amount :
              </div>
              <div className="py-1 text-[18px] text-[#000000]/80 font-semibold   rounded-[4px]">
                ₹{calculateTotalAmount()}
              </div>
            </div>

            <button
              className="w-full  px-8 py-3 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
              onClick={handleZerodhaRedirect}
            >
              {loading === true ? (
                <LoadingSpinner />
              ) : (
                <span className="text-[18px] font-medium text-[#ffffff] font-poopins ">
                  Place Order
                </span>
              )}
            </button>
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ZerodhaReviewModal;
