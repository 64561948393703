import React, { useState, useEffect, useRef, useCallback } from "react";
import { clientData } from "./dummyData";
import { debounce } from "lodash"; // Using lodash for debouncing
import {
  ChevronRight,
  ChevronDown,
  MessageSquareText,
  Edit2Icon,
  Trash2Icon,
  PlusIcon,
  MessageSquarePlus,
  XIcon,
  CloudUploadIcon,
} from "lucide-react";
import EditModal from "./EditModal";
import DeleteModal from "./DeleteModal";
import { AiFillCaretRight } from "react-icons/ai";
import UpdateCommentModal from "./updateCommentModal";
import FormatDateTime, { FormatDate } from "../../utils/formatDateTime";
import { v4 as uuidv4 } from "uuid";
import SubscriptionDeleteModal from "./SubscriptionDeleteModal";
import EditSubscriptionModal from "./EditSubscriptionModal";
import server from "../../utils/serverConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import toast from "react-hot-toast";
import Excel from "../../assests/Icon/Excel.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import formatCurrency from "../../utils/formatCurrency";

const ClientListNewTable = ({
  userId,
  setIsDataFetching,
  allClientsData,
  allGroupsData,
  searchQuery,
  getAllClientsData,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState(allClientsData);

  const allGroupsName =
    allGroupsData && allGroupsData?.flatMap((item) => item.groupName);

  const [expandedRows, setExpandedRows] = useState({});

  const [dropdownOpen, setDropdownOpen] = useState({});
  const [newClientVisible, setNewClientVisible] = useState(false);
  const [filteredData, setFilteredData] = useState(data);

  const [newClient, setNewClient] = useState({
    clientId: uuidv4().slice(0, 7),
    clientName: "",
    email: "",
    phone: "",
    groups: ["All Client"],
    location: "",
    telegram: "",
    pan: "",
    creationDate: FormatDateTime(new Date()),
    comments: "",
  });

  const [newSubscription, setNewSubscription] = useState({
    subId: uuidv4().slice(0, 10),
    startDate: FormatDateTime(new Date()),
    plan: "",
    capital: 0,
    charges: 0,
    invoice: 0,
    expiry: FormatDateTime(new Date()),
  });

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSendMessageModal, setShowSendMessageModal] = useState(false);
  const [showSubscriptionDeleteModal, setShowSubscriptionDeleteModal] =
    useState(false);

  const [showSubscriptionEditModal, setShowSubscriptionEditModal] =
    useState(false);

  const [clientToEdit, setClientToEdit] = useState(null);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [subscriptionClientId, setSubscriptionClientId] = useState(null);
  const [tableIndex, setTableIndex] = useState(null);
  const [subscriptionToEdit, setSubscriptionToEdit] = useState(null);
  const [subscriptionToDelete, setSubscriptionToDelete] = useState(null);
  const [newSubRow, setNewSubRow] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    setData(allClientsData);
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [allClientsData]);

  useEffect(() => {
    setFilteredData(
      data.filter((user) =>
        user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, data]);

  // const toggleRow = (index) => {
  //   setExpandedRows((prev) => ({
  //     ...prev,
  //     [index]: !prev[index],
  //   }));
  // };

  const toggleRow = (index) => {
    setExpandedRows((prev) => {
      const newExpandedRows = {};

      // Expand the current row or collapse if it's already expanded
      if (prev[index]) {
        // Collapse the currently expanded row
        return newExpandedRows;
      } else {
        // Expand the new row and collapse any previously expanded row
        newExpandedRows[index] = true;
        return newExpandedRows;
      }
    });
  };

  const toggleDropdown = (index) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClient((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleGroupChange = (group) => {
    // Prevent unchecking "All Client"
    if (group === "All Client") {
      return;
    }
    setNewClient((prevClient) => {
      const isGroupPresent = prevClient.groups.includes(group);
      const updatedGroups = isGroupPresent
        ? prevClient.groups.filter((g) => g !== group) // Remove group if already present
        : [...prevClient.groups, group]; // Add group if not present

      return { ...prevClient, groups: updatedGroups };
    });
  };

  const handleAddRow = () => {
    setNewClientVisible(true);
  };

  const handleAddSubRow = (index) => {
    setNewSubRow(true);
    setExpandedRows((prev) => ({
      ...prev,
      [index]: 1,
    }));
  };

  const handleCancel = () => {
    setNewClientVisible(false);
  };

  const handleCancelSubRow = (index) => {
    setNewSubRow(false);
    // setExpandedRows((prev) => ({
    //   ...prev,
    //   [index]: false,
    // }));
  };

  const handleEditClientModal = (client) => {
    setShowEditModal(true);
    setClientToEdit(client);
  };

  const handleDeleteModal = (client) => {
    setShowDeleteModal(true);
    setClientToDelete(client);
  };

  const handleCommentUpdateModal = (client) => {
    setShowSendMessageModal(true);
    setClientToEdit(client);
  };

  const handleAddClient = async () => {
    const newClientData = {
      ...newClient,
      subscriptions: [], // You can modify this as per your need
    };

    console.log("New Client data:", newClientData);

    try {
      // Send a POST request to add the new client
      const response = await fetch(
        `${server.server.baseUrl}api/add-new-client-to-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            clientData: newClientData,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Client added successfully:", result);

        // Reset the newClient form
        setNewClient({
          clientId: uuidv4().slice(0, 7),
          clientName: "",
          email: "",
          phone: "",
          groups: ["All Client"],
          location: "",
          telegram: "",
          pan: "",
          creationDate: FormatDateTime(new Date()),
          comments: "",
        });

        setNewClientVisible(false);
        setIsDataFetching(true);
        getAllClientsData(userId);
        toast.success(
          `New Client ${newClientData.clientName} added successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed to add client :", result.message);
        toast.error(`Failed to add new client ${newClientData.clientName} !`, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error adding client:", error);
      toast.error(`Error in adding new client ${newClientData.clientName} !`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleUpdateClient = async (updatedClientData) => {
    console.log("updated----", updatedClientData);

    const newData = {
      ...updatedClientData,
    };
    try {
      // Send a POST request to add the new client
      const response = await fetch(
        `${server.server.baseUrl}api/edit-client-from-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: newData.clientId,
            clientName: newData.clientName,
            email: newData.email,
            phone: newData.phone,
            groups: newData.groups,
            location: newData.location,
            telegram: newData.telegram,
            pan: newData.pan,
            comments: newData.comments,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Client edited successfully:", result);
        getAllClientsData(userId);
        toast.success(
          `${newData.clientName} details have been updated successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed to edit client:", result.message);
        getAllClientsData(userId);
        toast.error(`Failed to update ${newData.clientName} details !`, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error editing client:", error);
      toast.error(`Error in updating ${newData.clientName} details !`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleDeleteClient = async (client) => {
    console.log("Client data", client);
    try {
      const response = await fetch(
        `${server.server.baseUrl}api/delete-client-from-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: client.clientId,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Client removed successfully:", result);
        getAllClientsData(userId);
        toast.success(
          ` ${client.clientName} have been removed successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed to remove client:", result.message);
        getAllClientsData(userId);
        toast.error(
          `Failed to remove ${client.clientName} from client list !`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          }
        );
      }
    } catch (error) {
      console.error("Error removing client:", error);
      toast.error(`Error in deleting ${client.clientName} from client list !`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleDeleteSubscriptionModal = (index, client, subId) => {
    setShowSubscriptionDeleteModal(true);

    console.log("client---", client);
    console.log("index---", index);

    const subData = client.subscriptions.filter((item) => item.subId === subId);
    setSubscriptionToDelete(subData[0]);
    setSubscriptionClientId(client.clientId);
    setTableIndex(index);
  };

  const handleEditSubscriptionModal = (client, subId) => {
    setShowSubscriptionEditModal(true);

    console.log("client---", client);

    const subData = client.subscriptions.filter((item) => item.subId === subId);

    setSubscriptionToEdit(subData[0]);
    setSubscriptionClientId(client.clientId);
  };

  const handleSubscriptionChange = (e) => {
    const { name, value } = e.target;

    setNewSubscription((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddPlan = async (index, clientId) => {
    console.log("New SUB -----", newSubscription);
    try {
      // Send a POST request to add the new client
      const response = await fetch(
        `${server.server.baseUrl}api/add-subscriptions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: clientId,
            newSubscription: newSubscription,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("newSubscription added successfully:", result);

        setNewSubscription({
          subId: uuidv4().slice(0, 10),
          startDate: FormatDateTime(new Date()),
          plan: "",
          capital: 0,
          charges: 0,
          invoice: 0,
          expiry: FormatDateTime(new Date()),
        });
        getAllClientsData(userId);
        setNewSubRow(false);

        toast.success(
          `Subscription plan ${newSubscription?.plan} for client ${data[index]?.clientName} added successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed to add subscription:", result.message);
        toast.error(
          `Failed to add subscription for client ${data[index]?.clientName}`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          }
        );
      }
    } catch (error) {
      console.error("Error adding subscription:", error);
      toast.error("Error adding subscription details !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleDeleteSubscription = async (clientId, subId) => {
    console.log("Client data", clientId);
    console.log("Sub ID", subId);
    console.log("index", tableIndex);

    const client = allClientsData.filter((item) => item.clientId === clientId);
    const sub = client[0].subscriptions.filter((item) => item.subId === subId);

    try {
      const response = await fetch(
        `${server.server.baseUrl}api/delete-subscriptions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: clientId,
            subId: subId,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Subscription removed successfully:", result);
        getAllClientsData(userId);
        // Optionally update state or UI here
        // setExpandedRows((prev) => ({
        //   ...prev,
        //   [tableIndex]: false,
        // }));
        toast.success(
          `Subscription plan ${sub[0].plan} for ${client[0].clientName} deleted successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed to remove subscriptions:", result.message);
        toast.error(`Failed to delete subscription ${sub[0].plan} !`, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error removing delete-subscriptions:", error);
      toast.error(`Error in deleting subscription  ${sub[0].plan} !`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleUpdateSubscription = async (
    clientId,
    subId,
    updatedClientSubscription
  ) => {
    console.log("Client data", clientId);
    console.log("Sub ID", subId);
    console.log("Updated Subscriptions ", updatedClientSubscription);

    const client = allClientsData.filter((item) => item.clientId === clientId);

    try {
      const response = await fetch(
        `${server.server.baseUrl}api/edit-subscriptions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: clientId,
            subId: subId,
            startDate: updatedClientSubscription.startDate,
            plan: updatedClientSubscription.plan,
            capital: updatedClientSubscription.capital,
            charges: updatedClientSubscription.charges,
            invoice: updatedClientSubscription.invoice,
            expiry: updatedClientSubscription.expiry,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Subscription updated successfully:", result);
        getAllClientsData(userId);

        toast.success(
          `Subscription plan ${updatedClientSubscription.plan} for ${client[0].clientName} updated successfully !!`,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      } else {
        console.error("Failed update subscriptions:", result.message);
        toast.error(
          `Failed to update subscription ${updatedClientSubscription.plan} ! `,
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          }
        );
      }
    } catch (error) {
      console.error("Error updating subscriptions:", error);
      toast.error(
        `Error in updating subscription ${updatedClientSubscription.plan} !`,
        {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        }
      );
    }
  };

  const handleAddExistingClientToGroup = async (
    checked,
    clientId,
    groupName
  ) => {
    const data = allClientsData.filter((item) => item.clientId === clientId);
    const prevGroups = data[0].groups;
    const name = data[0].clientName;

    let updatedGroups;

    if (checked) {
      updatedGroups = [...prevGroups, groupName];
      console.log("group name for adding", groupName);
      console.log("updated group", updatedGroups);
    } else {
      updatedGroups = prevGroups.filter((group) => group !== groupName);
      console.log("group name for deleting", groupName);
      console.log("updated group", updatedGroups);
    }
    // console.log("group name for removing",getGroupName)

    try {
      // Send a POST request to add the new client
      const response = await fetch(
        `${server.server.baseUrl}api/add-existing-client-to-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clientId: clientId,
            updatedGroups: updatedGroups,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Client added to existing group successfully:", result);

        // Reset the newClient form
        setNewClient({
          clientId: uuidv4().slice(0, 7),
          clientName: "",
          email: "",
          phone: "",
          groups: ["All Client"],
          location: "",
          telegram: "",
          pan: "",
          creationDate: FormatDateTime(new Date()),
          comments: "",
        });

        setNewClientVisible(false);
        getAllClientsData(userId);
        if (checked) {
          toast.success(`${name} added to group ${groupName} successfully !!`, {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          });
        } else {
          toast.success(
            `${name} removed from group ${groupName} successfully !!`,
            {
              duration: 3000,
              style: {
                background: "white",
                color: "#1e293b",
                maxWidth: "500px",
                fontWeight: 600,
                fontSize: "13px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#16a085",
                secondary: "#FFFAEE",
              },
            }
          );
        }
      } else {
        console.error("Failed to add client to group:", result.message);
        toast.error(`Failed to add ${name} to group ${groupName} !!`, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error adding client to group:", error);
      toast.error(`Error adding ${name} to group ${groupName} !!`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  // Debouncing the API call
  const debouncedHandleAddExistingClientToGroup = useCallback(
    debounce(handleAddExistingClientToGroup, 300), // 300ms debounce
    []
  );

  const handleCheckboxChange = (e, clientIndex, groupName) => {
    const { checked } = e.target;

    // Prevent unchecking "All Client"
    if (groupName === "All Client" && !checked) {
      return;
    }

    setData((prevData) =>
      prevData.map((client, index) => {
        if (index === clientIndex) {
          const updatedGroups = checked
            ? [...client.groups, groupName] // Add group if checked
            : client.groups.filter((group) => group !== groupName); // Remove group if unchecked

          // Use the debounced function to avoid multiple rapid calls
          debouncedHandleAddExistingClientToGroup(
            checked,
            client.clientId,
            groupName
          );

          return {
            ...client,
            groups: updatedGroups,
          };
        }
        return client;
      })
    );
  };

  return (
    <div className=" w-full h-full  max-h-[calc(100vh-225px)] lg:max-h-[calc(100vh-50px)] overflow-auto mb-4 ">
      <table className="w-full bg-white font-poppins">
        <thead className="bg-[#f5f5f5] sticky top-0 z-20">
          <tr>
            <th className="w-[50px]  py-3 font-medium text-[13px] font-poppins text-white border-r-[1px] border-[#000000]/10">
              <div className="flex justify-center">
                <div
                  onClick={handleAddRow}
                  className="w-5 h-5 bg-[#000000] rounded-[2px] flex items-center justify-center cursor-pointer"
                >
                  <PlusIcon
                    strokeWidth={3}
                    className="text-[#ffffff] w-[18px] h-[18px]"
                  />
                </div>
              </div>
            </th>
            <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
              Client Id
            </th>
            <th className="px-3 py-3 w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
              Client Name
              <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
            </th>
            <th className="px-3 py-3 w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
              Email Id
              <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
            </th>

            <th className="px-3 py-3 max-w-[200px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
              Group Name(s)
            </th>
            <th className="px-3 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
              Phone No.
              <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
            </th>
            <th className="px-3 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
              Location
            </th>
            <th className="px-3 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
              Telegram Id
            </th>
            <th className="px-2 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
              KYC
            </th>
            <th className="px-3 py-3 min-w-[150px] font-medium text-[13px] font-poppins text-[#000000]/80  text-center border-r-[1px] border-[#000000]/10">
              Creation Date
              <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
            </th>
            <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-center border-r-[1px] border-[#000000]/10">
              Plans
            </th>
            <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-center border-r-[1px] border-[#000000]/10">
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          {newClientVisible && (
            <tr className="">
              <td className="px-3 py-4 max-w-[70px]">
                {/* Empty cell for spacing */}
              </td>
              <td className="min-w-[100px] px-4 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                <input
                  type="text"
                  name="clientId"
                  className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                  value={newClient.clientId}
                  onChange={handleInputChange}
                  placeholder="Client Id"
                  readOnly
                />
              </td>

              <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                <input
                  type="text"
                  name="clientName"
                  className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                  value={newClient.clientName}
                  onChange={handleInputChange}
                  placeholder="Client Name"
                />
              </td>
              <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                <input
                  type="email"
                  name="email"
                  className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                  value={newClient.email}
                  onChange={handleInputChange}
                  placeholder="Email Id"
                />
              </td>

              <td className="min-w-[160px] px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                <div
                  className="relative flex items-start justify-between"
                  ref={dropdownRef}
                >
                  <div className="flex flex-wrap gap-[4px]">
                    {newClient.groups.length > 0 ? (
                      newClient.groups.map((group, index) => (
                        <button
                          key={index}
                          className="bg-white font-normal px-2 py-0.5 rounded-full text-[10px] border-[1px] border-[#000000]/10 text-[#000000]/80"
                        >
                          {group}
                        </button>
                      ))
                    ) : (
                      <span className="text-gray-400">Group Name(s)</span>
                    )}
                  </div>
                  <button
                    onClick={() => toggleDropdown("newClient")}
                    className="flex items-center justify-center"
                  >
                    <ChevronDown className="text-[#000000]/80 w-6 h-6" />
                  </button>
                  {dropdownOpen["newClient"] && (
                    <div
                      className={`absolute z-20 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg h-[180px] overflow-y-auto`}
                    >
                      {allGroupsName && allGroupsName.length > 0 ? (
                        allGroupsName?.map((group) => (
                          <label
                            key={group}
                            className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 border-b-[1px] last-of-type:border-[0px] border-[#000000]/20 cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              value={group}
                              checked={newClient.groups.includes(group)}
                              onChange={() => handleGroupChange(group)}
                              className="mr-2 accent-black"
                            />
                            {group}
                          </label>
                        ))
                      ) : (
                        <div className="px-4 py-2 text-sm text-gray-500">
                          No groups available
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </td>
              <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                <input
                  type="text"
                  name="phone"
                  className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                  value={newClient.phone}
                  onChange={handleInputChange}
                  placeholder="Phone No."
                />
              </td>
              <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                <input
                  type="text"
                  name="location"
                  className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                  value={newClient.location}
                  onChange={handleInputChange}
                  placeholder="Location"
                />
              </td>
              <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                <input
                  type="text"
                  name="telegram"
                  className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                  value={newClient.telegram}
                  onChange={handleInputChange}
                  placeholder="Telegram Id"
                />
              </td>
              <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                <input
                  type="text"
                  name="pan"
                  className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                  value={newClient.pan}
                  onChange={handleInputChange}
                  placeholder="KYC"
                />
              </td>
              <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                <input
                  type="text"
                  name="creationDate"
                  className="w-full bg-white border border-gray-300 rounded py-1 px-2"
                  value={newClient.creationDate}
                  placeholder="Creation date"
                  readOnly
                />
              </td>

              <td
                colSpan={2}
                className="px-2 py-4 font-normal text-[13px] font-poppins text-[#000000]/80"
              >
                <div className="flex justify-start ">
                  {/* <div
                    onClick={() => handleAddEmptySubRow()}
                    className="flex items-center justify-center  text-blue-500  text-[11px]  font-medium font-poppins rounded-[4px] px-[6px] py-[4px] cursor-pointer"
                  >
                    Add Plan
                  </div> */}
                  <button
                    disabled={
                      !newClient.clientId ||
                      !newClient.email ||
                      !newClient.clientName ||
                      !newClient.creationDate ||
                      !newClient.phone
                    }
                    onClick={handleAddClient}
                    className="px-3 py-1 disabled:bg-opacity-50 disabled:cursor-not-allowed bg-[#000000] text-white font-medium text-[12px] rounded mr-2"
                  >
                    Save
                  </button>

                  <button
                    onClick={handleCancel}
                    className="px-2 py-1  text-black font-medium text-[12px] rounded"
                  >
                    <Trash2Icon className="h-4 w-4" />
                  </button>
                </div>
              </td>
            </tr>
          )}

          {filteredData &&
            filteredData?.map((item, index) => (
              <React.Fragment key={index}>
                <tr
                  className={`${
                    expandedRows[index]
                      ? "border-b-[0px] divide-x-[0px] divide-[#000000]/10"
                      : "border-b-[1px] border-[#000000]/10 divide-x-[1px] divide-[#000000]/10"
                  }`}
                >
                  <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80 ">
                    <div className="flex items-center justify-center">
                      {item.comments && (
                        <div className="relative group w-full">
                          <MessageSquareText className="h-5 w-5 text-gray-600 cursor-pointer " />
                          <div className="z-20 mt-1 absolute top-full min-w-[200px] mb-2 hidden group-hover:inline-block   bg-gray-800 text-white text-[11px] rounded-[3px] p-2">
                            {item.comments}
                            <div className="absolute -top-1 left-[12px] transform -translate-x-1/2 w-2 h-2 bg-black rotate-45"></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>

                  <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80 ">
                    <div className="flex items-center justify-between">
                      <span>
                        {item.subscriptions?.length > 0 && (
                          <AiFillCaretRight
                            className={`cursor-pointer h-5 w-5 text-black/80 transform ${
                              expandedRows[index] ? "rotate-90" : ""
                            }`}
                            onClick={() => toggleRow(index)}
                          />
                        )}
                      </span>
                      <span>{item.clientId}</span>
                    </div>
                  </td>
                  <td className="px-3 py-4 max-w-[180px] truncate font-normal text-[13px] font-poppins text-[#000000]/80">
                    <span className="mr-[4px] text-[12px] font-medium font-poppins">
                      ({item.subscriptions?.length})
                    </span>

                    {item.clientName}
                  </td>
                  <td className="px-3 py-4 max-w-[180px] truncate font-normal text-[13px] font-poppins text-[#000000]/80">
                    {item.email}
                  </td>

                  <td className="min-w-[200px] px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                    <div className="w-full relative flex items-start justify-between">
                      <div className="flex flex-wrap gap-[4px]">
                        {item.groups?.map((group, groupIndex) => (
                          <button
                            key={groupIndex}
                            className="bg-white font-normal capitalize px-2 py-0.5 rounded-full text-[10px] border-[1px] border-[#000000]/10 text-[#000000]/80"
                          >
                            {group?.replace(/_/g, " ")}
                          </button>
                        ))}
                      </div>

                      <button
                        onClick={() => toggleDropdown(index)}
                        className="flex items-center justify-center"
                      >
                        <ChevronDown className="text-[#000000]/80 w-6 h-6" />
                      </button>

                      {dropdownOpen[index] && (
                        <div
                          ref={dropdownRef}
                          className={`absolute z-20 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg max-h-[180px] overflow-y-auto ${
                            index >= data.length - 3 && data.length > 7
                              ? "bottom-full mb-2"
                              : "top-full mt-2"
                          }`}
                        >
                          {allGroupsName.length > 0 ? (
                            allGroupsName?.map((group) => (
                              <label
                                key={group}
                                className="w-full flex items-center capitalize px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 border-b-[1px] last-of-type:border-[0px] border-[#000000]/20 cursor-pointer"
                              >
                                <input
                                  type="checkbox"
                                  value={group}
                                  checked={item.groups?.includes(group)}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index, group)
                                  }
                                  className="mr-2 accent-black "
                                />
                                {group?.replace(/_/g, " ")}
                              </label>
                            ))
                          ) : (
                            <div className="px-4 py-2 text-sm text-gray-500">
                              No groups available
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="px-3 py-4 max-w-[120px] truncate font-normal text-[13px] font-poppins text-[#000000]/80">
                    {item.phone}
                  </td>
                  <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                    {item.location}
                  </td>
                  <td className="px-3 py-4 max-w-[120px] truncate font-normal text-[13px] font-poppins text-[#000000]/80">
                    {item.telegram?.includes("@") ? (
                      item.telegram
                    ) : item.telegram?.length > 0 ? (
                      <>@{item.telegram}</>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="px-3 py-4 max-w-[120px] truncate font-normal text-[13px] font-poppins text-[#000000]/80">
                    {item.pan}
                  </td>
                  <td className="px-3 py-4 max-w-[130px] font-normal text-[13px] font-poppins text-center text-[#000000]/80">
                    {FormatDate(item.creationDate)}
                  </td>
                  <td className="px-2 py-4 min-w-[80px]  font-normal text-[12px] font-poppins text-[#000000]/80">
                    <div
                      onClick={() => handleAddSubRow(index)}
                      className="flex items-center justify-center  text-blue-500  text-[11px]  font-medium font-poppins rounded-[4px] px-[6px] py-[4px] cursor-pointer"
                    >
                      Add Plan
                    </div>
                  </td>
                  <td className="px-2 py-4  font-normal text-[12px] font-poppins text-[#000000]/80">
                    <div className="flex items-center justify-center space-x-2">
                      <Edit2Icon
                        className="text-[#000000]/80 h-4 w-4 cursor-pointer"
                        onClick={() => handleEditClientModal(item)}
                      />
                      <Trash2Icon
                        className="text-[#000000]/80 h-4 w-4 cursor-pointer"
                        onClick={() => handleDeleteModal(item)}
                      />
                      {/* <MessageSquarePlus
                        className="text-[#000000]/80 h-4 w-4 cursor-pointer"
                        onClick={() => handleCommentUpdateModal(item)}
                      /> */}
                    </div>
                  </td>
                </tr>
                {expandedRows[index] && (
                  <tr className="bg-white">
                    <td
                      colSpan="11"
                      className="py-4 px-[130px] border-b-[1px] border-[#000000]/10 "
                    >
                      <table className="w-full border-[1px] border-[#000000]/10">
                        <thead className="bg-[#F5F5F5]/80">
                          <tr>
                            <th className="px-4 py-2 font-normal text-[11px] font-poppins text-left text-[#000000]/60">
                              <div className="flex items-center space-x-2">
                                <div
                                  onClick={() => handleAddSubRow(index)}
                                  className="w-4 h-4 bg-[#000000] rounded-[2px] flex items-center justify-center cursor-pointer"
                                >
                                  <PlusIcon
                                    strokeWidth={4}
                                    className="text-[#ffffff] w-[12px] h-[12px]"
                                  />
                                </div>
                                <div className="font-normal text-[11px] font-poppins text-left text-[#000000]/60">
                                  Start Date
                                  <span className="ml-1 text-[#E6626F] text-[14px]">
                                    *
                                  </span>
                                </div>
                              </div>
                            </th>
                            <th className="px-4 py-2 font-normal text-[11px] font-poppins text-left text-[#000000]/60">
                              Subscription Plan
                              <span className="ml-1 text-[#E6626F] text-[14px]">
                                *
                              </span>
                            </th>
                            <th className="px-4 py-2 font-normal text-[11px] font-poppins text-center text-[#000000]/60">
                              Capital Deployed
                              <span className="ml-1 text-[#E6626F] text-[14px]">
                                *
                              </span>
                            </th>
                            <th className="px-4 py-2 font-normal text-[11px] font-poppins text-center text-[#000000]/60">
                              Charges
                              <span className="ml-1 text-[#E6626F] text-[14px]">
                                *
                              </span>
                            </th>
                            <th className="px-4 py-2 font-normal text-[11px] font-poppins text-center text-[#000000]/60">
                              Invoice No.
                              <span className="ml-1 text-[#E6626F] text-[14px]">
                                *
                              </span>
                            </th>
                            <th className="px-4 py-2 font-normal text-[11px] font-poppins text-center text-[#000000]/60">
                              Expiry Date
                              <span className="ml-1 text-[#E6626F] text-[14px]">
                                *
                              </span>
                            </th>
                            <th className="px-4 py-2 font-normal text-[11px] font-poppins text-center text-[#000000]/60">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {newSubRow && (
                            <tr className="border-b-[1px] border-[#000000]/10">
                              <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                <input
                                  type="text"
                                  name="startDate"
                                  value={newSubscription.startDate}
                                  onChange={(e) => handleSubscriptionChange(e)}
                                  className="w-full p-2 border rounded"
                                  placeholder="Start Date"
                                />
                              </td>
                              <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                <input
                                  type="text"
                                  name="plan"
                                  value={newSubscription.plan}
                                  onChange={(e) => handleSubscriptionChange(e)}
                                  className="w-full p-2 border rounded"
                                  placeholder="Plan"
                                />
                              </td>
                              <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                <div className="flex relative">
                                  <input
                                    type="number"
                                    name="capital"
                                    value={newSubscription.capital}
                                    onChange={(e) =>
                                      handleSubscriptionChange(e)
                                    }
                                    className="w-full p-2 border rounded pl-[24px]"
                                    placeholder="Capital"
                                  />
                                  <div className="absolute left-[1px] rounded-l-[4px] text-[14px] text-black font-poppins py-[7px]  px-2">
                                    ₹
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                <div className="flex relative">
                                  <input
                                    type="number"
                                    name="charges"
                                    value={newSubscription.charges}
                                    onChange={(e) =>
                                      handleSubscriptionChange(e)
                                    }
                                    className="w-full p-2 border rounded pl-[24px]"
                                    placeholder="Charges"
                                  />
                                  <div className="absolute left-[1px] rounded-l-[4px] text-[14px] text-black font-poppins py-[7px]  px-2">
                                    ₹
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                <input
                                  type="number"
                                  name="invoice"
                                  value={newSubscription.invoice}
                                  onChange={(e) => handleSubscriptionChange(e)}
                                  className="w-full p-2 border rounded"
                                  placeholder="Invoice"
                                />
                              </td>
                              <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                <input
                                  type="text"
                                  name="expiry"
                                  value={newSubscription.expiry}
                                  onChange={(e) => handleSubscriptionChange(e)}
                                  className="w-full p-2 border rounded"
                                  placeholder="Expiry Date"
                                />
                              </td>
                              <td className="px-4 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                                <div className="flex space-x-0">
                                  <button
                                    disabled={
                                      !newSubscription.startDate ||
                                      !newSubscription.plan ||
                                      !newSubscription.capital ||
                                      !newSubscription.charges ||
                                      !newSubscription.invoice ||
                                      !newSubscription.expiry
                                    }
                                    onClick={() =>
                                      handleAddPlan(index, item.clientId)
                                    }
                                    className="disabled:cursor-not-allowed disabled:bg-opacity-50 px-4 py-1 bg-[#000000] text-white font-medium text-[12px] rounded mr-2"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={() => handleCancelSubRow(index)}
                                    className="px-4 py-1  text-black font-medium text-[12px] rounded"
                                  >
                                    <Trash2Icon className="h-4 w-4" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )}
                          {item.subscriptions?.map((sub, subIndex) => (
                            <tr
                              key={subIndex}
                              className="border-b-[1px] border-[#000000]/10"
                            >
                              <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                {FormatDate(sub.startDate)}
                              </td>
                              <td className="px-4 py-2 font-normal text-[12px] font-poppins text-left text-[#000000]/80">
                                {sub.plan}
                              </td>
                              <td className="px-4 py-2 font-normal text-[12px] font-poppins text-center text-[#000000]/80">
                                {sub.capital}
                              </td>
                              <td className="px-4 py-2 font-normal text-[12px] font-poppins text-center text-[#000000]/80">
                                {sub.charges}
                              </td>
                              <td className="px-4 py-2 font-normal text-[12px] font-poppins text-center text-[#000000]/80">
                                {sub.invoice}
                              </td>
                              <td className="px-4 py-2 font-normal text-[12px] font-poppins text-center text-[#000000]/80">
                                {FormatDate(sub.expiry)}
                              </td>
                              <td className="px-4 py-4 font-normal text-[12px] font-poppins text-[#000000]/80">
                                <div className="flex items-center justify-center space-x-2">
                                  <Edit2Icon
                                    onClick={() =>
                                      handleEditSubscriptionModal(
                                        item,
                                        sub.subId
                                      )
                                    }
                                    className="text-[#000000]/80 h-[14px] w-[14px] cursor-pointer"
                                  />
                                  <Trash2Icon
                                    onClick={() =>
                                      handleDeleteSubscriptionModal(
                                        index,
                                        item,
                                        sub.subId
                                      )
                                    }
                                    className="text-[#000000]/80 h-[14px] w-[14px] cursor-pointer"
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
        </tbody>
      </table>

      {showEditModal && (
        <EditModal
          client={clientToEdit}
          onClose={() => setShowEditModal(false)}
          onUpdate={handleUpdateClient}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          client={clientToDelete}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleDeleteClient}
        />
      )}

      {showSubscriptionDeleteModal && (
        <SubscriptionDeleteModal
          clientId={subscriptionClientId}
          subscriptionToDelete={subscriptionToDelete}
          onClose={() => setShowSubscriptionDeleteModal(false)}
          onDelete={handleDeleteSubscription}
        />
      )}

      {showSubscriptionEditModal && (
        <EditSubscriptionModal
          clientId={subscriptionClientId}
          subscriptionToEdit={subscriptionToEdit}
          onClose={() => setShowSubscriptionEditModal(false)}
          onUpdate={handleUpdateSubscription}
        />
      )}
    </div>
  );
};

export default ClientListNewTable;
