import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import moment from "moment";
import server from "../../utils/serverConfig";

import { DatePicker } from "../../components/ui/date-picker";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

import ShowAdviceDataTable from "./ShowAdviceDataTable";
import { CircleXIcon, RefreshCcwIcon } from "lucide-react";
import { CSVLink } from "react-csv";

import CSVIcon from "../../assests/csv_icon.svg";

const style = {
  selected:
    "  text-[14px] lg:text-[18px]   font-poppins text-black font-semibold  border-b-[3px] border-black cursor-pointer",
  unselected:
    "   font-medium  font-poppins text-[14px] lg:text-[18px]  text-[#000000]/40 cursor-pointer  border-b-[3px] border-transparent",
  firstHeading: "text-sm text-gray-900 text-left font-normal",
  inputBox:
    "w-full py-2 xl:px-6 md:py-2.5  bg-[#F9F9F9] text-[12px] md:text-[16px]  peer text-gray-900 placeholder-transparent font-poppins font-medium rounded-md  ring-1 hover:ring-1 ring-gray-200  hover:ring-[#D9D9D9] focus:outline-none focus:ring-1 focus:ring-[#D9D9D9]  transition ease-in duration-200 ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-[#F9F9F9] text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-normal peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  inputStartDiv: "relative w-full",
  selectDiv:
    "flex items-center px-2 py-2  text-lg rounded-md mx-3 mb-1 text-black  hover:bg-[#000000] hover:text-white cursor-pointer",
};

const RecommendationStatus = ({ adminEmail, userId }) => {
  const [allTrade, setAllTrade] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryStock, setSearchQueryStock] = useState("");
  const [filteredAllAdviceData, setFilteredAllAdviceData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filename, setFilename] = useState("");

  const [allCsvFileData, setAllCsvFileData] = useState();
  const [selectedTab, setSelectedTab] = useState("all");

  const getAllCsvData = useCallback(() => {
    if (userId) {
      axios
        .get(`${server.server.baseUrl}api/csv-upload/${userId}`)
        .then((res) => {
          setAllCsvFileData(res.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [userId]);

  useEffect(() => {
    getAllCsvData();
  }, [getAllCsvData]);

  const getAllTrades = () => {
    if (adminEmail) {
      setLoading(true);

      let config = {
        method: "get",
        url: `${server.server.baseUrl}api/trade-history/trade-history-by-trade-given-by?trade_given_by=${adminEmail}`,
      };

      axios
        .request(config)
        .then((response) => {
          setAllTrade(response.data.trades || []);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
    setLoading(false);
  };
  useEffect(() => {
    getAllTrades();
  }, []);

  useEffect(() => {
    const filteredAdvices = allTrade?.filter((trade) => {
      const matchesEmail = trade.user_email
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const matchesStockName = trade.Symbol?.toLowerCase().includes(
        searchQueryStock.toLowerCase()
      );

      const matchesStartDate = startDate
        ? moment(trade.date).isSameOrAfter(startDate, "day")
        : true;
      const matchesEndDate = endDate
        ? moment(trade.date).isSameOrBefore(endDate, "day")
        : true;

      const matchesFilename = filename
        ? (trade.trade_given_by_fileName || "")
            .toLowerCase()
            .includes(filename.toLowerCase())
        : true; // Check if trade_given_by_fileName exists before applying toLowerCase()

      return (
        matchesEmail &&
        matchesStockName &&
        matchesStartDate &&
        matchesEndDate &&
        matchesFilename
      );
    });

    setFilteredAllAdviceData(filteredAdvices);
  }, [searchQuery, searchQueryStock, startDate, endDate, filename, allTrade]);

  const handleRefersh = () => {
    getAllTrades();
  };

  const handleFileNameSelection = (value) => {
    setFilename(value);
    setSelectedTab("all");
  };

  const filteredCompltedAdvice =
    filteredAllAdviceData &&
    filteredAllAdviceData.filter(
      (item) => item.trade_place_status === "complete"
    );

  const filteredRecommendedAdvice =
    filteredAllAdviceData &&
    filteredAllAdviceData.filter(
      (item) => item.trade_place_status === "recommend"
    );

  const filteredPlacedAdvice =
    filteredAllAdviceData &&
    filteredAllAdviceData.filter(
      (item) => item.trade_place_status === "Placed"
    );

  const filteredOpenAdvice =
    filteredAllAdviceData &&
    filteredAllAdviceData.filter((item) => item.trade_place_status === "open");

  const filteredRejectedAdvice =
    filteredAllAdviceData &&
    filteredAllAdviceData.filter(
      (item) => item.trade_place_status === "rejected"
    );

  const filteredIgnoredAdvice =
    filteredAllAdviceData &&
    filteredAllAdviceData.filter(
      (item) => item.trade_place_status === "ignored"
    );

  const newCsvHeaders = [
    { label: "email", key: "email" },
    { label: "symbol", key: "symbol" },
    { label: "type", key: "type" },
    { label: "product_type", key: "product_type" },
    { label: "qty", key: "qty" },
    { label: "rem_qty", key: "rem_qty" },
    { label: "traded_qty", key: "traded_qty" },
    { label: "limit_price", key: "limit_price" },
    { label: "stop_price", key: "stop_price" },
    { label: "traded_price", key: "traded_price" },
    { label: "status", key: "trade_place_status" },
    { label: "ordered_time", key: "ordered_time" },
  ];

  const adviceStatusList = filteredAllAdviceData?.map((item) => {
    return {
      email: item.user_email,
      symbol: item.Symbol,
      type: item.Type,
      product_type: item.ProductType,
      qty: item.Quantity,
      rem_qty: item.Qty,
      traded_qty: item.tradedQty,
      limit_price: item.limitPrice,
      stop_price: item.stopPrice,
      traded_price: item.tradedPrice,
      trade_place_status: item.trade_place_status,
      ordered_time: moment(item.date).format("DD MMM YYYY h:mm A"),
    };
  });

  const clearSearchInput = () => {
    setSearchQuery("");
  };

  const clearSearchStockInput = () => {
    setSearchQueryStock("");
  };

  return (
    <div className="min-h-screen w-full items-center justify-center bg-[#F9F9F9] ">
      <div className="px-4 sm:px-8 flex  md:flex-wrap  py-3 md:py-3 justify-between items-center">
        <div className="flex items-center text-[18px] text-balck md:text-[22px] md:leading-[30px] font-sans font-bold">
          <RefreshCcwIcon
            onClick={handleRefersh}
            className=" h-4 w-4 md:h-5 md:w-5 text-gray-500 mr-2 hover:cursor-pointer"
          />
          Advice Status
        </div>
        <CSVLink
          data={adviceStatusList}
          headers={newCsvHeaders}
          filename={`advice_status_list_${moment().format(
            "YYYYMMDD_HHmmss"
          )}.csv`}
          className="group relative px-1   flex  text-black rounded-[4px] justify-center items-center cursor-pointer"
        >
          <button className="flex items-center text-[14px] px-2 disabled:bg-[#000000]/20  text-center bg-white border-[1px] border-[#000000]/20 disabled:cursor-not-allowed text-[#000000] md:text-[16px] font-medium font-poppins rounded-[4px] md:px-4 py-2 cursor-pointer hover:scale-[1.02] transition-all duration-150 ease-linear">
            <img
              src={CSVIcon}
              alt="CSV File Icon"
              width={20}
              className="mr-2"
            />
            Download Excel
          </button>
        </CSVLink>
      </div>
      <div className=" px-4  grid grid-cols-2 gap-2 sm:gap-0 sm:px-8 relative md:flex md:flex-row  md:space-x-6  md:items-end mt-2 ">
        <div className=" w-full text-[12px] md:text-[16px]">
          <DatePicker
            className="px-2 py-2 md:px-4 md:py-3"
            date={startDate}
            setDate={setStartDate}
            placeholder={"Start Date"}
          />
        </div>
        <div className="w-full">
          <DatePicker
            className="px-2 py-2 md:px-4 md:py-3"
            date={endDate}
            setDate={setEndDate}
            placeholder={"End Date"}
          />
        </div>

        <div className={`${style.inputStartDiv} relative  `}>
          <input
            id="email"
            type="text"
            placeholder={`Search By "Email"`}
            className={`${style.inputBox}  px-2 font-poppins placeholder:text-gray-400 placeholder:font-normal`}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {searchQuery?.length > 1 && (
            <CircleXIcon
              onClick={clearSearchInput}
              className="cursor-pointer absolute top-[14px] right-2 w-4 h-4 text-[#000000]/50"
            />
          )}
        </div>
        <div className={`${style.inputStartDiv} relative  `}>
          <input
            id="stock"
            type="text"
            placeholder={`Search By "Stock Name"`}
            className={`${style.inputBox}  px-2 font-poppins placeholder:text-gray-400 placeholder:font-normal`}
            value={searchQueryStock}
            onChange={(e) => setSearchQueryStock(e.target.value)}
          />
          {searchQueryStock?.length > 1 && (
            <CircleXIcon
              onClick={clearSearchStockInput}
              className="cursor-pointer absolute top-[14px] right-2 w-4 h-4 text-[#000000]/50"
            />
          )}
        </div>
      </div>

      <div className="mt-4 flex overflow-x-auto lg:overscroll-none gap-x-3 lg:gap-x-2 gap-y-2 w-full border-b-[1px] border-[#000000]/10 h-[50px] lg:px-2 shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.06)] md:shadow-none">
        <div
          className={`${
            selectedTab === "all" ? style.selected : style.unselected
          } flex xl:px-2 min-w-[130px]  font-poppins items-center justify-center  `}
          onClick={() => {
            setSelectedTab("all");
          }}
        >
          All Advices
          {filteredAllAdviceData && filteredAllAdviceData?.length > 0 && (
            <span
              className={`ml-2 pt-[1px] px-[4px] flex items-center text-[10px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[24px] h-5 rounded-full ${
                selectedTab !== "all" ? " bg-[#000000]/30" : "bg-[#E43D3D]"
              }`}
            >
              {filteredAllAdviceData ? filteredAllAdviceData?.length : ""}
            </span>
          )}
        </div>
        <div
          className={`${
            selectedTab === "recommended" ? style.selected : style.unselected
          } flex xl:px-6 min-w-[140px] lg:w-auto font-poppins items-center justify-center  `}
          onClick={() => {
            setSelectedTab("recommended");
          }}
        >
          Recommended
          {filteredRecommendedAdvice &&
            filteredRecommendedAdvice?.length > 0 && (
              <span
                className={`ml-2 pt-[1px] px-[4px] flex items-center text-[10px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[24px] h-5 rounded-full ${
                  selectedTab !== "recommended"
                    ? "bg-[#000000]/30"
                    : "bg-[#E43D3D]"
                }`}
              >
                {filteredRecommendedAdvice
                  ? filteredRecommendedAdvice?.length
                  : ""}
              </span>
            )}
        </div>
        <div
          className={`${
            selectedTab === "placed" ? style.selected : style.unselected
          } flex xl:px-6 min-w-[90px] lg:w-auto font-poppins items-center justify-center  `}
          onClick={() => {
            setSelectedTab("placed");
          }}
        >
          Placed
          {filteredPlacedAdvice && filteredPlacedAdvice?.length > 0 && (
            <span
              className={`ml-2 pt-[1px] px-[4px] flex items-center text-[10px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[24px] h-5 rounded-full ${
                selectedTab !== "placed" ? "bg-[#000000]/30" : "bg-[#E43D3D]"
              }`}
            >
              {filteredPlacedAdvice ? filteredPlacedAdvice?.length : ""}
            </span>
          )}
        </div>
        <div
          className={`${
            selectedTab === "open" ? style.selected : style.unselected
          } flex xl:px-6 min-w-[90px] lg:w-auto font-poppins items-center justify-center  `}
          onClick={() => {
            setSelectedTab("open");
          }}
        >
          Open
          {filteredOpenAdvice && filteredOpenAdvice?.length > 0 && (
            <span
              className={`ml-2 pt-[1px] px-[4px] flex items-center text-[10px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[24px] h-5 rounded-full ${
                selectedTab !== "open" ? "bg-[#000000]/30" : "bg-[#E43D3D]"
              }`}
            >
              {filteredOpenAdvice ? filteredOpenAdvice?.length : ""}
            </span>
          )}
        </div>
        <div
          className={`${
            selectedTab === "rejected" ? style.selected : style.unselected
          } flex xl:px-6 min-w-[110px] lg:w-auto font-poppins items-center justify-center  `}
          onClick={() => {
            setSelectedTab("rejected");
          }}
        >
          Rejected
          {filteredRejectedAdvice && filteredRejectedAdvice?.length > 0 && (
            <span
              className={`ml-2 pt-[1px] px-[4px] flex items-center text-[10px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[24px] h-5 rounded-full ${
                selectedTab !== "rejected" ? "bg-[#000000]/30" : "bg-[#E43D3D]"
              }`}
            >
              {filteredRejectedAdvice ? filteredRejectedAdvice?.length : ""}
            </span>
          )}
        </div>
        <div
          className={`${
            selectedTab === "completed" ? style.selected : style.unselected
          } flex xl:px-6 min-w-[120px] lg:w-auto font-poppins items-center justify-center  `}
          onClick={() => {
            setSelectedTab("completed");
          }}
        >
          Completed
          {filteredCompltedAdvice && filteredCompltedAdvice?.length > 0 && (
            <span
              className={`ml-2 pt-[1px] px-[4px] flex items-center text-[10px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[24px] h-5 rounded-full ${
                selectedTab !== "completed" ? "bg-[#000000]/30" : "bg-[#E43D3D]"
              }`}
            >
              {filteredCompltedAdvice ? filteredCompltedAdvice?.length : ""}
            </span>
          )}
        </div>

        <div
          className={`${
            selectedTab === "ignored" ? style.selected : style.unselected
          } flex xl:px-6 min-w-[120px] lg:w-auto font-poppins items-center justify-center  `}
          onClick={() => {
            setSelectedTab("ignored");
          }}
        >
          Ignored
          {filteredIgnoredAdvice && filteredIgnoredAdvice?.length > 0 && (
            <span
              className={`ml-2 pt-[1px] px-[4px] flex items-center text-[10px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[24px] h-5 rounded-full ${
                selectedTab !== "ignored" ? "bg-[#000000]/30" : "bg-[#E43D3D]"
              }`}
            >
              {filteredIgnoredAdvice ? filteredIgnoredAdvice?.length : ""}
            </span>
          )}
        </div>
      </div>

      {selectedTab === "all" && (
        <ShowAdviceDataTable
          allTrade={allTrade}
          selectedTab={selectedTab}
          filteredAllAdviceData={filteredAllAdviceData}
          loading={loading}
        />
      )}

      {selectedTab === "recommended" && (
        <ShowAdviceDataTable
          allTrade={allTrade}
          selectedTab={selectedTab}
          filteredAllAdviceData={filteredRecommendedAdvice}
          loading={loading}
        />
      )}
      {selectedTab === "placed" && (
        <ShowAdviceDataTable
          allTrade={allTrade}
          selectedTab={selectedTab}
          filteredAllAdviceData={filteredPlacedAdvice}
          loading={loading}
        />
      )}
      {selectedTab === "open" && (
        <ShowAdviceDataTable
          allTrade={allTrade}
          selectedTab={selectedTab}
          filteredAllAdviceData={filteredOpenAdvice}
          loading={loading}
        />
      )}
      {selectedTab === "completed" && (
        <ShowAdviceDataTable
          allTrade={allTrade}
          selectedTab={selectedTab}
          filteredAllAdviceData={filteredCompltedAdvice}
          loading={loading}
        />
      )}
      {selectedTab === "rejected" && (
        <ShowAdviceDataTable
          allTrade={allTrade}
          selectedTab={selectedTab}
          filteredAllAdviceData={filteredRejectedAdvice}
          loading={loading}
        />
      )}
      {selectedTab === "ignored" && (
        <ShowAdviceDataTable
          allTrade={allTrade}
          selectedTab={selectedTab}
          filteredAllAdviceData={filteredIgnoredAdvice}
          loading={loading}
        />
      )}
    </div>
  );
};

export default RecommendationStatus;
