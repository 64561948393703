import { ChevronDownIcon, XIcon } from "lucide-react";
import React, { useState } from "react";
import moment from "moment";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

const style = {
  selected:
    "   text-[18px]  font-poppins text-black font-semibold  border-b-[3px] border-black cursor-pointer",
  unselected:
    "   font-medium  font-poppins text-[18px]  text-[#000000]/40 cursor-pointer",
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-lg  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-1 hover:ring-2 ring-gray-300  hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",
  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full   ",
  unSelctedNoAllowed:
    "flex font-sans items-center justify-center font-medium font-poppins text-[18px]  text-[#000000]/40 cursor-not-allowed",
};

const ViewTradeFailureModals = ({
  selectedTab,
  setSelectedTab,
  setTradeFailureModal,
  filteredInconsistencyTrades,
  filteredFailuresTrades,
  filteredMissedTrades,
  selectedUser,
  setSelectedUser,
  userEmailCount,
  setUserEmailCount,
}) => {
  // const [showEmailList, setShowEmailList] = useState(false);

  // const showEmailListDropdown = () => {
  //   setShowEmailList(!showEmailList);
  // };

  const filteredTrades = filteredFailuresTrades?.filter(
    (trade) => trade.user_email === selectedUser
  );

  const handleSelectEmail = (value) => {
    setSelectedUser(value);

    const emailCount = filteredFailuresTrades?.filter(
      (trade) => trade.user_email === value
    ).length;

    setUserEmailCount(emailCount);
  };

  const userEmailList = Array.from(
    new Set(filteredFailuresTrades?.map((trade) => trade.user_email))
  );

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50 ">
      <div className="relative overflow-auto animate-modal">
        <div
          className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
          onClick={() => setTradeFailureModal(false)}
        >
          <XIcon className="w-6 h-6 mr-2" />
        </div>
        <div className="max-w-[750px]  py-8 bg-white  border-[#000000]/20 rounded-md">
          <div className="flex flex-wrap md:flex-nowrap gap-4 w-full border-b-[1px] border-[#000000]/10  h-auto md:h-[60px]  rounded-t-lg px-4 md:px-12 ">
            {filteredFailuresTrades?.length !== 0 ? (
              <div
                className={`${
                  selectedTab === "trade-failure"
                    ? style.selected
                    : style.unselected
                } flex font-sans items-center justify-center  `}
                onClick={() => {
                  setSelectedTab("trade-failure");
                }}
              >
                Trade Failure
              </div>
            ) : (
              <div className={style.unSelctedNoAllowed}>Trade Failure</div>
            )}
            {filteredInconsistencyTrades?.length !== 0 ? (
              <div
                className={`${
                  selectedTab === "trade-inconsistency"
                    ? ` ${style.selected}`
                    : ` ${style.unselected}`
                } flex font-sans items-center justify-center  `}
                onClick={() => {
                  setSelectedTab("trade-inconsistency");
                }}
              >
                Trade Inconsistencies
              </div>
            ) : (
              <div className={style.unSelctedNoAllowed}>
                Trade Inconsistencies
              </div>
            )}
            {filteredMissedTrades?.length !== 0 ? (
              <div
                className={`${
                  selectedTab === "missed-trades"
                    ? ` ${style.selected}`
                    : ` ${style.unselected}`
                } flex font-sans items-center justify-center  `}
                onClick={() => {
                  setSelectedTab("missed-trades");
                }}
              >
                Missed Trades
              </div>
            ) : (
              <div className={style.unSelctedNoAllowed}>Missed Trades</div>
            )}
          </div>

          <div className="relative flex flex-col items-center w-[450px] px-4 md:px-12">
            <div className="flex items-center font-poppins justify-between w-full cursor-pointer">
              <div className="flex items-center space-x-0 py-4">
                <span className="flex items-center justify-center pt-[2px] text-[#ffffff] text-[12px] w-[28px] h-5 rounded-full bg-[#E43D3D]">
                  {userEmailCount}
                </span>
                <div>
                  <Select
                    defaultValue={selectedUser}
                    onValueChange={(value) => handleSelectEmail(value)}
                  >
                    <SelectTrigger className="py-2.5 w-[320px]  text-[16px] font-poppins font-medium border-none ring-0  focus:outline-none focus:ring-0">
                      <SelectValue placeholder={"Select User Email"} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {userEmailList.map((email, i) => {
                          const ele = filteredFailuresTrades?.find(
                            (trade) => trade.user_email === email
                          );

                          return (
                            <SelectItem
                              className="font-poppins font-medium capitalize text-[14px] "
                              key={i}
                              value={ele?.user_email}
                            >
                              {ele?.user_email}
                            </SelectItem>
                          );
                        })}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>

          <div className=" w-full h-[400px] border-t-[1px]   border-[#000000]/10  overflow-auto custom-scroll">
            <table className={`w-full `}>
              <thead className="bg-[#f5f5f5] text-sm text-left sticky top-0">
                <tr className="border-b-[1px]   border-[#000000]/10">
                  <th className="text-[13px]  text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-5 ">
                    Stock Name
                  </th>
                  <th className=" text-[13px] min-w-[90px] lg:w-auto   text-[#000000]/80 text-center font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                    <div className="flex flex-col ">Quantity</div>
                  </th>
                  <th className=" text-[13px] min-w-[100px] lg:w-auto   text-[#000000]/80 text-center font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                    <div className="flex flex-col ">Type</div>
                  </th>
                  <th className=" text-[13px] min-w-[90px] lg:w-auto   text-[#000000]/80 text-center font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                    <div className="flex flex-col ">Price</div>
                  </th>
                  <th className="text-[13px] min-w-[120px]  text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                    Date of Purchase
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredTrades.map((ele, i) => {
                  return (
                    <tr
                      className={`border-b-[1px]   border-[#000000]/10 last-of-type:border-none`}
                      key={i}
                    >
                      <td className="text-[15px]  text-[#000000] font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-5 ">
                        {ele.Symbol}
                      </td>

                      <td
                        className={`text-[15px]  text-[#000000] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 `}
                      >
                        {ele.Quantity}
                      </td>
                      <td
                        className={`text-[15px]  text-[#000000] font-poppins font-medium text-center py-4 px-5 capitalize  
                          ${
                            ele?.Type?.toLowerCase() === "buy"
                              ? "text-[#16A085]"
                              : ele?.Type?.toLowerCase() === "sell"
                              ? "text-[#E43D3D] "
                              : "text-[#000000]/80"
                          } 
                        `}
                      >
                        {ele?.Type?.toLowerCase()}
                      </td>
                      <td className="text-[15px]  text-[#000000] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 ">
                        {ele.OrderType}
                      </td>
                      <td className="text-[15px]  text-[#000000] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 ">
                        {moment(ele.purchaseDate).format("DD/MM/YYYY hh:mm A")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTradeFailureModals;

{
  /* <div className="relative flex flex-col items-center w-[450px] px-12">
            <div
              className="flex items-center font-poppins justify-between w-full cursor-pointer"
              onClick={showEmailListDropdown}
            >
              <div className="flex items-center space-x-4 py-4">
                <span className="flex items-center justify-center text-[#ffffff] w-7 h-7 rounded-full bg-[#E43D3D]">
                  {userEmailCount}
                </span>
                <span className="text-[16px] font-normal  text-[#000000]">
                  {selectedUser}
                </span>
              </div>
              <ChevronDownIcon className="text-[#000000] w-5 h-5  " />
            </div>

            {showEmailList && (
              <div className="absolute  top-[55px] flex flex-col w-[350px] bg-[#000000] rounded-lg  z-50 ">
                {Array.from(
                  new Set(
                    filteredFailuresTrades?.map((trade) => trade.user_email)
                  )
                ).map((email, i) => {
                  const ele = filteredFailuresTrades?.find(
                    (trade) => trade.user_email === email
                  );
                  const emailCount = filteredFailuresTrades?.filter(
                    (trade) => trade.user_email === email
                  ).length;
                  return (
                    <p
                      className=" pl-4 py-3 text-[16px] font-normal text-[#ffffff]/80 font-poppins border-b-[1px] border-[#ffffff]/30 cursor-pointer hover:bg-[#ffffff]/30"
                      key={i}
                      onClick={() => {
                        setSelectedUser(ele?.user_email);
                        setShowEmailList(!showEmailList);
                        setUserEmailCount(emailCount);
                      }}
                    >
                      {ele?.user_email}
                    </p>
                  );
                })}
              </div>
            )}
          </div> */
}
