import { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import axios from "axios";

const useWebSocketCurrentPrice = (stockSymbols) => {
  const [ltp, setLtp] = useState([]);
  const subscribedSymbols = useRef(new Set());
  const socket = useRef(null);

  // Initialize WebSocket connection
  useEffect(() => {
    socket.current = io("wss://ccxt.alphaquark-case.com", {
      transports: ["websocket"],
      query: { EIO: "4" },
    });

    socket.current.on("market_data", (data) => {
      setLtp((prev) => {
        const index = prev.findIndex(
          (item) => item.tradingSymbol === data.stockSymbol
        );
        if (index !== -1) {
          const newLtp = [...prev];
          newLtp[index] = {
            ...newLtp[index],
            lastPrice: data.last_traded_price,
          };
          return newLtp;
        } else {
          return [
            ...prev,
            {
              tradingSymbol: data.stockSymbol,
              lastPrice: data.last_traded_price,
            },
          ];
        }
      });
    });

    // Ping the server every 20 seconds to keep the connection alive
    const pingInterval = setInterval(() => {
      if (socket.current && socket.current.connected) {
        socket.current.emit("ping");
      }
    }, 20000);

    return () => {
      if (socket.current) socket.current.disconnect();
      clearInterval(pingInterval);
    };
  }, []);

  // Subscribe to new stock symbols
  const subscribeToNewSymbols = async () => {
    if (!stockSymbols || stockSymbols.length === 0) return;

    const newSymbols = stockSymbols
      .map((trade) => ({
        exchange: trade.exchange || trade.Exchange,
        tradingSymbol: trade.symbol || trade.Symbol,
      }))
      .filter((symbol) => !subscribedSymbols.current.has(symbol.tradingSymbol));
    for (const symbol of newSymbols) {
      try {
        await axios.post(
          "https://ccxt.alphaquark-case.com/websocket/subscribe",
          {
            symbol: symbol.tradingSymbol,
            exchange: symbol.exchange,
          }
        );
        subscribedSymbols.current.add(symbol.tradingSymbol);
      } catch (error) {
        console.error(`Failed to subscribe to ${symbol.tradingSymbol}:`, error);
      }
    }
  };
  useEffect(() => {
    subscribeToNewSymbols();
  }, [stockSymbols]);

  // Function to get LTP for a symbol
  const getLTPForSymbol = (symbol) => {
    const ltpOne = ltp.find((item) => item.tradingSymbol === symbol)?.lastPrice;
    return ltpOne ? ltpOne.toFixed(2) : "-";
  };

  return {
    ltp,
    getLTPForSymbol,
  };
};

export default useWebSocketCurrentPrice;
