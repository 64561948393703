import React, { useState, useMemo } from "react";
import moment from "moment";
import axios from "axios";
import { CalendarDays } from "lucide-react";
import toast from "react-hot-toast";

import MPF_1 from "../../assests/mpf_1.png";
import server from "../../utils/serverConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import IsMarketHours from "../../utils/isMarketHours";

const RebalanceCard = ({
  data,
  allRebalances,
  sortedRebalances,
  frequency,
  setOpenRebalanceModal,
  modelName,
  userEmail,
  setCaluculatedPortfolioData,
  repair,
  advisorName,
  setModelPortfolioModelId,
  setStoreModalName,
  isInitialRebalance,
  setOpenTokenExpireModel,
  broker,
  setBrokerModel,
  funds,
  overView,
}) => {
  const formatStockCount = (count) => {
    if (count < 10) {
      return `0${count}`;
    }
    return `${count}`;
  };
  const { addedStocks, removedStocks } = useMemo(() => {
    if (!data) {
      return { addedStocks: 0, removedStocks: 0 };
    }

    // For initial rebalance
    if (allRebalances?.length === 1) {
      return {
        addedStocks: data?.adviceEntries?.length || 0,
        removedStocks: 0,
      };
    }

    // For subsequent rebalances
    const currentSymbols = new Set(
      data?.adviceEntries?.map((entry) => entry.symbol) || []
    );

    // Find the previous rebalance
    const previousRebalance = sortedRebalances?.find(
      (rebalance) => rebalance !== data
    );

    if (!previousRebalance) {
      return {
        addedStocks: data?.adviceEntries?.length || 0,
        removedStocks: 0,
      };
    }

    const previousSymbols = new Set(
      previousRebalance?.adviceEntries?.map((entry) => entry.symbol) || []
    );

    const added = [...currentSymbols].filter(
      (symbol) => !previousSymbols.has(symbol)
    ).length;

    const removed = [...previousSymbols].filter(
      (symbol) => !currentSymbols.has(symbol)
    ).length;

    return { addedStocks: added, removedStocks: removed };
  }, [data, allRebalances, sortedRebalances]);

  const formatNumber = (number) => {
    if (number >= 1000 && number < 1000000) {
      return (number / 1000).toFixed(1) + "K"; // Convert to K for thousands
    } else if (number >= 1000000 && number < 1000000000) {
      return (number / 1000000).toFixed(1) + "M"; // Convert to M for millions
    } else if (number >= 1000000000) {
      return (number / 1000000000).toFixed(1) + "B"; // Convert to B for billions
    } else {
      return number?.toString(); // Return the number as it is if less than 1000
    }
  };

  const [loading, setLoading] = useState(false);
  const handleAcceptRebalance = () => {
    const isMarketHours = IsMarketHours();
    setLoading(true);
    if (broker === undefined) {
      setBrokerModel(true);
      setLoading(false);
    } else if (funds?.meesage === "Invalid Token" && funds?.success === false) {
      setOpenTokenExpireModel(true);
    }
    // else if (!isMarketHours) {
    //   setLoading(false);
    //   toast.error("Orders cannot be placed after Market hours.", {
    //     duration: 3000,
    //     style: {
    //       background: "white",
    //       color: "#1e293b",
    //       maxWidth: "500px",
    //       fontWeight: 600,
    //       fontSize: "13px",
    //       padding: "10px 20px",
    //     },
    //     iconTheme: {
    //       primary: "#e43d3d",
    //       secondary: "#FFFAEE",
    //     },
    //   });
    //   return;
    // }
    else {
      if (repair) {
        console.log("-----activated");
        setOpenRebalanceModal(true);
        setModelPortfolioModelId(data.model_Id);
        setLoading(false);
      } else {
        let data2 = JSON.stringify({
          userEmail: userEmail,
          userBroker: broker,
          modelName: modelName,
          advisor: advisorName,
          model_id: data.model_Id,
          userFund: funds?.data?.availablecash,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}rebalance/calculate`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data2,
        };
        axios
          .request(config)
          .then((response) => {
            setLoading(false);
            setCaluculatedPortfolioData(response.data);
            setOpenRebalanceModal(true);
            setModelPortfolioModelId(data.model_Id);
            setStoreModalName(modelName);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    }
  };

  return (
    <div
      className={`${
        repair ? "bg-[#DE8846]" : "bg-[#2A587C]"
      } relative font-poppins w-full rounded-xl  shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)]  py-4`}
    >
      {/* Tooltip Modal  */}

      <div
        className={`flex flex-col  items-start justify-between  mx-5 border-b border-[#ffffff]/50`}
      >
        <div className="flex space-x-2 py-2">
          <p className=" text-[16px] font-poppins text-[#ffffff]/80 capitalize">
            {" "}
            Rebalance Update : {frequency}{" "}
          </p>
        </div>
      </div>
      <div className={`flex flex-col  items-start justify-between  px-5`}>
        <div className="flex flex-row space-x-6 py-4">
          <img src={MPF_1} alt="Portfolio" className="w-20 h-20" />
          <div className="flex flex-col">
            <p className=" text-xl font-medium font-poppins text-[#ffffff] capitalize">
              {" "}
              {data?.updatedModelName}{" "}
            </p>
            <span className="ml-[2px] pt-[8px] text-[14px] text-[#ffffff]/80 font-normal">
              {overView?.split(" ").slice(0, 6).join(" ")}
            </span>
          </div>
        </div>
      </div>

      <div
        className={` flex flex-row items-center justify-between mt-3  pb-1 px-5`}
      >
        <div className="text-[#ffffff] text-xs  font-medium flex flex-row items-center">
          <CalendarDays size={16} className="" />
          <span className="ml-2">
            {moment(data.rebalanceDate).format("Do MMM YYYY")}
          </span>
          <span className="mx-2">|</span>{" "}
          <span>{moment(data.rebalanceDate).format("HH:mm A")}</span>
        </div>
      </div>

      <div className={` flex mt-[10px] px-5`}>
        <div className="flex space-x-2 w-full">
          <button
            className="w-full  py-2.5 px-1 rounded-[4px] bg-white  text-[#1D1D1F] text-xs sm:text-[13px] lg:text-[13px] font-poppins font-medium  hover:scale-[1.03] transition-all duration-150 ease-linear"
            onClick={handleAcceptRebalance}
          >
            {loading === true ? (
              <LoadingSpinner />
            ) : repair ? (
              "Repair Portfolio"
            ) : (
              "Accept Rebalance"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RebalanceCard;
