import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { EyeIcon, EyeOffIcon, Info } from "lucide-react";

import server from "../../utils/serverConfig";
import { TokenExpireModal } from "./TokenExpireModal";
import LoadingSpinner from "../../components/LoadingSpinner";

const style = {
  selected:
    "flex items-center text-[12px] leading-[14px] lg:text-[16px]  font-poppins text-black font-bold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected:
    "flex items-center text-[12px] leading-[14px] font-medium font-poppins lg:text-[16px]  text-[#000000]/40 lg:leading-[42px] cursor-pointer",
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-[18px]  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full  ",
};

const TokenExpireBrokarModal = ({
  openTokenExpireModel,
  setOpenTokenExpireModel,
  userId,
  apiKey,
  secretKey,
  checkValidApiAnSecret,
  clientCode,
  my2pin,
  panNumber,
  mobileNumber,
  getUserDeatils,
  broker,
}) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  // user login

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const handleIiflLogin = () => {
    setLoginLoading(true);
    let data = JSON.stringify({
      clientCode: clientCode,
      password: password,
      my2pin: my2pin,
      userId: userId,
    });

    let config = {
      method: "post",
      url: `${server.server.baseUrl}api/iifl/generate-session`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setLoginLoading(false);
        getUserDeatils();
        toast.success(
          "You have been successfully logged in to IIFL Securities",
          {
            duration: 5000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
        setOpenTokenExpireModel(false);
      })
      .catch((error) => {
        const result = error.response.data.response;
        setLoginLoading(false);
        toast.error(`${result.message}`, {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };
  const [openOtpBox, setOpenOtpBox] = useState(false);
  const [storeResponse, setStoreResponse] = useState();
  const updateKotakSecretKey = () => {
    setLoginLoading(true);
    let data = {
      uid: userId,
      apiKey: apiKey,
      secretKey: secretKey,
    };
    // Conditionally add optional fields
    if (password) {
      data.password = password;
    }

    if (mobileNumber) {
      data.mobileNumber = mobileNumber.toString();
    }

    if (panNumber) {
      data.pan = panNumber;
    }

    let config = {
      method: "post",
      url: `${server.server.baseUrl}api/kotak/update-key`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response) => {
        setLoginLoading(false);
        setStoreResponse(response.data.response);
        setOpenOtpBox(true);
        // Handle success response
      })
      .catch((error) => {
        console.log(error);
        setLoginLoading(false);
        toast.error("Incorrect credential. Please try again", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  const [showMpin, setShowMpin] = useState(false);
  const [mpin, setMpin] = useState();
  const [otp, setOtp] = useState();

  const handleKotakLogin = () => {
    setLoginLoading(true);
    let data = {
      uid: userId,
      apiKey: apiKey,
      secretKey: secretKey,
      jwtToken: storeResponse.access_token,
      password: password,
      otp: otp,
      sid: storeResponse.sid,
      viewToken: storeResponse.view_token,
    };

    // Conditionally add optional fields (pan, mobile, userId)
    if (panNumber) {
      data.pan = panNumber;
    } else if (mobileNumber) {
      data.mobileNumber = mobileNumber;
    }

    // Conditionally add optional fields

    let config = {
      method: "put",
      url: `${server.server.baseUrl}api/kotak/connect-broker`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response) => {
        setLoginLoading(false);
        toast.success("You have been successfully logged in to your broker.", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        getUserDeatils();
        setOpenTokenExpireModel(false);
      })
      .catch((error) => {
        console.log(error);
        setLoginLoading(false);
        toast.error("Incorrect credential. Please try again", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };
  useEffect(() => {
    if (showSuccessMsg) {
      setShowSuccessMsg(true);
      const toRef = setTimeout(() => {
        setShowSuccessMsg(false);
        clearTimeout(toRef);
      }, 4000);
    }
  }, [showSuccessMsg]);

  const [zerodhaLoading, setZerodhaLoading] = useState(false);
  const connectZerodha = () => {
    setZerodhaLoading(true);
    let data = JSON.stringify({
      apiKey: "b0g1r806oitsamoe",
      site: "test",
    });

    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}zerodha/login_url`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setZerodhaLoading(false);
        window.location.href = `${response.data}`;
      })
      .catch((error) => {
        setZerodhaLoading(false);
        console.log(error);
      });
  };
  return (
    <TokenExpireModal
      openTokenExpireModel={openTokenExpireModel}
      setOpenTokenExpireModel={setOpenTokenExpireModel}
    >
      <div className="flex flex-col items-center justify-center space-y-2">
        <div className="flex items-center justify-center rounded-full p-2 ">
          <Info className="text-[#000000]/50 w-16 h-16 " />
        </div>
      </div>
      <div className="flex space-x-4 items-center justify-center  text-black text-center text-2xl font-bold mt-4 mb-4">
        <span className="text-[20px] text-[#000000] font-medium font-poppins text-center">
          Please login to your broker to continue investments
        </span>
      </div>
      <div className="md:flex flex-col space-y-2">
        {broker === "IIFL Securities" ? (
          <div className="md:flex flex-col space-y-2">
            <div className={style.inputStartDiv}>
              <input
                id="clientCode"
                placeholder="Client Code"
                type="text"
                value={clientCode}
                disabled
                className={`${style.inputBox}`}
              />
              <label
                htmlFor="clientCode"
                className={`${style.firstHeading} ${style.labelFloat}`}
              >
                Client Code
              </label>
            </div>
            <div className={style.inputStartDiv}>
              <input
                id="my2pin"
                placeholder="My2Pin"
                type="text"
                value={my2pin}
                disabled
                className={`${style.inputBox}`}
              />
              <label
                htmlFor="my2pin"
                className={`${style.firstHeading} ${style.labelFloat}`}
              >
                My2Pin
              </label>
            </div>
            <div className={`relative ${style.inputStartDiv} `}>
              <input
                id="pass"
                placeholder="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`${style.inputBox}`}
              />
              <label
                htmlFor="pass"
                className={`${style.firstHeading} ${style.labelFloat}`}
              >
                Password
              </label>
              {showPassword ? (
                <EyeIcon
                  onClick={() => setShowPassword(false)}
                  className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                />
              ) : (
                <EyeOffIcon
                  onClick={() => setShowPassword(true)}
                  className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                />
              )}
            </div>
          </div>
        ) : null}
      </div>

      {broker === "IIFL Securities" ? (
        <div
          className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
          onClick={handleIiflLogin}
        >
          {loginLoading === true ? (
            <LoadingSpinner />
          ) : (
            <span className="text-[16px] text-white font-semibold font-poppins">
              Login to {broker ? broker : ""}
            </span>
          )}
        </div>
      ) : broker === "ICICI Direct" ? (
        <div className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer">
          <a
            href={`https://api.icicidirect.com/apiuser/login?api_key=${encodeURIComponent(
              checkValidApiAnSecret(apiKey ? apiKey : "")
            )}`}
          >
            <span className="text-[16px] text-white font-semibold font-poppins">
              Login to {broker ? broker : ""}
            </span>
          </a>
        </div>
      ) : broker === "Upstox" ? (
        <div className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer">
          <a
            href={`https://api.upstox.com/v2/login/authorization/dialog?response_type=code&client_id=${checkValidApiAnSecret(
              apiKey
            )}&redirect_uri=https://test.alphaquark-case.com/stock-recommendation&state=ccxt`}
          >
            <span className="text-[16px] text-white font-semibold font-poppins">
              Login to {broker ? broker : ""}
            </span>
          </a>
        </div>
      ) : broker === "Zerodha" ? (
        <div
          className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
          onClick={connectZerodha}
        >
          {zerodhaLoading === true ? (
            <LoadingSpinner />
          ) : (
            <span className="text-[16px] text-white font-semibold font-poppins">
              Login to {broker ? broker : ""}
            </span>
          )}
        </div>
      ) : broker === "Kotak" ? (
        <div className="md:flex flex-col space-y-3">
          {panNumber ? (
            <div className={style.inputStartDiv}>
              <input
                id="clientCode"
                placeholder="Client Code"
                type="text"
                value={panNumber}
                disabled
                className={`${style.inputBox}`}
              />
              <label
                htmlFor="clientCode"
                className={`${style.firstHeading} ${style.labelFloat}`}
              >
                Pan Number
              </label>
            </div>
          ) : (
            <div className={style.inputStartDiv}>
              <input
                id="clientCode"
                placeholder="Client Code"
                type="text"
                value={mobileNumber}
                disabled
                className={`${style.inputBox}`}
              />
              <label
                htmlFor="clientCode"
                className={`${style.firstHeading} ${style.labelFloat}`}
              >
                MobileNumber
              </label>
            </div>
          )}
          <div className={`relative ${style.inputStartDiv} `}>
            <input
              id="pass"
              placeholder="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`${style.inputBox}`}
            />
            <label
              htmlFor="pass"
              className={`${style.firstHeading} ${style.labelFloat}`}
            >
              Password
            </label>
            {showPassword ? (
              <EyeIcon
                onClick={() => setShowPassword(false)}
                className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
              />
            ) : (
              <EyeOffIcon
                onClick={() => setShowPassword(true)}
                className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
              />
            )}
          </div>
          {openOtpBox === true ? (
            <div className={`relative ${style.inputStartDiv} `}>
              <input
                id="otp"
                placeholder="Otp"
                type={"number"}
                value={mpin}
                onChange={(e) => setMpin(e.target.value)}
                className={`${style.inputBox}`}
              />
              <label
                htmlFor="otp"
                className={`${style.firstHeading} ${style.labelFloat}`}
              >
                Otp
              </label>
            </div>
          ) : null}
          {openOtpBox === true ? (
            <div className={`relative ${style.inputStartDiv} `}>
              <input
                id="mpin"
                placeholder="Mpin"
                type={showMpin ? "number" : "Mpin"}
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className={`${style.inputBox}`}
              />
              <label
                htmlFor="mpin"
                className={`${style.firstHeading} ${style.labelFloat}`}
              >
                Mpin
              </label>
              {showMpin ? (
                <EyeIcon
                  onClick={() => setShowMpin(false)}
                  className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                />
              ) : (
                <EyeOffIcon
                  onClick={() => setShowMpin(true)}
                  className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                />
              )}
            </div>
          ) : null}
          {openOtpBox === true ? (
            <div
              className="mt-8 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
              onClick={handleKotakLogin}
            >
              {loginLoading === true ? (
                <LoadingSpinner />
              ) : (
                <span className="text-[16px] text-white font-semibold font-poppins">
                  Submit
                </span>
              )}
            </div>
          ) : (
            <div
              className="mt-8 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
              onClick={updateKotakSecretKey}
            >
              {loginLoading === true ? (
                <LoadingSpinner />
              ) : (
                <span className="text-[16px] text-white font-semibold font-poppins">
                  Login to {broker ? broker : ""}
                </span>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer">
          <a href="https://smartapi.angelbroking.com/publisher-login?api_key=EfkDdJMH">
            <span className="text-[16px] text-white font-semibold font-poppins">
              Login to {broker ? broker : ""}
            </span>
          </a>
        </div>
      )}
    </TokenExpireModal>
  );
};

export default TokenExpireBrokarModal;
