import React from "react";
import MPF_Icon from "../../assests/mpf_icon.png";
import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon } from "lucide-react";
import { cn } from "../../lib/utils";
import ModalPFCard from "./ModelPFCard";

const ModalPFList = ({
  children,
  className,
  showModalDropdown,
  setShowModalDropdown,
  dropdownRef,
  modelPortfolioStrategy,
  modelPortfolioRepairTrades,
  userEmail,
}) => {
  return (
    <div className="mt-4 flex flex-col space-y-2 bg-[#2A587C] rounded-[6px] shadow-[3px_5px_20px_0px_rgba(0,0,0,0.06)]  pt-4 ">
      <div
        className={cn("bg-[#2A587C] w-full grid grid-cols-7  py-3", className)}
      >
        <div className="flex col-span-2 pl-10 ">
          <div className="text-[#ffffff]/60 font-normal font-poppins text-[14px]">
            Modal Portfolio Name
          </div>
        </div>
        <div className="flex items-center justify-center  ">
          <div className="text-[#ffffff]/60 font-normal font-poppins text-[14px]">
            Invested Amount
          </div>
        </div>
        <div className="flex items-center justify-center  ">
          <div className="text-[#ffffff]/60 font-normal font-poppins text-[14px]">
            Current Amount
          </div>
        </div>
        <div className="flex items-center justify-center ">
          <div className="text-[#ffffff]/60 font-normal font-poppins text-[14px]">
            Absolute Returns
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="text-[#ffffff]/60 font-normal font-poppins text-[14px]">
            Net Returns
          </div>
        </div>
        <div className="flex items-center justify-center  ">
          <div className="text-[#ffffff]/60 font-normal font-poppins text-[14px]">
            Details
          </div>
        </div>
      </div>

      <div
        className={`flex flex-col bg-[#000000]/10 divide-[#ffffff]/10 divide-y-[1px]  animate-slideDown `}
      >
        {modelPortfolioStrategy?.length !== 0 &&
          modelPortfolioStrategy?.map((ele, i) => {
            const allRebalances = ele?.model?.rebalanceHistory || [];

            const sortedRebalances = allRebalances?.sort(
              (a, b) => new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
            );
            const latest = sortedRebalances[0];

            if (!latest) return null;

            const matchingFailedTrades = modelPortfolioRepairTrades?.find(
              (trade) =>
                trade.modelId === latest?.model_Id &&
                trade.failedTrades.length !== 0
            );

            return (
              <ModalPFCard
                key={i}
                modelName={ele?.model_name}
                data={latest}
                repair={matchingFailedTrades ? "repair" : null}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ModalPFList;
