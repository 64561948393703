import React, { useState, useEffect } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import { XIcon } from "lucide-react";

import { useMediaQuery } from "../../hooks/use-media-query";
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
} from "../../components/ui/drawer";

import LoadingSpinner from "../../components/LoadingSpinner";
import server from "../../utils/serverConfig";

import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";

const UpdateRebalanceModal = ({
  userEmail,
  openRebalanceModal,
  setOpenRebalanceModal,
  data,
  calculatedPortfolioData,
  broker,
  apiKey,
  jwtToken,
  secretKey,
  clientCode,
  funds,
  setOpenSucessModal,
  setOrderPlacementResponse,
  modelPortfolioModelId,
  modelPortfolioRepairTrades,
  getRebalanceRepair,
  storeModalName,
  getModelPortfolioStrategyDetails,
}) => {
  const isDesktop = useMediaQuery("(min-width: 830px)");
  const [loading, setLoading] = useState();

  const checkValidApiAnSecret = (apiKey) => {
    const bytesKey = CryptoJS.AES.decrypt(apiKey, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8);
    if (Key) {
      return Key;
    }
  };

  const filteredData = data.filter(
    (item) => item.model_name === storeModalName
  );

  // Now, let's find the matching repair trade
  const matchingRepairTrade =
    modelPortfolioRepairTrades &&
    modelPortfolioRepairTrades?.find(
      (trade) => trade.modelId === modelPortfolioModelId
    );

  const repairStatus =
    matchingRepairTrade &&
    matchingRepairTrade.failedTrades &&
    matchingRepairTrade.failedTrades.length > 0;

  // Check if modelPortfolioRepairTrades exists and has trades
  let dataArray = [];

  if (repairStatus) {
    dataArray = matchingRepairTrade.failedTrades.map((trade) => ({
      symbol: trade.advSymbol,
      qty: parseInt(trade.advQTY, 10),
      orderType: trade.transactionType.toUpperCase(),
      exchange: trade.advExchange,
    }));
  } else if (calculatedPortfolioData && calculatedPortfolioData?.length !== 0) {
    dataArray = [
      ...Object.entries(calculatedPortfolioData?.buy || {}).map(
        ([symbol, qty]) => ({
          symbol,
          qty,
          orderType: "BUY",
          exchange: symbol.endsWith("-EQ") ? "NSE" : "BSE",
        })
      ),
      ...Object.entries(calculatedPortfolioData?.sell || {}).map(
        ([symbol, qty]) => ({
          symbol,
          qty,
          orderType: "SELL",
          exchange: symbol.endsWith("-EQ") ? "NSE" : "BSE",
        })
      ),
    ];
  }

  const { getLTPForSymbol } = useWebSocketCurrentPrice(dataArray);

  const totalInvestmentValue = dataArray
    .filter((item) => item.orderType === "BUY")
    .reduce((total, item) => {
      const currentPrice = getLTPForSymbol(item.symbol);
      const investment = item.qty * currentPrice;
      return total + investment;
    }, 0);

  const cashRequired = dataArray.reduce((totalCash, item) => {
    const currentPrice = getLTPForSymbol(item.symbol); // Get the current price for the symbol
    if (item.orderType === "BUY") {
      // Buy order: Add buy price * quantity
      totalCash += item.qty * currentPrice;
    } else if (item.orderType === "SELL") {
      // Sell order: Subtract sell price * quantity * 80%
      totalCash -= item.qty * currentPrice * 0.8;
    }
    return totalCash;
  }, 0);

  const convertResponse = (dataArray) => {
    return dataArray.map((item) => {
      return {
        transactionType: item.orderType,
        exchange: item.exchange,
        segment: "EQUITY",
        productType: "DELIVERY",
        orderType: "MARKET",
        price: 0,
        tradingSymbol: item.symbol,
        quantity: item.qty,
        priority: 0,
        user_broker: broker,
      };
    });
  };

  const stockDetails = convertResponse(dataArray);

  const placeOrder = () => {
    setLoading(true);
    const matchingRepairTrade = Array.isArray(modelPortfolioRepairTrades)
      ? modelPortfolioRepairTrades.find(
          (trade) => trade.model_id === modelPortfolioModelId
        )
      : modelPortfolioRepairTrades?.model_id === modelPortfolioModelId
      ? modelPortfolioRepairTrades
      : null;
    if (broker === "IIFL Securities") {
      let payload = {
        clientCode: clientCode,
        user_broker: broker,
        user_email: userEmail,
        trades: stockDetails,
      };

      if (matchingRepairTrade) {
        payload = {
          ...payload,
          modelName: matchingRepairTrade.modelName,
          advisor: matchingRepairTrade.advisorName,
          model_id: matchingRepairTrade.model_id,
          unique_id: matchingRepairTrade?.uniqueId,
        };
      } else {
        payload = {
          ...payload,
          modelName: filteredData[0]["model_name"],
          advisor: filteredData[0]["advisor"],
          model_id: modelPortfolioModelId,
          unique_id: calculatedPortfolioData?.uniqueId,
        };
      }
      let config2 = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}rebalance/process-trade`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      };

      axios
        .request(config2)
        .then((response) => {
          setOrderPlacementResponse(response.data.results);
          getRebalanceRepair();
          const updateData = {
            modelId: modelPortfolioModelId,
            orderResults: response.data.results,
            userEmail: userEmail,
            modelName: filteredData[0]["model_name"],
          };

          // Make sure to return this promise
          return axios.post(
            `${server.server.baseUrl}api/model-portfolio-db-update`,
            updateData
          );
        })
        .then((updateResponse) => {
          setLoading(false);
          setOpenSucessModal(true);
          setOpenRebalanceModal(false);
          getModelPortfolioStrategyDetails();
        })

        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "ICICI Direct") {
      let payload = {
        apiKey: checkValidApiAnSecret(apiKey),
        secretKey: checkValidApiAnSecret(secretKey),
        sessionToken: jwtToken,
        user_broker: broker,
        user_email: userEmail,
        trades: stockDetails,
      };

      if (matchingRepairTrade) {
        payload = {
          ...payload,
          modelName: matchingRepairTrade.modelName,
          advisor: matchingRepairTrade.advisorName,
          model_id: matchingRepairTrade.model_id,
          unique_id: matchingRepairTrade?.uniqueId,
        };
      } else {
        payload = {
          ...payload,
          modelName: filteredData[0]["model_name"],
          advisor: filteredData[0]["advisor"],
          model_id: modelPortfolioModelId,
          unique_id: calculatedPortfolioData?.uniqueId,
        };
      }
      let config2 = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}rebalance/process-trade`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      };

      axios
        .request(config2)
        .then((response) => {
          setOrderPlacementResponse(response.data.results);
          getRebalanceRepair();
          const updateData = {
            modelId: modelPortfolioModelId,
            orderResults: response.data.results,
            userEmail: userEmail,
            modelName: filteredData[0]["model_name"],
          };

          // Make sure to return this promise
          return axios.post(
            `${server.server.baseUrl}api/model-portfolio-db-update`,
            updateData
          );
        })
        .then((updateResponse) => {
          setLoading(false);
          setOpenSucessModal(true);
          setOpenRebalanceModal(false);
          getModelPortfolioStrategyDetails();
        })

        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "Upstox") {
      let payload = {
        apiKey: checkValidApiAnSecret(apiKey),
        secretKey: checkValidApiAnSecret(secretKey),
        accessToken: jwtToken,
        user_broker: broker,
        user_email: userEmail,
        trades: stockDetails,
      };

      if (matchingRepairTrade) {
        payload = {
          ...payload,
          modelName: matchingRepairTrade.modelName,
          advisor: matchingRepairTrade.advisorName,
          model_id: matchingRepairTrade.model_id,
          unique_id: matchingRepairTrade?.uniqueId,
        };
      } else {
        payload = {
          ...payload,
          modelName: filteredData[0]["model_name"],
          advisor: filteredData[0]["advisor"],
          model_id: modelPortfolioModelId,
          unique_id: calculatedPortfolioData?.uniqueId,
        };
      }
      let config2 = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}rebalance/process-trade`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      };

      axios
        .request(config2)
        .then((response) => {
          setOrderPlacementResponse(response.data.results);
          getRebalanceRepair();
          const updateData = {
            modelId: modelPortfolioModelId,
            orderResults: response.data.results,
            userEmail: userEmail,
            modelName: filteredData[0]["model_name"],
          };

          // Make sure to return this promise
          return axios.post(
            `${server.server.baseUrl}api/model-portfolio-db-update`,
            updateData
          );
        })
        .then((updateResponse) => {
          setLoading(false);
          setOpenSucessModal(true);
          setOpenRebalanceModal(false);
          getModelPortfolioStrategyDetails();
        })

        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "Angel One") {
      let payload = {
        apiKey: apiKey,
        jwtToken: jwtToken,
        user_broker: broker,
        user_email: userEmail,
        trades: stockDetails,
      };

      if (matchingRepairTrade) {
        payload = {
          ...payload,
          modelName: matchingRepairTrade.modelName,
          advisor: matchingRepairTrade.advisorName,
          model_id: matchingRepairTrade.model_id,
          unique_id: matchingRepairTrade?.uniqueId,
        };
      } else {
        payload = {
          ...payload,
          modelName: filteredData[0]["model_name"],
          advisor: filteredData[0]["advisor"],
          model_id: modelPortfolioModelId,
          unique_id: calculatedPortfolioData?.uniqueId,
        };
      }
      let config2 = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}rebalance/process-trade`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      };
      axios
        .request(config2)
        .then((response) => {
          setOrderPlacementResponse(response.data.results);
          getRebalanceRepair();
          const updateData = {
            modelId: modelPortfolioModelId,
            orderResults: response.data.results,
            userEmail: userEmail,
            modelName: filteredData[0]["model_name"],
          };

          // Make sure to return this promise
          return axios.post(
            `${server.server.baseUrl}api/model-portfolio-db-update`,
            updateData
          );
        })
        .then((updateResponse) => {
          setLoading(false);
          setOpenSucessModal(true);
          setOpenRebalanceModal(false);
          getModelPortfolioStrategyDetails();
        })

        .catch((error) => {
          console.log(error);
        });
    }
  };

  if (isDesktop) {
    return (
      <Dialog open={openRebalanceModal} onOpenChange={setOpenRebalanceModal}>
        <DialogContent className="max-w-[55vw] w-full p-0">
          <div className="flex flex-col  w-full  rounded-[8px] pt-4  h-[630px] lg:h-auto bg-[#ffffff] ">
            <div className="px-6 py-6 flex flex-col space-y-3  shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] ">
              <div className="text-[22px] text-black font-bold leading-[40px] font-sans">
                Strategy Rebalance Update
              </div>
              {/*
              {data.length > 1 ? (
                <Stepper steps={steps} currentStep={currentStep} />
              ) : null}
                */}
            </div>

            <div className="mt-4">
              <div className="px-8 text-lg font-semibold font-poppins">
                Amount of cash required to place this order today : ₹{" "}
                {parseFloat(cashRequired).toFixed(2)}
              </div>
              <div className=" w-full border-t-[1px]   border-[#000000]/10 h-[380px]  mt-2 overflow-auto custom-scroll">
                <table className={`w-full  `}>
                  <thead className="bg-[#f5f5f5]  sticky top-0 z-20 ">
                    <tr className="border-b-[1px]   border-[#000000]/10">
                      <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                        Stocks
                      </th>

                      <th className="text-[12px] min-w-[120px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Current Price (₹)
                      </th>

                      <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Quantity
                      </th>
                      <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Order Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataArray?.map((item, i) => {
                      const currentLTP = getLTPForSymbol(item.symbol);
                      return (
                        <tr
                          className={`border-b-[1px]   border-[#000000]/10 last-of-type:border-none`}
                          key={i}
                        >
                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8 ">
                            {item.symbol}
                          </td>

                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-normal text-center py-3 px-5 ">
                            ₹ {currentLTP}
                          </td>

                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 ">
                            {item.qty}
                          </td>
                          <td
                            className={
                              item?.orderType?.toLowerCase() === "buy"
                                ? "text-[#16A085] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                                : item?.orderType?.toLowerCase() === "sell"
                                ? "text-[#E43D3D] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                                : "text-[#000000]/80 text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                            }
                          >
                            {item.orderType?.toLowerCase()}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <DialogFooter className="flex sm:justify-between  px-8 py-4 shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.06)]">
            <div className=" flex flex-row items-center">
              <div className=" text-[15px] leading-[22px] text-black font-semibold lg:text-base lg:leading-[40px] font-sans">
                Confirm Total Amount :
              </div>
              <div className="px-4 py-1 lg:py-1 lg:px-2 text-lg text-[#000000]/80 font-bold ">
                ₹ {totalInvestmentValue.toFixed(2)}
              </div>
            </div>
            <button
              className="w-[180px]  px-8 py-3 ml-4 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
              onClick={placeOrder}
            >
              {loading === true ? (
                <LoadingSpinner />
              ) : (
                <span className="text-[18px] font-medium text-[#ffffff] font-poopins ">
                  Place Order
                </span>
              )}
            </button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Drawer open={openRebalanceModal} onOpenChange={setOpenRebalanceModal}>
      <DrawerContent className="focus:outline-none max-h-[550px] md:max-h-[690px]">
        <div
          onClick={() => {
            setOpenRebalanceModal(false);
          }}
          className="absolute top-4 right-3 flex items-center justify-center "
        >
          <XIcon className="h-5 w-5 text-gray-900" />
        </div>
        <div className="pt-4 h-full ">
          <div className="px-6 flex flex-row justify-between items-center ">
            <div className="text-xl text-black font-semibold leading-[40px] font-sans">
              Strategy Rebalance Update
            </div>
          </div>

          <div className="px-6 text-sm font-semibold font-poppins">
            Amount of cash required to place this order today : ₹{" "}
            {parseFloat(cashRequired).toFixed(2)}
          </div>

          <div className=" w-full h-[290px]  border-t-[1px] border-[#000000]/10 mt-2 overflow-auto custom-scroll">
            {dataArray.map((ele, i) => {
              const currentLTP = getLTPForSymbol(ele.symbol);
              return (
                <div
                  className="flex items-center  border-b-[1px] border-[#000000]/10  py-4 px-4 bg-white font-poppins"
                  key={i}
                >
                  <div className="flex flex-1 items-start flex-col space-y-[6px] overflow-hidden">
                    <div className="text-[14px] truncate w-full   text-[#000000]/80 font-poppins font-semibold">
                      {ele.symbol}
                    </div>
                    <div
                      className={`${
                        ele.orderType?.toLowerCase() === "buy"
                          ? "bg-[#16A085]/10 text-[#16A085] "
                          : "bg-[#EA2D3F]/10 text-[#EA2D3F]"
                      } flex items-center px-2 py-[1px] rounded-[4px]`}
                    >
                      <span className=" text-[14px] lg:text-[12px]  px-1 py-0  font-poppins font-medium capitalize">
                        {ele.orderType?.toLowerCase()}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center justify-between pr-4 text-[14px] text-[#000000] font-poppins font-medium">
                    ₹ {currentLTP}
                  </div>

                  <div className="flex pl-4 flex-col items-end space-y-[6px]">
                    <div className="text-[14px]  text-[#000000] font-poppins font-medium">
                      {ele.qty}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <DrawerFooter className="pt-2 flex flex-col  w-full shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.06)]">
          <div className="flex  items-center justify-between space-x-4">
            <div className="flex flex-col items-start ">
              <div className=" min-w-[110px] text-[10px] leading-[22px] text-black font-semibold lg:text-base lg:leading-[24px] font-sans">
                Total Amount :
              </div>
              <div className="py-1 text-[18px] text-[#000000]/80 font-semibold   rounded-[4px]">
                ₹ {totalInvestmentValue.toFixed(2)}
              </div>
            </div>

            <button
              className=" w-full px-8 py-3 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
              onClick={placeOrder}
            >
              {loading === true ? (
                <LoadingSpinner />
              ) : (
                <span className="text-[18px] font-semibold text-[#ffffff] font-poopins ">
                  Place Order
                </span>
              )}
            </button>
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default UpdateRebalanceModal;
