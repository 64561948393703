import React, { useEffect, useState } from "react";
import { Edit2Icon, EyeIcon, PlusIcon, Trash2Icon, XIcon } from "lucide-react";
import FormatDateTime from "../../utils/formatDateTime";
import { useNavigate } from "react-router-dom";
import server from "../../utils/serverConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import toast from "react-hot-toast";

const GroupListTable = ({
  userId,
  allClientsData,
  allGroupsData,
  getAllGroupsData,
  getAllClientsData,
  searchQuery,
  openAddGroupModal,
}) => {
  const navigate = useNavigate();

  const [allData, setAlData] = useState(allGroupsData);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [groupMemberCounts, setGroupMemberCounts] = useState({});
  const [filteredGroupData, setFilteredGroupData] = useState(allData);

  

  useEffect(() => {
    setAlData(allGroupsData);
    const calculateMemberCounts = () => {
      const counts = {};

      allGroupsData.forEach((group) => {
        counts[group.groupName] = allClientsData.filter((client) =>
          client.groups.includes(group.groupName)
        ).length;
      });

      setGroupMemberCounts(counts);
    };

    calculateMemberCounts();
  }, [allGroupsData, allClientsData]);

  useEffect(() => {
    if (searchQuery) {
      // Step 1: Find clients with matching email
      const matchingClients = allClientsData.filter((client) =>
        client.email.toLowerCase().includes(searchQuery.toLowerCase())
      );

      // Step 2: Extract groups from matching clients
      const matchingGroupNames = new Set(
        matchingClients.flatMap((client) => client.groups)
      );

      // Step 3: Filter allGroupsData based on matching group names
      const filteredGroupsNames = allData.filter((group) =>
        matchingGroupNames.has(group.groupName)
      );

      setFilteredGroupData(filteredGroupsNames);
    } else {
      // Reset to all groups if searchQuery is empty
      setFilteredGroupData(allGroupsData);
    }
  }, [searchQuery, allClientsData, allData]);

  const handleEdit = (group) => {
    setCurrentGroup(group);
    setShowEditModal(true);
  };

  const handleDelete = (group) => {
    setCurrentGroup(group);
    setShowDeleteModal(true);
  };

  const handleSaveEdit = async () => {
    console.log("New Group Name:", currentGroup?.groupName);

    try {
      const response = await fetch(`${server.server.baseUrl}api/edit-group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          groupId: currentGroup?._id,
          newGroupName: currentGroup?.groupName?.replace(/_/g, " "),
        }),
      });

      if (response.ok) {
        console.log("Group name updated successfully");
        getAllGroupsData(userId);
        getAllClientsData(userId);
        setShowEditModal(false);
        toast.success("Group name updated successfully !!", {
          duration: 3000,
          style: {
        background: "white",
        color: "#1e293b",
        maxWidth: "500px",
        fontWeight: 600,
        fontSize: "13px",
        padding: "10px 20px",
      },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      } else {
        console.error("Failed to update group name");
        setShowEditModal(false);
        toast.error("Failed to update group name !", {
          duration: 3000,
          style: {
        background: "white",
        color: "#1e293b",
        maxWidth: "500px",
        fontWeight: 600,
        fontSize: "13px",
        padding: "10px 20px",
      },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error updating group name:", error);
      toast.error("Error in updating group name !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      setShowEditModal(false);
    }
  };

  const handleConfirmDelete = async () => {
    console.log("Current Group", currentGroup.groupName);
    try {
      const response = await fetch(`${server.server.baseUrl}api/delete-group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ groupId: currentGroup?._id }),
      });

      if (response.ok) {
        console.log("Group name deleted successfully");
        getAllGroupsData(userId);
        getAllClientsData(userId);
        setShowDeleteModal(false);
        toast.success(` ${currentGroup.groupName} deleted successfully !!`, {
          duration: 3000,
          style: {
        background: "white",
        color: "#1e293b",
        maxWidth: "500px",
        fontWeight: 600,
        fontSize: "13px",
        padding: "10px 20px",
      },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      } else {
        console.error("Failed to delete group name");
        setShowDeleteModal(false);
        toast.error("Failed to delete group name !", {
          duration: 3000,
          style: {
        background: "white",
        color: "#1e293b",
        maxWidth: "500px",
        fontWeight: 600,
        fontSize: "13px",
        padding: "10px 20px",
      },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error deleting group name:", error);
      setShowDeleteModal(false);
      toast.error("Error in deleting group name !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleViewFile = (groupName) => {
    navigate(`/admin/client-list/${groupName}`);
  };

  return (
    <div className="w-full h-full max-h-[calc(100vh-225px)] lg:max-h-[calc(100vh-50px)] overflow-auto mb-4">
      <table className="min-w-full bg-white font-poppins table-fixed">
        <thead className="bg-[#f5f5f5] sticky top-0 z-20">
          <tr>
            <th className=" w-[50px]  py-3 font-medium text-[13px] font-poppins text-[#000000]/80 border-r-[1px] border-[#000000]/10">
              <div className="flex justify-center">
                <div
                  onClick={openAddGroupModal}
                  className="w-5 h-5 bg-[#000000] rounded-[2px] flex items-center justify-center cursor-pointer"
                >
                  <PlusIcon
                    strokeWidth={3}
                    className="text-[#ffffff] w-[18px] h-[18px]"
                  />
                </div>
              </div>
            </th>
            <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80 text-left border-r-[1px] border-[#000000]/10">
              Group Name
            </th>
            <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80 text-center border-r-[1px] border-[#000000]/10">
              No. of Members
            </th>
            <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80 text-center border-r-[1px] border-[#000000]/10">
              Creation Date
            </th>
            <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80 text-center border-r-[1px] border-[#000000]/10">
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          {filteredGroupData &&
            filteredGroupData.map((item, index) => {
              if (item.groupName.toLowerCase() !== "all client") {
                return (
                  <tr
                    key={index}
                    className="border-b-[1px] border-[#000000]/10"
                  >
                    <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80"></td>
                    <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80 capitalize">
                      {item.groupName?.replace(/_/g, " ")}
                    </td>
                    <td className="px-3 py-4 font-normal text-[13px] text-center font-poppins text-[#000000]/80">
                      {groupMemberCounts[item.groupName] || 0}
                    </td>
                    <td className="px-3 py-4 font-normal text-[13px] text-center font-poppins text-[#000000]/80">
                      {FormatDateTime(item.createdAt)}
                    </td>
                    <td className="px-3 py-4 font-normal text-[13px] font-poppins text-[#000000]/80">
                      <div className="flex items-center justify-center space-x-2">
                        <button
                          
                          className="cursor-pointer disabled:cursor-not-allowed"
                        >
                          <EyeIcon
                            onClick={() => handleViewFile(item.groupName)}
                            className={`  text-[#000000]/80 h-4 w-4`}
                          />
                        </button>

                        <Edit2Icon
                          className="text-[#000000]/80 h-4 w-4 cursor-pointer"
                          onClick={() => handleEdit(item)}
                        />
                        <Trash2Icon
                          className="text-[#000000]/80 h-4 w-4 cursor-pointer"
                          onClick={() => handleDelete(item)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              }
            })}
        </tbody>
      </table>

      {showEditModal && (
        <div className="fixed inset-0 flex items-center font-poppins justify-center bg-black bg-opacity-50 z-50">
          <div className="relative">
            <div
              className="absolute right-2 top-4 text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer"
              onClick={() => setShowEditModal(false)}
            >
              <XIcon className="w-6 h-6 mr-2" />
            </div>
            <div className="bg-white p-6 rounded-lg w-[450px]">
              <h3 className="text-lg font-semibold mb-4">Edit Group Name</h3>
              <div className="grid grid-cols-1 gap-4 font-poppins">
                <label className="flex flex-col">
                  <input
                    type="text"
                    name="groupName"
                    value={currentGroup.groupName}
                    onChange={(e) =>
                      setCurrentGroup({
                        ...currentGroup,
                        groupName: e.target.value,
                      })
                    }
                    className="p-2 border rounded"
                  />
                </label>
              </div>
              <div className="mt-6 flex justify-end space-x-4">
                <button
                  onClick={() => setShowEditModal(false)}
                  className="bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[18px] font-medium font-poppins rounded-md px-4 py-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveEdit}
                  className="bg-[#1D1D1D] text-white text-[18px] font-medium font-poppins rounded-md px-4 py-2 hover:scale-[1.04] transition-all duration-150 ease-linear"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center font-poppins justify-center bg-black bg-opacity-50 z-50">
          <div className="relative">
            <div
              className="absolute right-2 top-4 text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer"
              onClick={() => setShowDeleteModal(false)}
            >
              <XIcon className="w-6 h-6 mr-2" />
            </div>
            <div className="bg-white p-6 rounded-lg w-[450px]">
              <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
              <p className="font-poppins">
                Are you sure you want to delete the group
                <span className="font-semibold ml-1">
                  {currentGroup.groupName}
                </span>
                ?
              </p>
              <div className="mt-6 flex justify-end space-x-4">
                <button
                  onClick={() => setShowDeleteModal(false)}
                  className="bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[18px] font-medium font-poppins rounded-md px-4 py-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmDelete}
                  className="bg-[#ff0000] text-white text-[18px] font-medium font-poppins rounded-md px-4 py-2 hover:scale-[1.04] transition-all duration-150 ease-linear"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupListTable;
