import React, { useEffect, useState } from "react";
import DashboardNav from "./DashboardNav";
import Logo from "../assests/Logo.jpg";
import { MenuIcon } from "lucide-react";
import MobileNavBar from "./MobileNavBar";
import UserProfileModal from "./UserProfileModal";
import StepGuideModal from "./StockRecommendation/StepGuideModal";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import server from "../utils/serverConfig";
import axios from "axios";
import { Link } from "react-router-dom";

export default function DashboardLayout({ children }) {
  const [user] = useAuthState(auth);

  const userEmail = user && user.email;

  const [userDetails, setUserDetails] = useState();

  const getUserDeatils = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`)
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserDeatils();
  }, [userEmail]);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [showUserProfileModal, setShowUserProfileModal] = useState(false);
  const [showStepGuideModal, setShowStepGuideModal] = useState(false);
  const circumference = 2 * Math.PI * 20; // Adjust radius as needed

  const [stockRecoNotExecuted, setStockRecoNotExecuted] = useState([]);
  const [ignoredTrades, setIgnoredTrades] = useState([]);

  var initials;

  var fullName = userDetails && userDetails.name;
  initials =
    fullName &&
    fullName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();

  const getAllTrades = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/user/trade-reco-for-user?user_email=${userEmail}`,
    };
    axios
      .request(config)
      .then((response) => {
        const filteredTrades = response?.data?.trades.filter((trade) => {
          return trade.trade_place_status === "recommend";
        });
        const allIgnoredTrades = response?.data?.trades.filter((trade) => {
          return trade.trade_place_status === "ignored";
        });
        setStockRecoNotExecuted(filteredTrades);
        setIgnoredTrades(allIgnoredTrades);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllTrades();
  }, []);

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const openStepGuideModal = () => {
    setShowStepGuideModal(true);
  };

  const closeStepGuideModal = () => {
    setShowStepGuideModal(false);
  };

  const openUserProfileModal = () => {
    setShowUserProfileModal(true);
  };

  const closeUserProfileModal = () => {
    setShowUserProfileModal(false);
  };

  const profileCompletion =
    userDetails && typeof userDetails.profile_completion === "number"
      ? userDetails.profile_completion
      : 0;

  const strokeDashoffset =
    circumference - (profileCompletion / 100) * circumference;

  const fullUrl = window.location.origin;

  return (
    <div className="relative flex flex-col lg:flex-row  min-h-screen w-full ">
      {/* Dashboard Side Bar  */}

      <div className="z-10">
        <DashboardNav
          openUserProfileModal={openUserProfileModal}
          openStepGuideModal={openStepGuideModal}
          userDetails={userDetails}
          adviceTradeData={stockRecoNotExecuted}
          ignoreTradesData={ignoredTrades}
          isExpanded={isExpanded}
          toggleExpand={toggleExpand}
        />
      </div>

      <div className="sticky top-0 z-50 lg:hidden flex justify-between  h-[60px] md:h-[90px]  bg-black border-b-[1px] border-[#454646]/60 px-4 ">
        <div className="flex items-center space-x-2">
          <div
            onClick={toggleMobileNav}
            className=" flex items-center  lg:hidden text-white  cursor-pointer"
          >
            <MenuIcon className="h-10 w-10 md:h-12 md:w-12 text-white " />
          </div>
          <div className="flex items-center space-x-2">
            <div className="flex flex-row justify-center ">
              <img
                src={Logo}
                alt="Logo"
                className="rounded-md w-[35px] h-[35px]"
              />
            </div>
            <div className="text-[18px] font-medium font-poppins text-[#f2f2f2] leading-[20px] ">
              AlphaQuark
            </div>
          </div>
        </div>

        {/* Profile Icon  */}
        <div className="flex  items-center justify-end">
          <div
            onClick={openUserProfileModal}
            className="relative flex items-center   justify-center rounded-full  lg:hidden"
          >
            <svg className="transform -rotate-90 w-[50px] h-[50px] ">
              <circle
                cx="25"
                cy="25"
                r="20"
                stroke="currentColor"
                strokeWidth="2"
                fill="transparent"
                className="text-gray-200"
              />
              <circle
                cx="25"
                cy="25"
                r="20"
                stroke="currentColor"
                strokeWidth="4"
                fill="transparent"
                className="text-[#16A085]"
                strokeDasharray={circumference}
                strokeDashoffset={strokeDashoffset}
              />
            </svg>
            <div className="absolute flex items-center justify-center rounded-full ">
              {userDetails &&
              userDetails?.image_url &&
              userDetails?.image_url !== "" &&
              userDetails?.image_url !== null ? (
                <div className=" flex flex-row justify-center w-full">
                  <img
                    src={userDetails?.image_url}
                    alt="Logo"
                    className="rounded-full w-[30px] h-[30px]"
                  />
                </div>
              ) : (
                <div className="flex font-medium font-poppins text-[20px] items-center  justify-center rounded-full  text-white">
                  {initials}
                </div>
              )}
            </div>
          </div>
        </div>
        {isMobileNavOpen && (
          <MobileNavBar
            isOpen={isMobileNavOpen}
            onClose={toggleMobileNav}
            openUserProfileModal={openUserProfileModal}
            userDetails={userDetails}
            adviceTradeData={stockRecoNotExecuted}
            ignoreTradesData={ignoredTrades}
          />
        )}
      </div>

      {/* Main Dashboard  */}
      <main className=" relative flex flex-1  bg-white overflow-hidden  transition duration-500 ease-in">
        {React.cloneElement(children, { getAllTrades })}

        <div className="hidden lg:flex items-center justify-between  absolute bg-[#f9f9f9]  w-full  h-[40px] bottom-0 px-8 py-2">
          <div className="text-[12px] font-normal font-poppins text-[#818282]">
            Alphaquark
          </div>

          <div className="flex items-center justify-evenly divide-x-[1px] divide-[#000000]/10">
            <a
              href={`${fullUrl}/disclaimer`}
              className="flex items-center justify-center px-2 text-[12px] text-[#818282] underline font-normal font-poppins"
              target="_blank"
              rel="noreferrer noopener"
            >
              Disclaimer
            </a>
            <a
              href={`${fullUrl}/terms-and-conditions-by-alphaquark`}
              className=" flex items-center justify-center px-2 text-[12px] text-[#818282] underline font-normal font-poppins"
              target="_blank"
              rel="noreferrer noopener"
            >
              T&C by Alphaquark
            </a>
            <a
              href={`${fullUrl}/terms-and-conditions-by-advisor`}
              className=" flex items-center justify-center px-2 text-[12px] text-[#818282] underline  font-normal font-poppins"
              target="_blank"
              rel="noreferrer noopener"
            >
              T&C by Advisor
            </a>
            <a
              href={`${fullUrl}/privacy-policy`}
              className=" flex items-center justify-center px-2 text-[12px] text-[#818282] underline  font-normal font-poppins"
              target="_blank"
              rel="noreferrer noopener"
            >
              Privacy Policy
            </a>
            <a
              href={`${fullUrl}/contact-us`}
              className=" flex items-center justify-center px-2 text-[12px] text-[#818282] underline  font-normal font-poppins"
              target="_blank"
              rel="noreferrer noopener"
            >
              Contact Us
            </a>
          </div>
        </div>
      </main>

      {showUserProfileModal && (
        <UserProfileModal
          showUserProfileModal={showUserProfileModal}
          setShowUserProfileModal={setShowUserProfileModal}
          getUserDeatils={getUserDeatils}
          userDetails={userDetails && userDetails}
        />
      )}

      {showStepGuideModal && (
        <StepGuideModal
          showStepGuideModal={showStepGuideModal}
          setShowStepGuideModal={setShowStepGuideModal}
        />
      )}
    </div>
  );
}
