import React, { useEffect, useState } from "react";
import fetchAdminData from "./AdminData";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const TermsAndConditionsByAdvisor = () => {
  const [data, setData] = useState();
  const [user] = useAuthState(auth);

  useEffect(() => {
    const getData = async () => {
      const newData = await fetchAdminData();
      // console.log("New",newData?.data)
      setData(newData?.data);
    };

    getData();
  }, []);

  const termsData = data?.termsAndConditions
    ?.split(/\n{1,}/)
    ?.map((item) => item.trim())
    ?.filter((item) => item.length > 0)
    ?.map((item) => ({ text: item }));

  return (
    <div className="min-h-screen w-full items-center justify-center bg-[#F9F9F9] ">
      <div className="border-[1px] border-[#000000]/10 px-[20px]  sm:px-[30px] py-[14px] lg:px-20 lg:py-3 flex items-center justify-between">
        <h2 className=" font-sans text-[18px] sm:text-[24px] md:text-[28px] font-bold md:leading-[60px] text-left ">
          Terms & Conditions by Advisor
        </h2>
        {user === null && (
          <Link
            to="/"
            className=" bg-[#000000] text-white text-[16px] md:text-[18px] font-medium font-poppins rounded-md px-6 py-[6px] md:px-8 md:py-2 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
          >
            Login
          </Link>
        )}
      </div>

      <ul className="mt-1 space-y-4 list-decimal list-inside px-[20px]  sm:px-[30px] pb-[10px] lg:px-20  lg:pb-3">
        {data &&
          termsData?.map((item, index) => (
            <div key={index} className="font-semibold text-[#161515CC]">
              <p className="font-poppins text-[16px] font-normal leading-[24px] text-left mt-2">
                {item.text}
              </p>
            </div>
          ))}
      </ul>
    </div>
  );
};

export default TermsAndConditionsByAdvisor;
