import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import axios from "axios";
import CryptoJS from "crypto-js";
import {
  TrendingUp,
  EyeIcon,
  EyeOffIcon,
  Info,
  X,
  CandlestickChartIcon,
} from "lucide-react";

import { auth } from "../../firebase";
import server from "../../utils/serverConfig";
import PlaceOrders from "./PlaceOrders";
import NewStockCard from "../StockRecommendation/NewStockCard";
import LoadingSpinner from "../../components/LoadingSpinner";
import ConnectBroker from "../UserHomePage/connectBroker";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";

import ReviewTradeModel from "../StockRecommendation/ReviewTradeModel";
import ZerodhaReviewModal from "../StockRecommendation/ZerodhaReviewModal";
import UpdateUserDeatils from "../UserHomePage/UpdateUserDetails";
import RecommendationSuccessModal from "../StockRecommendation/RecommendationSuccessModal";
import { TokenExpireModal } from "../StockRecommendation/TokenExpireModal";
import { IgnoreTradeModal } from "../StockRecommendation/IgnoreTradeModal";
import IsMarketHours from "../../utils/isMarketHours";

const style = {
  selected:
    "flex items-center text-[12px] leading-[14px] lg:text-[16px]  font-poppins text-black font-bold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected:
    "flex items-center text-[12px] leading-[14px] font-medium font-poppins lg:text-[16px]  text-[#000000]/40 lg:leading-[42px] cursor-pointer",
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-[18px]  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full  ",
};

const defaultRationale = `This recommendation is based on a comprehensive analysis of the
        company's growth potential and value metrics. This recommendation
        also accounts for potential future risks, ensuring a balanced
        approach to maximizing returns while mitigating uncertainties.
        Please contact your advisor for any queries.`;

const OrderBookDetails = ({ getAllTradesUpdate }) => {
  // user details fetch
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [userDetails, setUserDetails] = useState();

  const getUserDeatils = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`)
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserDeatils();
  }, [userEmail, server.server.baseUrl]);

  const [brokerModel, setBrokerModel] = useState(null);
  const [brokerStatus, setBrokerStatus] = useState(
    userDetails ? userDetails.connect_broker_status : null
  );

  const [updateUserDetails, setUpdateUserDetails] = useState(false);
  useEffect(() => {
    if (userDetails && userDetails.user_broker !== undefined) {
      setBrokerStatus(userDetails && userDetails.connect_broker_status);
    }
  }, [userDetails, brokerStatus]);

  // all recommendation
  const [allTrades, setAllTrades] = useState([]);
  const [rejectedTrades, setRejectedTrades] = useState([]);
  const getAllTrades = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/user/trade-reco-for-user?user_email=${userEmail}`,
    };
    axios
      .request(config)
      .then((response) => {
        setAllTrades(response.data.trades);
        const allRejectedTrades = response?.data?.trades.filter((trade) => {
          return trade.trade_place_status === "rejected";
        });
        setRejectedTrades(allRejectedTrades);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllTrades();
  }, []);

  // executed tardes trades
  const filterExecutedTrades = () => {
    const filteredTrades = allTrades.filter((trade) => {
      return (
        trade.trade_place_status !== "recommend" &&
        trade.trade_place_status !== "ignored" &&
        trade.trade_place_status !== "rejected"
      );
    });
    return filteredTrades;
  };
  const executedTrades = filterExecutedTrades();

  const [selectedRecommendation, setSelectedRecommendation] =
    useState("orders-placed");
  const [stockDetails, setStockDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const clientCode = userDetails && userDetails.clientCode;
  const apiKey = userDetails && userDetails.apiKey;
  const jwtToken = userDetails && userDetails.jwtToken;
  const my2pin = userDetails && userDetails.my2Pin;
  const secretKey = userDetails && userDetails.secretKey;
  const viewToken = userDetails && userDetails?.viewToken;
  const sid = userDetails && userDetails?.sid;
  const serverId = userDetails && userDetails?.serverId;
  const mobileNumber = userDetails && userDetails?.phone_number;
  const panNumber = userDetails && userDetails?.panNumber;

  const [broker, setBroker] = useState("");
  const [openReviewTrade, setOpenReviewTrade] = useState(false);
  const [openSuccessModal, setOpenSucessModal] = useState(false);
  const [orderPlacementResponse, setOrderPlacementResponse] = useState();
  const [openTokenExpireModel, setOpenTokenExpireModel] = useState(null);

  useEffect(() => {
    if (userDetails) {
      setBroker(userDetails.user_broker);
    }
  }, [userDetails]);

  const dateString = userDetails && userDetails.token_expire;
  // Format the moment object as desired
  const expireTokenDate = moment(dateString).format("YYYY-MM-DD HH:mm:ss");

  const today = new Date();
  const todayDate = moment(today).format("YYYY-MM-DD HH:mm:ss");
  const userId = userDetails && userDetails._id;

  const checkValidApiAnSecret = (data) => {
    const bytesKey = CryptoJS.AES.decrypt(data, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8);
    if (Key) {
      return Key;
    }
  };
  const updatePortfolioData = () => {
    let data = JSON.stringify({
      user_email: userEmail,
    });

    let config = {
      method: "post",

      url: `${server.ccxtServer.baseUrl}altqube-portfolio`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const placeOrder = () => {
    setLoading(true);
    if (broker === "IIFL Securities") {
      setOpenReviewTrade(true);
      let data = JSON.stringify({
        clientCode: clientCode,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/iifl/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("res--------", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();
          updatePortfolioData();
          if (getAllTradesUpdate) {
            getAllTradesUpdate(); // Ensure this is being called correctly
          }
          getCartAllStocks();
        })
        .catch((error) => {
          console.error(`Error placing order for stock `, error);
          setLoading(false);
          toast.error("Something went wrong. Please try again later", {
            duration: 6000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "500px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          });
          // Handle error for individual stock
        });
    } else if (broker === "ICICI Direct") {
      setOpenReviewTrade(true);
      let data = JSON.stringify({
        apiKey: checkValidApiAnSecret(apiKey),
        secretKey: checkValidApiAnSecret(secretKey),
        jwtToken: jwtToken,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/icici/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("res=======", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();
          updatePortfolioData();
          if (getAllTradesUpdate) {
            getAllTradesUpdate(); // Ensure this is being called correctly
          }
          getCartAllStocks();
        })
        .catch((error) => {
          console.log("err", error);
          toast.error(
            "There was an issue in placing the trade, please try again after sometime or contact your advisor",
            {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
          setLoading(false);
        });
    } else if (broker === "Upstox") {
      setOpenReviewTrade(true);
      let data = JSON.stringify({
        apiKey: apiKey,
        jwtToken: jwtToken,
        secretKey: secretKey,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/upstox/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("res=======", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          if (getAllTradesUpdate) {
            getAllTradesUpdate(); // Ensure this is being called correctly
          }
          getAllTrades();
          updatePortfolioData();
          getCartAllStocks();
        })
        .catch((error) => {
          console.log("err", error);
          toast.error(
            "There was an issue in placing the trade, please try again after sometime or contact your advisor",
            {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
          setLoading(false);
        });
    } else if (broker === "Kotak") {
      let data = JSON.stringify({
        apiKey: apiKey,
        secretKey: secretKey,
        jwtToken: jwtToken,
        viewToken: viewToken,
        sid: sid,
        serverId: serverId,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/kotak/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("kotak order place res=======", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();
          if (getAllTradesUpdate) {
            getAllTradesUpdate(); // Ensure this is being called correctly
          }
          getCartAllStocks();
          updatePortfolioData();
        })
        .catch((error) => {
          console.log("err", error);
          toast.error(
            "There was an issue in placing the trade, please try again after sometime or contact your advisor",
            {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
          setLoading(false);
        });
    } else {
      setOpenReviewTrade(true);
      let data = JSON.stringify({
        apiKey: apiKey,
        jwtToken: jwtToken,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/trading/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("angel one order place res=======", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();
          if (getAllTradesUpdate) {
            getAllTradesUpdate(); // Ensure this is being called correctly
          }
          updatePortfolioData();
          getCartAllStocks();
        })
        .catch((error) => {
          console.log("err", error);
          toast.error(
            "There was an issue in placing the trade, please try again after sometime or contact your advisor",
            {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
          setLoading(false);
        });
    }
  };

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  // user login

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const handleIiflLogin = () => {
    setLoginLoading(true);
    let data = JSON.stringify({
      clientCode: clientCode,
      password: password,
      my2pin: my2pin,
      userId: userId,
    });

    let config = {
      method: "post",
      url: `${server.server.baseUrl}api/iifl/generate-session`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setLoginLoading(false);
        getUserDeatils();
        setOpenTokenExpireModel(false);
        toast.success(
          "You have been successfully logged in to IIFL Securities",
          {
            duration: 5000,
            style: {
              background: "white",
              color: "#16a085",
              maxWidth: "550px",
              fontWeight: "bolder",
              fontSize: "16px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      })
      .catch((error) => {
        const result = error.response.data.response;
        setLoginLoading(false);
        toast.error(`${result.message}`, {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  useEffect(() => {
    if (showSuccessMsg) {
      setShowSuccessMsg(true);
      const toRef = setTimeout(() => {
        setShowSuccessMsg(false);
        clearTimeout(toRef);
      }, 4000);
    }
  }, [showSuccessMsg]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // ignore trade
  const [openIgnoreTradeModel, setOpenIgnoreTradeModel] = useState(false);
  const [stockIgnoreId, setStockIgnoreId] = useState();
  const [ignoreLoading, setIgnoreLoading] = useState(false);
  const [ignoreText, setIgnoreText] = useState("");

  const handleIgnoredTrades = (id) => {
    setIgnoreLoading(true);
    let data = JSON.stringify({
      uid: id,
      trade_place_status: "ignored",
      reason: ignoreText,
    });

    // Second API request to place the order
    let orderConfig = {
      method: "put",
      url: `${server.server.baseUrl}api/recommendation`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(orderConfig)
      .then((response) => {
        toast.success("You have successfully ignored your trade", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        setIgnoreLoading(false);
        setOpenIgnoreTradeModel(false);
        getAllTrades();
      })
      .catch((error) => {
        console.error(`Error placing order for stock `, error);
        setLoading(false);
      });
  };

  const { getLTPForSymbol } = useWebSocketCurrentPrice(rejectedTrades);

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    stockDetails.forEach((ele) => {
      if (ele.transactionType === "BUY") {
        const ltp = getLTPForSymbol(ele.tradingSymbol); // Get LTP for current symbol
        if (ltp !== "-") {
          totalAmount += parseFloat(ltp) * ele.quantity; // Calculate total amount for this trade
        }
      }
    });
    return totalAmount.toFixed(2); // Return total amount formatted to 2 decimal places
  };

  const [openZerodhaModel, setOpenZerodhaModel] = useState(false);
  const [selectedLength, setSelectedLength] = useState();
  const [singleStockSelectState, setSingleStockSelectState] = useState(false);
  const getCartAllStocks = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/cart/${userEmail}?trade_place_status=rejected`,
    };

    axios
      .request(config)
      .then((response) => {
        const transformedStockDetails = response?.data?.map((stock) => ({
          user_email: stock.user_email,
          trade_given_by: stock.trade_given_by,
          tradingSymbol: stock.Symbol,
          transactionType: stock.Type,
          exchange: stock.Exchange,
          segment: stock.Segment,
          productType: stock.ProductType,
          orderType: stock.OrderType,
          price: stock.Price,
          quantity: stock.Quantity,
          priority: stock.Priority,
          tradeId: stock.tradeId,
          user_broker: broker, // Assuming you want to set this from the existing context
        }));

        setStockDetails(transformedStockDetails);
        setSelectedLength(transformedStockDetails);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (userEmail) {
      getCartAllStocks();
    }
  }, [userEmail]);

  const handleSelectAllRejectedStocks = async () => {
    const newStockDetails = rejectedTrades.reduce((acc, stock) => {
      const isSelected = stockDetails.some(
        (selectedStock) =>
          selectedStock.tradingSymbol === stock.Symbol &&
          selectedStock.tradeId === stock.tradeId
      );

      if (!isSelected) {
        const ltp = getLTPForSymbol(stock.Symbol);
        const advisedRangeLower = stock.Advised_Range_Lower;
        const advisedRangeHigher = stock.Advised_Range_Higher;

        const shouldDisableTrade =
          (advisedRangeHigher === 0 && advisedRangeLower === 0) ||
          (advisedRangeHigher === null && advisedRangeLower === null) ||
          (advisedRangeHigher > 0 &&
            advisedRangeLower > 0 &&
            parseFloat(advisedRangeHigher) > parseFloat(ltp) &&
            parseFloat(ltp) > parseFloat(advisedRangeLower)) ||
          (advisedRangeHigher > 0 &&
            advisedRangeLower === 0 &&
            advisedRangeLower === null &&
            parseFloat(advisedRangeHigher) > parseFloat(ltp)) ||
          (advisedRangeLower > 0 &&
            advisedRangeHigher === 0 &&
            advisedRangeHigher === null &&
            parseFloat(advisedRangeLower) < parseFloat(ltp));

        if (shouldDisableTrade) {
          const newStock = {
            user_email: stock.user_email,
            trade_given_by: stock.trade_given_by,
            tradingSymbol: stock.Symbol,
            transactionType: stock.Type,
            exchange: stock.Exchange,
            segment: stock.Segment,
            productType: stock.ProductType,
            orderType: stock.OrderType,
            price: stock.Price,
            quantity: stock.Quantity,
            priority: stock.Priority,
            tradeId: stock.tradeId,
            user_broker: broker,
          };
          acc.push(newStock);
        }
      }

      return acc;
    }, []);

    if (newStockDetails.length > 0) {
      try {
        await axios.post(
          `${server.server.baseUrl}api/cart/add/add-multiple-to-cart`,
          {
            stocks: newStockDetails,
          }
        );
        // Optionally, update the state to reflect the changes in the UI
        getCartAllStocks();
      } catch (error) {
        console.error("Error adding stocks to cart", error);
      }
    }
  };

  const handleRemoveAllSelectedStocks = async () => {
    try {
      // Use all stock details in the cart for removal
      const stocksToRemove = [...stockDetails];

      if (stocksToRemove.length > 0) {
        await axios.post(
          `${server.server.baseUrl}api/cart/cart-items/remove/multiple/remove-multiple-from-cart`,
          {
            stocks: stocksToRemove,
          }
        );

        // Clear stockDetails since all stocks are removed
        setStockDetails([]);
        getCartAllStocks(); // Refresh the cart
      }
    } catch (error) {
      console.error("Error removing stocks from cart", error);
    }
  };

  // zerodha start
  const handleZerodhaRedirect = async () => {
    localStorage.setItem(
      "stockDetailsZerodhaOrder",
      JSON.stringify(stockDetails)
    );
    const apiKey = "b0g1r806oitsamoe";

    const basket = stockDetails.map((stock) => {
      let baseOrder = {
        variety: "regular",
        tradingsymbol: stock.tradingSymbol,
        exchange: stock.exchange,
        transaction_type: stock.transactionType,
        order_type: stock.orderType,
        quantity: stock.quantity,
        readonly: false,
      };

      // Get the LTP for the current stock
      const ltp = getLTPForSymbol(stock.tradingSymbol);

      // If LTP is available and not '-', use it as the price
      if (ltp !== "-") {
        baseOrder.price = parseFloat(ltp);
      }

      // If it's a LIMIT order, use the LTP as the price
      if (stock.orderType === "LIMIT") {
        if (ltp !== "-") {
          baseOrder.price = parseFloat(ltp);
          baseOrder.variety = "regular";
        } else {
          baseOrder.variety = "regular";
          baseOrder.price = stock.limitPrice || 0; // Use limitPrice if available, or set to 0
        }
      }

      if (stock.quantity > 100) {
        baseOrder.readonly = true;
      }

      return baseOrder;
    });

    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://kite.zerodha.com/connect/basket";
    // form.target = "_blank";

    const apiKeyInput = document.createElement("input");
    apiKeyInput.type = "hidden";
    apiKeyInput.name = "api_key";
    apiKeyInput.value = apiKey;

    const dataInput = document.createElement("input");
    dataInput.type = "hidden";
    dataInput.name = "data";
    dataInput.value = JSON.stringify(basket);

    form.appendChild(apiKeyInput);
    form.appendChild(dataInput);
    document.body.appendChild(form);

    const currentISTDateTime = new Date();
    try {
      // Update the database with the current IST date-time
      await axios.put(`${server.server.baseUrl}api/zerodha/update-trade-reco`, {
        stockDetails: stockDetails,
        leaving_datetime: currentISTDateTime,
      });

      // Submit the form after the database is updated
      form.submit();
    } catch (error) {
      console.error("Failed to update trade recommendation:", error);
    }
  };

  const todaysDate = new Date().toLocaleDateString();

  const filterTodaysRejectedTrades = rejectedTrades
    .map((trade) => ({
      ...trade,
      date: new Date(trade.date).toLocaleDateString(),
    }))
    .filter((trade) => trade.date === todaysDate);

  const filterTodaysExecutedTrades = executedTrades
    .map((trade) => ({
      ...trade,
      date: new Date(trade.purchaseDate || trade.exitDate).toLocaleDateString(),
    }))
    .filter((trade) => trade.date === todaysDate);

  const [funds, setFunds] = useState({});

  const getAllFunds = () => {
    if (broker === "IIFL Securities") {
      if (clientCode) {
        let data = JSON.stringify({
          clientCode: clientCode,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}iifl/margin`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "ICICI Direct") {
      if (apiKey && jwtToken && secretKey) {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          sessionToken: jwtToken,
          secretKey: checkValidApiAnSecret(secretKey),
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}icici/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Upstox") {
      if (apiKey && jwtToken && secretKey) {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          accessToken: jwtToken,
          apiSecret: checkValidApiAnSecret(secretKey),
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}upstox/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Angel One") {
      if (apiKey && jwtToken) {
        let data = JSON.stringify({
          apiKey: apiKey,
          jwtToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Zerodha") {
      if (jwtToken) {
        let data = JSON.stringify({
          apiKey: "b0g1r806oitsamoe",
          accessToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}zerodha/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Kotak") {
      if (jwtToken) {
        let data = JSON.stringify({
          consumerKey: checkValidApiAnSecret(apiKey),
          consumerSecret: checkValidApiAnSecret(secretKey),
          accessToken: jwtToken,
          viewToken: viewToken,
          exchange: "NSE",
          segment: "CASH",
          product: "ALL",
          sid: sid,
          serverId: serverId,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}kotak/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            // console.log("res", response);
            setFunds(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    getAllFunds();
  }, [userDetails]);

  const handleTrade = () => {
    getAllFunds();
    const isFundsEmpty =
      funds?.meesage === "Invalid Token" && funds?.success === false;
    const isMarketHours = IsMarketHours();

    if (!isMarketHours) {
      toast.error("Orders cannot be placed after Market hours.", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      return;
    }
    if (broker === "Zerodha") {
      if (todayDate > expireTokenDate || isFundsEmpty) {
        setOpenTokenExpireModel(true);
      } else {
        setOpenZerodhaModel(true);
      }
    } else {
      if (brokerStatus === null) {
        setBrokerModel(true);
      } else if (todayDate > expireTokenDate || isFundsEmpty) {
        setOpenTokenExpireModel(true);
      } else {
        setOpenReviewTrade(true);
      }
    }
  };

  const handleOpenReviewModal = () => {
    setOpenReviewTrade(false);
    setSingleStockSelectState(false);
    getCartAllStocks();
  };

  const handleOpenZerodhaModal = () => {
    setOpenZerodhaModel(false);
    setSingleStockSelectState(false);
    getCartAllStocks();
  };

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col relative  w-full min-h-screen  bg-[#f9f9f9]">
          <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
            <svg
              className="h-10 w-10 text-[#000000] animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        <div className="flex flex-col relative  w-full max-h-[calc(100vh-60px)] md:min-h-screen  bg-[#f9f9f9]">
          <div className="flex flex-col  w-full min-h-screen  bg-[#f9f9f9] ">
            <Toaster position="top-center" reverseOrder={true} />
            <div className="px-4 lg:px-[50px] flex flex-row  justify-between border-b border-[#000000]/20  h-[50px] lg:h-[60px] font-poppins">
              <div className="flex flex-row space-x-6 lg:space-x-8 ">
                <div
                  className={
                    selectedRecommendation === "orders-placed"
                      ? ` ${style.selected}`
                      : ` ${style.unselected}`
                  }
                  onClick={() => {
                    setSelectedRecommendation("orders-placed");
                    getAllTrades();
                  }}
                >
                  Orders Placed
                  {filterTodaysExecutedTrades &&
                    filterTodaysExecutedTrades.length > 0 && (
                      <span
                        className={`ml-2 pt-[1px] px-[4px] flex items-center text-[11px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[28px] h-5 rounded-full ${
                          selectedRecommendation !== "orders-placed"
                            ? "bg-[#000000]/30"
                            : filterTodaysExecutedTrades.length === 0
                            ? "bg-[#000000]/30"
                            : "bg-[#E43D3D]"
                        }`}
                      >
                        {filterTodaysExecutedTrades
                          ? filterTodaysExecutedTrades.length
                          : ""}
                      </span>
                    )}
                </div>
                <div
                  className={
                    selectedRecommendation === "rejected-orders"
                      ? ` ${style.selected}`
                      : ` ${style.unselected}`
                  }
                  onClick={() => {
                    setSelectedRecommendation("rejected-orders");
                    getAllTrades();
                    getAllFunds();
                  }}
                >
                  Rejected Orders
                  {filterTodaysRejectedTrades &&
                    filterTodaysRejectedTrades.length > 0 && (
                      <span
                        className={` ml-2 pt-[1px] px-[4px] flex items-center text-[11px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[28px] h-5 rounded-full ${
                          selectedRecommendation !== "rejected-orders"
                            ? "bg-[#000000]/30"
                            : filterTodaysRejectedTrades.length === 0
                            ? "bg-[#000000]/30"
                            : "bg-[#E43D3D]"
                        }`}
                      >
                        {filterTodaysRejectedTrades
                          ? filterTodaysRejectedTrades.length
                          : ""}
                      </span>
                    )}
                </div>
              </div>

              {selectedRecommendation === "rejected-orders" ? (
                <div className="hidden md:flex flex-row items-center ">
                  {rejectedTrades.length !== 0 && (
                    <>
                      {rejectedTrades.length === stockDetails.length ? (
                        <div
                          onClick={handleRemoveAllSelectedStocks}
                          className=" text-xs md:text-lg py-2 hidden md:block md:px-6 text-[#000000] font-medium font-poppins border-[1px] border-[#000000]/20 rounded-md cursor-pointer"
                        >
                          <span className="">Deselect All</span>
                        </div>
                      ) : (
                        <div
                          onClick={handleSelectAllRejectedStocks}
                          className="text-xs md:text-lg py-2 hidden md:block md:px-6 text-[#000000] font-medium font-poppins border-[1px] border-[#000000]/20 rounded-md cursor-pointer"
                        >
                          <span className="">Select All</span>
                        </div>
                      )}
                    </>
                  )}

                  <button
                    className="w-[80px] lg:w-[150px]   disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-2 py-1.5 bg-black text-[14px] lg:ml-6 lg:text-lg lg:px-4 lg:py-2 text-white font-medium rounded-md cursor-pointer"
                    onClick={handleTrade}
                    disabled={stockDetails?.length === 0}
                  >
                    <div className="flex flex-row justify-center items-center space-x-2">
                      {" "}
                      <TrendingUp className=" w-4 h-4 lg:h-5 lg:w-5" />
                      <span>Trade</span>
                      {singleStockSelectState === true ? (
                        <span className="font-medium mt-0.5 md:mt-1">
                          (
                          {(stockDetails?.length || 0) +
                            (selectedLength?.length || 0)}
                          )
                        </span>
                      ) : (
                        stockDetails?.length > 0 && (
                          <span className="font-medium mt-0.5 md:mt-1">
                            ({stockDetails?.length})
                          </span>
                        )
                      )}
                    </div>
                  </button>
                </div>
              ) : null}
            </div>

            {selectedRecommendation === "orders-placed" && (
              <PlaceOrders executedTrades={executedTrades} />
            )}

            {selectedRecommendation === "rejected-orders" && (
              <div className="h-[calc(100vh-160px)] overflow-hidden  md:overflow-auto md:max-h-[calc(100vh-100px)] pb-[20px]  md:pb-0 w-full">
                {rejectedTrades?.sort(
                  (a, b) => new Date(b.date) - new Date(a.date)
                ).length !== 0 ? (
                  <div className=" overflow-scroll custom-scrollbar md:overflow-auto w-full grid gap-y-6 py-4 px-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-4 lg:pt-6 lg:pb-12 lg:px-[50px]">
                    {rejectedTrades.map((ele, i) => {
                      return (
                        <div key={i}>
                          <NewStockCard
                            id={ele._id}
                            isSelected={stockDetails.some(
                              (stock) =>
                                stock.tradingSymbol === ele.Symbol &&
                                stock.tradeId === ele.tradeId
                            )}
                            symbol={ele.Symbol}
                            Price={ele.Price}
                            date={ele.date}
                            Quantity={ele.Quantity}
                            action={ele.Type}
                            orderType={ele.OrderType}
                            exchange={ele.Exchange}
                            segment={ele.Segment}
                            cmp={ele.CMP}
                            tradeId={ele.tradeId}
                            rationale={
                              ele?.rationale ? ele.rationale : defaultRationale
                            }
                            recommendationStock={
                              rejectedTrades && rejectedTrades
                            }
                            setRecommendationStock={setRejectedTrades}
                            setStockDetails={setStockDetails}
                            stockDetails={stockDetails}
                            setOpenReviewTrade={setOpenReviewTrade}
                            setOpenIgnoreTradeModel={setOpenIgnoreTradeModel}
                            setStockIgnoreId={setStockIgnoreId}
                            getLTPForSymbol={getLTPForSymbol}
                            setOpenTokenExpireModel={setOpenTokenExpireModel}
                            todayDate={todayDate}
                            expireTokenDate={expireTokenDate}
                            brokerStatus={brokerStatus}
                            setBrokerModel={setBrokerModel}
                            funds={funds?.data?.availablecash}
                            advisedRangeLower={ele.Advised_Range_Lower}
                            advisedRangeHigher={ele.Advised_Range_Higher}
                            setSingleStockSelectState={
                              setSingleStockSelectState
                            }
                            getCartAllStocks={getCartAllStocks}
                            getAllFunds={getAllFunds}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex flex-col  space-y-4 lg:space-y-6 lg:pt-[20px] items-center justify-center h-[calc(100vh-180px)]  lg:h-full">
                    <div className="flex items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
                      <CandlestickChartIcon className=" w-[50px] h-[50px] lg:w-[60px]   lg:h-[60px]" />
                    </div>
                    <div className="flex flex-col space-y-3 lg:space-y-3 items-center">
                      <div className="text-balck text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                        No Rejected Trades
                      </div>
                      <div className="text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[30px] font-normal font-poppins px-[10px] lg:px-[60px]">
                        Trades that could not be placed will appear here
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {selectedRecommendation === "rejected-orders" &&
              rejectedTrades.length > 0 && (
                <div className="absolute  bottom-0 bg-[#f9f9f9] shadow-[rgba(0,_0,_0,_0.4)_0px_30px_90px] border-[1px] border-[#000000]/10 flex flex-row items-center justify-between  h-[65px] px-4 space-x-4 w-full md:hidden">
                  {rejectedTrades.length === stockDetails.length ? (
                    <div
                      onClick={handleRemoveAllSelectedStocks}
                      className=" w-full flex items-center justify-center  text-[15px] py-3 px-2   text-[#000000] font-medium font-poppins border-[1px] border-[#000000]/20 rounded-md cursor-pointer"
                    >
                      <span className="">Deselect All</span>
                    </div>
                  ) : (
                    <div
                      onClick={handleSelectAllRejectedStocks}
                      className=" w-full flex items-center justify-center text-[15px] py-3 px-2   text-[#000000] font-medium font-poppins border-[1px] border-[#000000]/20 rounded-md cursor-pointer"
                    >
                      <span className="">Select All</span>
                    </div>
                  )}
                  <button
                    className="w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-2 py-3 bg-black text-[15px]  text-white font-medium rounded-md cursor-pointer"
                    onClick={handleTrade}
                    disabled={stockDetails?.length === 0}
                  >
                    <div className="flex flex-row justify-center items-center space-x-2">
                      {" "}
                      <TrendingUp className=" w-4 h-4 lg:h-5 lg:w-5" />
                      <span>Trade</span>
                      {singleStockSelectState === true ? (
                        <span className="font-medium mt-0.5 md:mt-1">
                          (
                          {(stockDetails?.length || 0) +
                            (selectedLength?.length || 0)}
                          )
                        </span>
                      ) : (
                        stockDetails?.length > 0 && (
                          <span className="font-medium mt-0.5 md:mt-1">
                            ({stockDetails?.length})
                          </span>
                        )
                      )}
                    </div>
                  </button>
                </div>
              )}

            {brokerModel === true ? (
              <ConnectBroker
                uid={userDetails && userDetails._id}
                userDetails={userDetails && userDetails}
                setBrokerStatus={setBrokerStatus}
                setUpdateUserDetails={setUpdateUserDetails}
                setBrokerModel={setBrokerModel}
                getUserDeatils={getUserDeatils}
                broker={broker}
                setBroker={setBroker}
              />
            ) : null}

            {updateUserDetails === true ? (
              <UpdateUserDeatils
                setUpdateUserDetails={setUpdateUserDetails}
                uid={userDetails && userDetails._id}
                userDetails={userDetails}
              />
            ) : null}

            {/* IIFL Login  Modal with Mobile Drawer    */}

            {openTokenExpireModel === true ? (
              <TokenExpireModal
                openTokenExpireModel={openTokenExpireModel}
                setOpenTokenExpireModel={setOpenTokenExpireModel}
              >
                <div className="flex flex-col items-center justify-center space-y-2">
                  <div className="flex items-center justify-center rounded-full p-2 ">
                    <Info className="text-[#000000]/50 w-16 h-16 " />
                  </div>
                </div>
                <div className="flex space-x-4 items-center justify-center  text-black text-center text-2xl font-bold mt-4 mb-4">
                  <span className="text-[20px] text-[#000000] font-medium font-poppins text-center">
                    Please login to your broker to continue investments
                  </span>
                </div>
                <div className="md:flex flex-col space-y-2">
                  {broker === "IIFL Securities" ? (
                    <div className="md:flex flex-col space-y-2">
                      <div className={style.inputStartDiv}>
                        <input
                          id="clientCode"
                          placeholder="Client Code"
                          type="text"
                          value={clientCode}
                          disabled
                          className={`${style.inputBox}`}
                        />
                        <label
                          htmlFor="clientCode"
                          className={`${style.firstHeading} ${style.labelFloat}`}
                        >
                          Client Code
                        </label>
                      </div>
                      <div className={style.inputStartDiv}>
                        <input
                          id="my2pin"
                          placeholder="My2Pin"
                          type="text"
                          value={my2pin}
                          disabled
                          className={`${style.inputBox}`}
                        />
                        <label
                          htmlFor="my2pin"
                          className={`${style.firstHeading} ${style.labelFloat}`}
                        >
                          My2Pin
                        </label>
                      </div>
                      <div className={`relative ${style.inputStartDiv} `}>
                        <input
                          id="pass"
                          placeholder="password"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className={`${style.inputBox}`}
                        />
                        <label
                          htmlFor="pass"
                          className={`${style.firstHeading} ${style.labelFloat}`}
                        >
                          Password
                        </label>
                        {showPassword ? (
                          <EyeIcon
                            onClick={() => setShowPassword(false)}
                            className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                          />
                        ) : (
                          <EyeOffIcon
                            onClick={() => setShowPassword(true)}
                            className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                          />
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>

                {broker === "IIFL Securities" ? (
                  <div
                    className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
                    onClick={handleIiflLogin}
                  >
                    {loginLoading === true ? (
                      <LoadingSpinner />
                    ) : (
                      <span className="text-[16px] text-white font-semibold font-poppins">
                        Login to {broker ? broker : ""}
                      </span>
                    )}
                  </div>
                ) : broker === "ICICI Direct" ? (
                  <div className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer">
                    <a
                      href={`https://api.icicidirect.com/apiuser/login?api_key=${encodeURIComponent(
                        checkValidApiAnSecret(apiKey ? apiKey : "")
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="text-[16px] text-white font-semibold font-poppins">
                        Login to {broker ? broker : ""}
                      </span>
                    </a>
                  </div>
                ) : broker === "Upstox" ? (
                  <div className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer">
                    <a
                      href={`https://api.upstox.com/v2/login/authorization/dialog?response_type=code&client_id=${checkValidApiAnSecret(
                        apiKey
                      )}&redirect_uri=https://test.alphaquark-case.com/stock-recommendation&state=ccxt`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="text-[16px] text-white font-semibold font-poppins">
                        Login to {broker ? broker : ""}
                      </span>
                    </a>
                  </div>
                ) : (
                  <div className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer">
                    <a
                      href="https://smartapi.angelbroking.com/publisher-login?api_key=EfkDdJMH"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="text-[16px] text-white font-semibold font-poppins">
                        Login to {broker ? broker : ""}
                      </span>
                    </a>
                  </div>
                )}
              </TokenExpireModal>
            ) : null}

            {/* Ignore Trade Model with Mobile Drawer */}

            {openIgnoreTradeModel === true ? (
              <IgnoreTradeModal
                openIgnoreTradeModel={openIgnoreTradeModel}
                setOpenIgnoreTradeModel={setOpenIgnoreTradeModel}
                handleIgnoredTrades={handleIgnoredTrades}
                stockIgnoreId={stockIgnoreId}
                ignoreText={ignoreText}
                setIgnoreText={setIgnoreText}
                ignoreLoading={ignoreLoading}
                style={style}
              />
            ) : null}

            {stockDetails.length !== 0 && openReviewTrade === true ? (
              <ReviewTradeModel
                calculateTotalAmount={calculateTotalAmount}
                getLTPForSymbol={getLTPForSymbol}
                stockDetails={stockDetails}
                setStockDetails={setStockDetails}
                setOpenReviewTrade={handleOpenReviewModal}
                placeOrder={placeOrder}
                loading={loading}
                funds={funds?.data?.availablecash}
                openReviewTrade={openReviewTrade}
                getCartAllStocks={getCartAllStocks}
              />
            ) : stockDetails.length !== 0 &&
              broker === "Zerodha" &&
              openZerodhaModel === true ? (
              <ZerodhaReviewModal
                getLTPForSymbol={getLTPForSymbol}
                stockDetails={stockDetails}
                setStockDetails={setStockDetails}
                calculateTotalAmount={calculateTotalAmount}
                funds={funds?.data?.availablecash}
                setOpenZerodhaModel={handleOpenZerodhaModal}
                handleZerodhaRedirect={handleZerodhaRedirect}
                openZerodhaModel={openZerodhaModel}
                getCartAllStocks={getCartAllStocks}
              />
            ) : null}

            {openSuccessModal && (
              <RecommendationSuccessModal
                setOpenSucessModal={setOpenSucessModal}
                orderPlacementResponse={orderPlacementResponse}
                setStockDetails={setStockDetails}
                openSuccessModal={openSuccessModal}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OrderBookDetails;
