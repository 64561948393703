import React, { useState } from "react";
import { XIcon } from "lucide-react"; // Make sure you have this icon
import server from "../../utils/serverConfig";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const style = {
  selected:
    "   text-[16px]  text-black font-bold  border-b-[3px] border-black cursor-pointer",
  unselected: "   font-medium  text-[16px]  text-[#000000]/40 cursor-pointer",
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full   px-3 py-3  bg-white text-[16px]  peer text-gray-900 placeholder:text-gray-400 placeholder:font-normal   font-medium rounded-md mt-1.5 ring-1 hover:ring-2 ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full   ",
  selected_2:
    "flex items-center text-[16px] leading-[14px] lg:text-[18px]  font-poppins text-black font-semibold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected_2:
    "flex items-center text-[16px] leading-[14px] font-medium font-poppins lg:text-[18px]  text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const SettingsModal = ({ adminEmail, closeSettingsModal }) => {

  const navigate = useNavigate()
  const [disclaimer, setDisclaimer] = useState("");
  const [termsAndConditions, settermsAndConditions] = useState("");
  const [rationale, setRationale] = useState("");
  const [selectedTab, setSelectedTab] = useState("disclaimer");
  const [isDisclaimerFilled, setIsDisclaimerFilled] = useState(false);
  const [isTermsFilled, setIsTermsFilled] = useState(false);

  // Handlers
  const handleNext = () => {
    if (selectedTab === "disclaimer") {
      setSelectedTab("terms");
    } else if (selectedTab === "terms") {
      setSelectedTab("rationale");
    }
  };

  const handleBack = () => {
    if (selectedTab === "terms") {
      setSelectedTab("disclaimer");
    } else if (selectedTab === "rationale") {
      setSelectedTab("terms");
    }
  };

  const handleSave = async () => {
    const payload = {
      email: adminEmail,
      disclaimer,
      termsAndConditions,
      rationale,
    };

    console.log("Sending data:", payload);

    try {
      // Send a POST request to the server
      const response = await fetch(
        `${server.server.baseUrl}api/update-terms-conditions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the request was successful
      if (response.ok) {
        const result = await response.json();
        console.log("Response from server:", result);
        closeSettingsModal();
        toast.success("Account setup completed successfully !!", {
          duration: 3000,
          style: {
        background: "white",
        color: "#1e293b",
        maxWidth: "500px",
        fontWeight: 600,
        fontSize: "13px",
        padding: "10px 20px",
      },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });

        navigate('/admin/client-list')
      } else {
        console.error("Failed to update:", response.statusText);
        closeSettingsModal();
        toast.error("Failed to setup account !!", {
          duration: 3000,
          style: {
        background: "white",
        color: "#1e293b",
        maxWidth: "500px",
        fontWeight: 600,
        fontSize: "13px",
        padding: "10px 20px",
      },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error sending data:", error.message);
      closeSettingsModal();
      toast.error("Error in account setup !!", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }

    // Close the settings modal
    
   
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white py-4 rounded-lg w-[900px]">
        {/* Close Button */}
        <div
          className="absolute right-4 top-4 text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer"
          onClick={closeSettingsModal}
        >
          <XIcon className="w-6 h-6" />
        </div>

        {/* Modal Content */}
        {/* <div className="text-black text-[22px] leading-[30px] py-4 font-sans font-bold lg:px-12 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)]">
          Setup your Account
        </div> */}

        {/* Tab Buttons */}
        <div className="grid grid-cols-2 md:space-x-6 md:flex flex-row lg:px-8 border-b border-[#000000]/10 h-[50px] lg:h-[70px] font-poppins">
          <div
            className={`${
              selectedTab === "disclaimer"
                ? `${style.selected_2}`
                : `${style.unselected_2}`
            } px-4 lg:min-w-[120px] justify-center`}
            onClick={() => setSelectedTab("disclaimer")}
          >
            Disclaimer
          </div>
          <div
            className={`${
              selectedTab === "terms"
                ? `${style.selected_2}`
                : `${style.unselected_2}`
            } px-4 lg:min-w-[120px] justify-center`}
            onClick={() => isDisclaimerFilled && setSelectedTab("terms")}
            disabled={!isDisclaimerFilled}
          >
            Terms & Conditions
          </div>
          <div
            className={`${
              selectedTab === "rationale"
                ? `${style.selected_2}`
                : `${style.unselected_2}`
            } px-4 lg:min-w-[120px] justify-center`}
            onClick={() => isTermsFilled && setSelectedTab("rationale")}
            disabled={!isTermsFilled}
          >
            Rationale
          </div>
        </div>

        {/* Disclaimer Step */}
        {selectedTab === "disclaimer" && (
          <div className="flex flex-col space-y-4 max-w-[1000px] py-4 px-8">
            <label className="flex flex-col space-y-1 col-span-2">
              <textarea
                value={disclaimer}
                onChange={(e) => {
                  setDisclaimer(e.target.value);
                  setIsDisclaimerFilled(e.target.value.trim() !== "");
                }}
                className="w-full p-2 border rounded mb-4 text-[16px] font-medium placeholder:font-normal font-poppins"
                placeholder="Enter your disclaimer message here..."
                rows="10"
              />
            </label>
          </div>
        )}

        {/* Terms Step */}
        {selectedTab === "terms" && (
          <div className="flex flex-col space-y-4 max-w-[1000px] py-4 px-8">
            <label className="flex flex-col space-y-1 col-span-2">
              <textarea
                value={termsAndConditions}
                onChange={(e) => {
                  settermsAndConditions(e.target.value);
                  setIsTermsFilled(e.target.value.trim() !== "");
                }}
                className="w-full p-2 border rounded mb-4 text-[16px] font-medium placeholder:font-normal font-poppins"
                placeholder="Enter your terms & conditions message here..."
                rows="10"
              />
            </label>
          </div>
        )}

        {/* Rationale Step */}
        {selectedTab === "rationale" && (
          <div className="flex flex-col space-y-4 max-w-[1000px] py-4 px-8">
            <label className="flex flex-col space-y-1 col-span-2">
              <textarea
                value={rationale}
                onChange={(e) => setRationale(e.target.value)}
                className="w-full p-2 border rounded mb-4 text-[16px] font-medium placeholder:font-normal font-poppins"
                placeholder="Enter your rationale message here..."
                rows="10"
              />
            </label>
          </div>
        )}

        {/* Navigation Buttons */}
        <div className="flex justify-end mt-6 lg:px-8">
          {selectedTab === "disclaimer" && (
            <div className="flex space-x-4">
              <button
                onClick={closeSettingsModal}
                className="bg-[#ffffff]  text-black border-[1px] border-[#000000]/20 text-[18px] font-medium font-poppins rounded-md px-10 py-2 cursor-pointer"
              >
                Cancel
              </button>
              <button
                onClick={handleNext}
                className="bg-[#000000] text-white text-[18px] font-medium font-poppins rounded-md px-10 py-2 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
                disabled={!isDisclaimerFilled}
              >
                Next
              </button>
            </div>
          )}
          {selectedTab === "terms" && (
            <div className="flex space-x-4">
              <button
                onClick={handleBack}
                className="bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[18px] font-medium font-poppins rounded-md px-10 py-2 cursor-pointer"
              >
                Back
              </button>
              <button
                onClick={handleNext}
                className="bg-[#000000] text-white text-[18px] font-medium font-poppins rounded-md px-10 py-2 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
                disabled={!isTermsFilled}
              >
                Next
              </button>
            </div>
          )}

          {selectedTab === "rationale" && (
            <div className="flex space-x-4">
              <button
                onClick={handleBack}
                className="bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[18px] font-medium font-poppins rounded-md px-10 py-2 cursor-pointer"
              >
                Back
              </button>
              <button
                onClick={handleSave}
                className="bg-[#000000] text-white text-[18px] font-medium font-poppins rounded-md px-10 py-2 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
                disabled={rationale.trim() === ""}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
