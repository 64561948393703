import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";

const DisclaimerPage = () => {
  const [user] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex flex-col relative  w-full lg:min-h-screen  bg-[#f9f9f9] ">
      <div className="border-[1px] border-[#000000]/10 px-[20px]  sm:px-[30px] py-[14px] lg:px-20 lg:py-3 flex items-center justify-between">
        <h2 className=" font-sans text-[18px] sm:text-[24px] md:text-[28px] font-bold md:leading-[60px] text-left ">
          Disclaimer
        </h2>
        {!isLoading && user === null && (
          <Link
            to="/"
            className=" bg-[#000000] text-white text-[16px] md:text-[18px] font-medium font-poppins rounded-md px-6 py-[6px] md:px-8 md:py-2 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
          >
            Login
          </Link>
        )}
      </div>

      <div className="mt-1 space-y-4 list-decimal list-inside   px-[20px]  sm:px-[30px] pb-[10px] lg:px-20  lg:pb-3">
        <p className="font-poppins text-[16px] font-normal leading-[24px] text-left mt-2">
          Alphaquark, developed by Arpint Pvt Ltd, is a financial technology
          software currently in its beta stage. The information and
          recommendations provided through our Service are for informational
          purposes only and should not be considered financial advice. Please do
          your own research and/or consult a SEBI-registered expert (e.g., RIA)
          before making any investment decisions.
        </p>
        <p className="font-poppins text-[16px] font-normal leading-[24px] text-left mt-2">
          Arpint Pvt Ltd, as a technology service provider, makes no guarantees
          regarding the accuracy, reliability, or completeness of the
          information or functionality of the software and shall not be liable
          for any direct, indirect, incidental, or consequential damages arising
          from its use. All financial investments are subject to market risks;
          please read all scheme-related documents carefully. Our liability is
          capped at the lesser of the amount of monthly fees paid or INR
          1,00,000, and all disputes will be governed by the laws of India, with
          jurisdiction exclusively in the courts of Bengaluru. By using our
          Service, you acknowledge and accept these terms.
        </p>
      </div>
    </div>
  );
};

export default DisclaimerPage;
