import React, { useState, useEffect } from "react";
import axios from "axios";
import Logo from "../assests/Logo.jpg";
import { auth, provider } from "../firebase";
import { FcGoogle } from "react-icons/fc";
import SignInEmail from "./SignInEmail";
import ForgotPassword from "./forgotPassword";
import styles from "./login.module.css";
import SignUpEmail from "./SignUpEmail";
import server from "../utils/serverConfig";
import { Link, useNavigate } from "react-router-dom";

const WithoutLogin = () => {
  const navigate = useNavigate();

  const signIn = () => {
    auth
      .signInWithPopup(provider)
      .then((response) => {
        if (response) {
          axios
            .post(`${server.server.baseUrl}api/user/`, {
              email: response.additionalUserInfo.profile.email,
              name: response.additionalUserInfo.profile.name,
              firebaseId: response.additionalUserInfo.profile.id,
              imageUrl: response.additionalUserInfo.profile.picture,
              phoneNumber: 0,
              telegramId: "",
              profileCompletion: 50,
              user_onBoard_from: "AlphaQuark",
            })
            .then((result) => {
              navigate(`/stock-recommendation`);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch(alert);
  };

  const [showforgotPassword, setShowForgotPassord] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1700);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div
      className={`flex min-h-screen justify-center items-center bg-gray-100 px-[10px]`}
    >
      {isLoading ? (
        <div className="relative  w-full min-h-screen  bg-[#f9f9f9] py-24 text-3xl font-semibold font-sans flex items-center justify-center">
          <svg
            className="h-10 w-10 text-[#000000] animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : (
        <div
          className={`relative bg-white flex flex-col  justify-between border-[1px] border-[#000000]/20 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] rounded-[10px] lg:px-0 lg:py-[5px]`}
        >
          {showSignUp === true ? (
            <div className=" pt-6 flex flex-col  space-y-2 px-[16px] md:px-[40px]  lg:px-[40px]">
              <div className="flex flex-col justify-center items-center space-y-2">
              <img
                  src={Logo}
                  alt="AlphaQuark Logo"
                  width={60}
                  className="rounded-full"
                />
                <span className="text-black text-xl lg:text-2xl font-bold font-sans">
                  AlphaQuark
                </span>
              </div>

              <div className="flex flex-col  space-y-4">
                <div className="flex flex-col space-y-2 items-center justify-center">
                <h3 className="text-black text-center font-semibold text-[18px] lg:text-[20px] font-sans ">
                  Invest with AlphaQuark
                </h3>
                </div>
                <SignUpEmail
                  showSignUp={showSignUp}
                  setShowSignUp={setShowSignUp}
                />
              </div>
            </div>
          ) : showforgotPassword === true ? (
            <div className="pt-6 flex flex-col  space-y-2 px-[16px] md:px-[40px]  lg:px-[40px]">
              <div className="flex flex-col justify-center items-center space-y-2">
              <img
                  src={Logo}
                  alt="AlphaQuark Logo"
                  width={60}
                  className="rounded-full"
                />
                <span className="text-black text-xl lg:text-2xl font-bold font-sans">
                  AlphaQuark
                </span>
              </div>

              <div className="flex flex-col  space-y-8">
                <div className="flex flex-col space-y-2 items-center justify-center">
                <h3 className="text-black text-center font-semibold text-[18px] lg:text-[20px] font-sans ">
                  Invest with AlphaQuark
                </h3>
                </div>
                <ForgotPassword
                  setShowForgotPassord={setShowForgotPassord}
                  showforgotPassword={showforgotPassword}
                />
              </div>
            </div>
          ) : (
            <div className="pt-4 pb-1 flex flex-col w-full  md:w-[480px] space-y-1 ">
              <div className="flex flex-col justify-center items-center space-y-1 px-[20px] sm:px-[40px] lg:px-[40px]">
                <img
                  src={Logo}
                  alt="AlphaQuark Logo"
                  width={60}
                  className="rounded-full"
                />
                <span className="text-black text-xl lg:text-2xl font-bold font-sans">
                  AlphaQuark
                </span>
              </div>

              <div className="flex flex-col space-y-4 lg:space-y-6 px-[20px] sm:px-[40px] lg:px-[40px]">
                <h3 className="text-black text-center font-semibold text-[18px] lg:text-[20px] font-sans ">
                  Invest with AlphaQuark
                </h3>

                <div
                  className="flex items-center justify-center space-x-3 py-2 bg-black  text-center rounded-lg cursor-pointer"
                  onClick={signIn}
                >
                  <FcGoogle className=" w-[28px] h-[28px]" />
                  <span className="text-white font-medium text-lg font-poppins">
                    Sign In with Google
                  </span>
                </div>
              </div>

              <div className={`${styles.centerseparatorMobile} px-[20px] sm:px-[40px] lg:px-[40px]`}>
                <div className="flex items-center text-[#000000]/40 font-poppins text-[18px] font-medium justify-center">
                  or
                </div>
              </div>
              <SignInEmail
                setShowForgotPassord={setShowForgotPassord}
                showforgotPassword={showforgotPassword}
              />

              <div className="w-full flex flex-row text-[#000000]/60 font-normal text-[12px] lg:text-[14px] font-poppins justify-center items-center pb-[3px]  ">
                <div>No account ?</div>
                <div
                  className="ml-1.5 lg:ml-3 text-black font-medium text-[12px] lg:text-[16px]  font-poppins cursor-pointer underline"
                  onClick={() => setShowSignUp(true)}
                >
                  Signup with email
                </div>
              </div>
            </div>
          )}
          <div className="border-t-[1px] border-[#000000]/10 px-[16px] md:px-[20px] lg:px-[20px] flex flex-wrap max-w-[480px] mx-auto py-2 text-[10px]  leading-[20px] lg:py-2 items-center justify-center w-full  bottom-0  rounded-b-[10px] lg:text-[11px] font-normal text-[#818282] font-poppins">
            By signing in, you agree to our
            <Link
              to="/terms-and-conditions-by-alphaquark"
              className="text-[#818282] font-medium px-1 underline"
            >
              Terms & Conditions.
            </Link>{" "}
            See our{" "}
            <Link
              to="/privacy-policy"
              className="text-[#818282] font-medium px-1 underline"
            >
              Privacy Policy
            </Link>
            and{" "}
            <Link
              to="/contact-us"
              className="text-[#818282] font-medium px-1 underline"
            >
              Contact
            </Link>{" "}
            Us for more info..
          </div>
        </div>
      )}
    </div>
  );
};

export default WithoutLogin;
