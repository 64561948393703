import React, { useEffect, useState } from "react";
import Logo from "../assests/Logo.jpg";
import { cn } from "../utils/utils";
import { Link } from "react-router-dom";
import { auth } from "../firebase";
import {
  LogOutIcon,
  HistoryIcon,
  IndianRupeeIcon,
  LightbulbIcon,
  FileBarChartIcon,
  ChevronRightIcon,
  CircleAlertIcon,
  BanIcon,
  UserRound,
  XIcon,
  MenuIcon,
  CandlestickChartIcon,
  UserCog2Icon,
  GitForkIcon,
  ChevronLeftIcon,
  ChevronsLeftIcon,
  SettingsIcon,
  Wallet2Icon,
  HandCoins 
} from "lucide-react";
import server from "../utils/serverConfig";
import CryptoJS from "crypto-js";

import axios from "axios";
import formatCurrency from "../utils/formatCurrency";
import { fetchFunds } from "../FunctionCall/fetchFunds";

const Icons = {
  home: <FileBarChartIcon className="mr-5 h-[18px] w-[18px]" />,
  home2: <FileBarChartIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  advice: <LightbulbIcon className="mr-5 h-[18px] w-[18px]" />,
  advice2: <LightbulbIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  history: <HistoryIcon className="mr-5 h-[18px] w-[18px]" />,
  history2: <HistoryIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  sub: <SettingsIcon className="mr-5 h-[18px] w-[18px]" />,
  sub2: <SettingsIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  ignore: <BanIcon className="mr-5 h-[18px] w-[18px]" />,
  ignore2: <BanIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  userRound: <UserCog2Icon className="mr-5 h-[18px] w-[18px]" />,
  userRound2: <UserCog2Icon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  order: <CandlestickChartIcon className="mr-5 h-[18px] w-[18px]" />,
  order2: <CandlestickChartIcon className="mr-0 ml-1 h-[24px] w-[24px]" />,
  strategy: <GitForkIcon className="mr-5 h-[18px] w-[18px] -rotate-90" />,
  strategy2: <GitForkIcon className="mr-0 ml-1 h-[24px] w-[24px] -rotate-90" />,
  pricing: <HandCoins className="mr-5 h-[18px] w-[18px] " />,
  pricing2: <HandCoins  className="mr-0 ml-1 h-[24px] w-[24px] " />

};

const sideBarNavItems = [
  {
    title: "Live Portfolio",
    href: "/",
    path: "/",
    icon: "home",
    icon2: "home2",
    tooltip: "Portfolio",
  },
  {
    title: "Advice",
    href: "/stock-recommendation",
    path: "stock-recommendation",
    icon: "advice",
    icon2: "advice2",
    tooltip: "Advice",
  },
  {
    title: "Order Details",
    href: "/order-details",
    path: "order-details",
    icon: "order",
    icon2: "order2",
    tooltip: "Order Details",
  },
  {
    title: "Exited Trade History",
    href: "/trade-history",
    path: "trade-history",
    icon: "history",
    icon2: "history2",
    tooltip: "Trade History",
  },
  {
    title: "Broker Settings",
    href: "/subscriptions",
    path: "subscriptions",
    icon: "sub",
    icon2: "sub2",
    tooltip: "Subscription",
  },
  {
    title: "Strategy",
    href: "/strategy",
    path: "strategy",
    icon: "strategy",
    icon2: "strategy2",
    tooltip: "Strategy",
  },
  {
    title: "Pricing",
    href: "/pricing",
    path: "pricing",
    icon: "pricing",
    icon2: "pricing2",
    tooltip: "Advisor",
  },
  {
    title: "Contact Advisor",
    href: "/contact-advisor",
    path: "contact-advisor",
    icon: "userRound",
    icon2: "userRound2",
    tooltip: "Advisor",
  },
  // {
  //   title: "Ignored Trades",
  //   href: "/ignore-trades",
  //   path: "ignore-trades",
  //   icon: "ignore",
  //   icon2: "ignore2",
  //   tooltip: "Ignore Trades",
  // },

];

const DashboardNav = ({
  openUserProfileModal,
  openStepGuideModal,
  userDetails,
  adviceTradeData,
  ignoreTradesData,
  isExpanded,
  toggleExpand,
}) => {
  const [showLogoutModel, setShowLogoutModel] = useState(false);

  const path = window.location.pathname;

  var initials;
  var fullName = userDetails && userDetails.name;
  initials =
    fullName &&
    fullName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();

  // const filteredNavItems = sideBarNavItems.filter((item) => {
  //   if (userDetails && userDetails.user_broker === "Zerodha") {
  //     return item.title !== "Exited Trade History";
  //   }
  //   return true;
  // });

  const circumference = 2 * Math.PI * 20; // Adjust radius as needed

  const profileCompletion =
    userDetails && typeof userDetails.profile_completion === "number"
      ? userDetails.profile_completion
      : 0;

  const strokeDashoffset =
    circumference - (profileCompletion / 100) * circumference;

  const clientCode = userDetails && userDetails.clientCode;
  const apiKey = userDetails && userDetails?.apiKey;
  const broker = userDetails && userDetails.user_broker;
  const jwtToken = userDetails && userDetails.jwtToken;
  const my2pin = userDetails && userDetails.my2Pin;
  const secretKey = userDetails && userDetails.secretKey;

  const checkValidApiAnSecret = (data) => {
    const bytesKey = CryptoJS.AES.decrypt(data, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8);
    if (Key) {
      return Key;
    }
  };

  const [funds, setFunds] = useState({});

  const getAllFunds = async () => {
    const fetchedFunds = await fetchFunds(
      broker,
      clientCode,
      apiKey,
      jwtToken,
      secretKey
    );
    if (fetchedFunds) {
      setFunds(fetchedFunds);
    } else {
      console.error("Failed to fetch funds.");
    }
  };

  useEffect(() => {
    // Call the function when the component mounts or when relevant props change
    if (broker && (clientCode || jwtToken)) {
      getAllFunds();
    }
  }, [broker, clientCode, apiKey, jwtToken, secretKey]);

  return (
    <div
      className={`sticky top-0 hidden lg:flex  h-screen bg-black ${
        isExpanded ? "w-[300px]" : "w-[65px]"
      } transition-width duration-300 ease-in-out  
    `}
    >
      <aside className=" max-h-screen   w-full flex flex-col lg:flex   bg-black border-r-[1px] border-[#454646]/40">
        <div
          className={`w-full flex flex-row items-center   ${
            isExpanded ? "px-6 py-6" : "pl-[10px] py-6"
          }`}
        >
          {isExpanded ? (
            <div className="w-full flex items-center justify-between">
              <div className="flex space-x-5">
                <img
                  onClick={toggleExpand}
                  src={Logo}
                  alt="Logo"
                  className="rounded-md w-[40px] h-[40px]"
                />
                <div className="text-[22px] font-medium font-poppins text-[#f2f2f2] leading-[40px] ">
                  AlphaQuark
                </div>
              </div>
              <div
                onClick={toggleExpand}
                className=" flex items-center text-white  cursor-pointer"
              >
                <ChevronLeftIcon
                  strokeWidth={3}
                  className="h-6 w-6 text-white/80 "
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center space-y-3">
              <div
                onClick={toggleExpand}
                className=" flex items-center text-white  cursor-pointer"
              >
                <MenuIcon className="h-[30px] w-[30px] text-white/80 " />
              </div>
              <img
                src={Logo}
                alt="Logo"
                className="mx-auto rounded-md w-[36px] h-[36px]"
              />
            </div>
          )}
        </div>
        <nav
          className={` bg-black grid items-start  max-h-[500px] h-auto pb-[110px] custom-scroll-sidebar ${
            isExpanded ? "gap-y-0 overflow-y-scroll" : "gap-y-1"
          }  `}
        >
          {sideBarNavItems.map((item, index) => {
            const Icon = Icons[item.icon];
            const Icon2 = Icons[item.icon2];

            return (
              item.href && (
                <div className="" key={index}>
                  <Link
                    className=""
                    key={index}
                    to={item.disabled ? "/" : item.href}
                  >
                    <div
                      className={cn(
                        "group relative  text-sm border-l-4  hover:bg-[#ffffff]/10 hover:text-gray-300 text-[15px] text-zinc-500 font-normal font-poppins leading-[22px] flex items-center ",
                        path === item.href || path.startsWith(`/${item.path}`)
                          ? "bg-[#ffffff]/10 border-l-4 border-[#ffffff] text-[#ffffff]"
                          : "border-l-4 border-transparent bg-transparent",
                        item.disabled && "cursor-not-allowed opacity-80",
                        isExpanded
                          ? "px-[24px] py-[10px]"
                          : "pl-[10px] py-[10px]"
                      )}
                    >
                      {isExpanded ? <>{Icon}</> : <>{Icon2}</>}

                      <span>{isExpanded ? item.title : ""}</span>

                      {isExpanded &&
                        item.icon === "advice" &&
                        adviceTradeData &&
                        adviceTradeData.length >= 1 && (
                          <span className="ml-3">
                            ({adviceTradeData && adviceTradeData.length})
                          </span>
                        )}

                      {isExpanded &&
                        item.icon === "ignore" &&
                        ignoreTradesData &&
                        ignoreTradesData.length >= 1 && (
                          <span className="ml-3">
                            ({ignoreTradesData && ignoreTradesData.length})
                          </span>
                        )}
                      {!isExpanded && (
                        <div className="absolute hidden group-hover:flex min-w-[90px] left-[0px] -top-[22px] items-center justify-center px-2 py-1 rounded-md text-[10px] font-medium bg-black text-white">
                          {item.tooltip}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              )
            );
          })}

          <div
            onClick={() => setShowLogoutModel(true)}
            className={cn(
              "w-full group relative cursor-pointer text-sm border-l-4 border-transparent bg-transparent hover:bg-[#ffffff]/10 hover:text-gray-300 text-[15px] text-zinc-500 font-normal font-poppins leading-[22px] flex items-center ",
              isExpanded ? "px-[28px] py-[10px]" : "pl-[13px] py-[10px]"
            )}
          >
            <LogOutIcon
              className={`${
                isExpanded
                  ? "h-[20px] w-[20px] mr-[15px]"
                  : "h-[24px] w-[24px] mr-0 ml-1"
              }  text-zinc-500 group-hover:text-gray-300 `}
            />
            <span>{isExpanded ? "Logout" : ""}</span>
            {!isExpanded && (
              <div className="absolute hidden group-hover:flex min-w-[90px] left-[0px] -top-[22px] items-center justify-center px-2 py-1 rounded-md text-[10px] font-medium bg-black text-white">
                Logout
              </div>
            )}
          </div>
        </nav>

        {isExpanded &&
          userDetails &&
          userDetails.profile_completion !== 100 && (
            <div className="absolute bottom-[46px] flex flex-col space-y-[10px]  border-t-[1px] border-[#ffffff]/20 w-full px-4 py-[8px] bg-black">
              {broker && broker !== "" && (
                <div className="flex items-center justify-between bg-[#ffffff]/10 py-2.5 rounded-md space-x-4 px-4">
                  <div className="flex items-center text-[14px] font-medium text-white font-poppins">
                    <Wallet2Icon className="text-white h-5 w-5 mr-2" />
                    Cash Balance
                  </div>
                  <span
                    className={`
                      ${
                        funds?.data?.availablecash > 0
                          ? " text-[#16A085] "
                          : funds?.data?.availablecash < 0
                          ? " text-[#EA2D3F]"
                          : "text-white"
                      }
                        flex items-center text-[14px] font-medium  font-poppins`}
                  >
                    ₹
                    {funds?.data?.availablecash
                      ? formatCurrency(
                          parseFloat(funds?.data?.availablecash).toFixed(2)
                        )
                      : 0}
                  </span>
                </div>
              )}

              <div className="flex  justify-between items-center  w-full hover:cursor-pointer ">
                <div className="flex  space-x-3 items-center   text-white text-center ">
                  <div
                    onClick={openUserProfileModal}
                    className="relative flex items-center   justify-center rounded-full  "
                  >
                    <svg className="transform -rotate-90 w-[50px] h-[50px] ">
                      <circle
                        cx="25"
                        cy="25"
                        r="20"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="transparent"
                        className="text-gray-200"
                      />
                      <circle
                        cx="25"
                        cy="25"
                        r="20"
                        stroke="currentColor"
                        strokeWidth="4"
                        fill="transparent"
                        className="text-[#16A085]"
                        strokeDasharray={circumference}
                        strokeDashoffset={strokeDashoffset}
                      />
                    </svg>
                    <div className="absolute flex items-center justify-center rounded-full ">
                      {userDetails &&
                      userDetails?.image_url &&
                      userDetails?.image_url !== "" &&
                      userDetails?.image_url !== null ? (
                        <div className=" flex flex-row justify-center w-full">
                          <img
                            src={userDetails?.image_url}
                            alt="Logo"
                            className="rounded-full w-[35px] h-[35px]"
                          />
                        </div>
                      ) : (
                        <div className="flex font-medium font-poppins text-[20px] items-center  justify-center rounded-full  text-white">
                          {initials ||
                            userDetails?.email.slice(0, 2).toUpperCase()}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col items-start">
                    <span className="text-[16px] text-[#F2F2F2] font-poppins font-medium">
                      {userDetails && userDetails.name
                        ? userDetails.name
                        : userDetails?.email.split("@")[0]}
                    </span>
                    <span className="text-[13px] text-[#D9D9D9]/60 font-poppins font-normal ">
                      {userDetails && userDetails.email}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-1 flex flex-col space-y-3  w-full px-2">
                <button
                  onClick={openUserProfileModal}
                  className=" w-[180px] flex items-center justify-center py-1 px-4 rounded-[4px] text-[#ffffff] font-medium  text-[14px] font-poppins ring-[1px] ring-[#ffffff]/50"
                >
                  Complete Profile
                  <ChevronRightIcon className="h-5 w-5 text-white ml-1 " />
                </button>
              </div>
            </div>
          )}

        {!isExpanded &&
          userDetails &&
          userDetails.profile_completion !== 100 && (
            <div className="absolute bottom-2  flex  items-center pl-2  text-white text-center mb-2">
              <div
                onClick={openUserProfileModal}
                className="relative flex items-center   justify-center rounded-full  "
              >
                <svg className="transform -rotate-90 w-[50px] h-[50px] ">
                  <circle
                    cx="25"
                    cy="25"
                    r="20"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="transparent"
                    className="text-gray-200"
                  />
                  <circle
                    cx="25"
                    cy="25"
                    r="20"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="transparent"
                    className="text-[#16A085]"
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                  />
                </svg>
                <div className="absolute flex items-center justify-center rounded-full ">
                  {userDetails &&
                  userDetails?.image_url &&
                  userDetails?.image_url !== "" &&
                  userDetails?.image_url !== null ? (
                    <div className=" flex flex-row justify-center w-full">
                      <img
                        src={userDetails?.image_url}
                        alt="Logo"
                        className="rounded-full w-[35px] h-[35px]"
                      />
                    </div>
                  ) : (
                    <div className="flex font-medium font-poppins text-[20px] items-center  justify-center rounded-full  text-white">
                      {initials || userDetails?.email.slice(0, 2).toUpperCase()}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

        {/* Profile Icon and Name if profile completion 100%  */}

        {isExpanded &&
          userDetails &&
          userDetails.profile_completion === 100 && (
            <div className="absolute bottom-[46px] flex flex-col space-y-3  w-full border-t-[1px] border-[#ffffff]/20 py-3  px-6 bg-black">
              {broker && broker !== "" && (
                <div className="flex items-center justify-between bg-[#ffffff]/10 py-2.5 rounded-md space-x-4 px-4">
                  <div className="flex items-center text-[14px] font-medium text-white font-poppins">
                    <Wallet2Icon className="text-white h-5 w-5 mr-2" />
                    Cash Balance
                  </div>
                  <span
                    className={`
                      ${
                        funds?.data?.availablecash > 0
                          ? " text-[#16A085] "
                          : funds?.data?.availablecash < 0
                          ? " text-[#EA2D3F]"
                          : "text-white"
                      }
                        flex items-center text-[14px] font-medium  font-poppins`}
                  >
                    ₹
                    {funds?.data?.availablecash
                      ? formatCurrency(
                          parseFloat(funds?.data?.availablecash).toFixed(2)
                        )
                      : 0}
                  </span>
                </div>
              )}

              <div className="flex  space-x-3 items-center   text-white text-center   ">
                {userDetails &&
                userDetails?.image_url &&
                userDetails?.image_url !== "" &&
                userDetails?.image_url !== null ? (
                  <div
                    className=" flex items-center justify-center rounded-full ring-[3px]  ring-[#16A085] w-[40px] h-[40px] p-1 cursor-pointer"
                    onClick={openUserProfileModal}
                  >
                    <img
                      src={userDetails?.image_url}
                      alt="Profile Image"
                      width={50}
                      className="rounded-full"
                    />
                  </div>
                ) : (
                  <div
                    className="flex font-medium font-poppins text-[20px] items-center  justify-center rounded-full border-[2px] border-[#ffffff]/30 w-[45px] h-[45px] p-1 cursor-pointer"
                    onClick={openUserProfileModal}
                  >
                    {initials || userDetails?.email.slice(0, 2).toUpperCase()}
                  </div>
                )}
                <div className="flex flex-col items-start">
                  <span className="text-[15px] text-[#F2F2F2] font-poppins font-medium">
                    {userDetails && userDetails.name
                      ? userDetails.name
                      : userDetails?.email.split("@")[0]}
                  </span>
                  <span className="text-[13px] text-[#D9D9D9]/60 font-poppins font-normal">
                    {userDetails && userDetails.email}
                  </span>
                </div>
              </div>
            </div>
          )}

        {!isExpanded &&
          userDetails &&
          userDetails.profile_completion === 100 && (
            <div className="w-full absolute bottom-2  flex  items-center justify-center   text-white text-center mb-2">
              {userDetails &&
              userDetails?.image_url &&
              userDetails?.image_url !== "" &&
              userDetails?.image_url !== null ? (
                <div
                  onClick={openUserProfileModal}
                  className=" cursor-pointer  flex items-center justify-center rounded-full ring-[3px] ring-[#16A085]  w-[40px] h-[40px] p-1 "
                >
                  <img
                    src={userDetails?.image_url}
                    alt="Profile Image"
                    width={40}
                    className="rounded-full"
                  />
                </div>
              ) : (
                <div
                  onClick={openUserProfileModal}
                  className="cursor-pointer flex font-medium font-poppins text-[20px] items-center  justify-center rounded-full border-[2px] border-[#16A085] w-[40px] h-[40px] p-1"
                >
                  {initials || userDetails?.email.slice(0, 2).toUpperCase()}
                </div>
              )}
            </div>
          )}

        <div
          className={`absolute ${
            isExpanded
              ? "flex transition-width duration-200 ease-in-out"
              : "hidden"
          } ${
            userDetails && userDetails.profile_completion === 100
              ? "bottom-[8px]"
              : "bottom-2"
          } w-full bg-black  border-t-[1px] border-[#ffffff]/20 flex items-center space-x-2 py-2 px-4`}
        >
          <div
            className={`flex t items-center text-[#ffffff]/70 text-[13px]  font-poppins leading-[22px] font-normal
               
              `}
          >
            {" "}
            <CircleAlertIcon className="text-[#ffffff]/50 w-4 h-4 mr-1" />
            How to use AlphaQuark
            <Link
              onClick={openStepGuideModal}
              className="ml-1 underline text-blue-500 text-[14px] font-normal font-poppins"
              // to="/stock-recommendation"
            >
              Click here
            </Link>
          </div>
        </div>

        {showLogoutModel && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 ">
            <div className="relative">
              <div
                className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
                onClick={() => setShowLogoutModel(false)}
              >
                <XIcon className="w-5 h-5 mr-2" />
              </div>
              <div className="w-[480px] px-12 py-8 bg-white  border-[#000000]/20 rounded-md">
                <div className="text-[20px] text-[#000000] font-medium font-poppins text-center ">
                  Are you sure you want to logout?
                </div>
                <div className="mt-8 text-[18px] font-normal font-poppins text-center ">
                  <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                    <div
                      onClick={() => setShowLogoutModel(false)}
                      className=" flex items-center justify-center bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[18px] font-medium font-poppins rounded-md px-8 py-2 cursor-pointer"
                    >
                      Cancel
                    </div>
                    <div
                      onClick={() => auth.signOut()}
                      className=" flex items-center justify-center bg-[#ff0000] text-white text-[18px] font-medium font-poppins rounded-md px-8 py-2  cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
                    >
                      Logout
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </aside>
    </div>
  );
};

export default DashboardNav;
