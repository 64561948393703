import React from "react";
import { Trash2, XIcon, InfoIcon, MinusIcon, PlusIcon } from "lucide-react";
import axios from "axios";
import { useMediaQuery } from "../../hooks/use-media-query";
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
} from "../../components/ui/drawer";
import LoadingSpinner from "../../components/LoadingSpinner";
import server from "../../utils/serverConfig";

const ReviewTradeModel = ({
  calculateTotalAmount,
  stockDetails,
  getLTPForSymbol,
  setStockDetails,
  setOpenReviewTrade,
  placeOrder,
  loading,
  funds,
  openReviewTrade,
  getCartAllStocks,
  broker,
}) => {
  const handleDecreaseStockQty = (symbol, tradeId) => {
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: Math.max(stock.quantity - 1, 0) }
        : stock
    );
    setStockDetails(newData);
  };

  const handleIncreaseStockQty = (symbol, tradeId) => {
    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: stock.quantity + 1 }
        : stock
    );
    setStockDetails(newData);
  };

  const handleQuantityInputChange = (symbol, value, tradeId) => {
    const newQuantity = parseInt(value) || 0; // Convert value to integer or default to 0 if invalid

    const newData = stockDetails.map((stock) =>
      stock.tradingSymbol === symbol && stock.tradeId === tradeId
        ? { ...stock, quantity: newQuantity }
        : stock
    );
    setStockDetails(newData);
  };

  const handleSelectStock = async (symbol, tradeId) => {
    const isSelected = stockDetails.some(
      (selectedStock) =>
        selectedStock.tradingSymbol === symbol &&
        selectedStock.tradeId === tradeId
    );

    if (isSelected) {
      // If the stock is already selected, remove it from the cart
      try {
        await axios.post(
          `${server.server.baseUrl}api/cart/cart-items/remove/remove-from-cart`,
          { tradeId }
        );

        setStockDetails(
          stockDetails.filter(
            (selectedStock) =>
              selectedStock.tradingSymbol !== symbol ||
              selectedStock.tradeId !== tradeId
          )
        );
        getCartAllStocks();
      } catch (error) {
        console.error("Error removing stock from cart", error);
      }
    } else {
      // If the stock is not selected, add it to the cart
      const updatedStock = stockDetails.find(
        (item) => item.Symbol === symbol && item.tradeId === tradeId
      );
      if (updatedStock) {
        const newStock = {
          user_email: updatedStock.user_email,
          trade_given_by: updatedStock.trade_given_by,
          tradingSymbol: updatedStock.Symbol,
          transactionType: updatedStock.Type,
          exchange: updatedStock.Exchange,
          segment: updatedStock.Segment,
          productType: updatedStock.ProductType,
          orderType: updatedStock.OrderType,
          price: updatedStock.Price,
          quantity: updatedStock.Quantity,
          priority: updatedStock.Priority,
          tradeId: updatedStock.tradeId,
          user_broker: broker,
        };
        setStockDetails([...stockDetails, newStock]);

        try {
          await axios.post(
            `${server.server.baseUrl}api/cart/add/add-to-cart`,
            newStock
          );
          getCartAllStocks();
        } catch (error) {
          console.error("Error adding stock to cart", error);
        }
      }
    }
  };

  const isDesktop = useMediaQuery("(min-width: 830px)");
  const fullUrl = window.location.origin;
  if (isDesktop) {
    return (
      <Dialog open={openReviewTrade} onOpenChange={setOpenReviewTrade}>
        <DialogContent className="max-w-[55vw] w-full p-0">
          <div className="flex flex-col  w-full  rounded-[8px] pt-4  h-[630px] lg:h-auto bg-[#ffffff] ">
            <div className="px-6 flex flex-row justify-between items-center shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] ">
              <div className="text-[22px] text-black font-bold leading-[40px] font-sans pb-2">
                Review Trade Details
              </div>
            </div>

            <div className="px-6 flex flex-row items-center">
              {parseFloat(calculateTotalAmount()) > parseFloat(funds) ? (
                <div className="mt-3 mb-3  text-[14px] leading-[29px] text-[#EA882D] font-semibold lg:text-base font-sans">
                  Note : Orders may be rejected due to insufficient broker
                  balance of ₹{parseFloat(funds).toFixed(2)}.
                </div>
              ) : null}
            </div>

            <div className="">
              <div className=" w-full h-[380px] border-t-[1px] border-[#000000]/10  overflow-auto custom-scroll">
                <table className={`w-full  `}>
                  <thead className="bg-[#f5f5f5] sticky top-0 z-20 ">
                    <tr className="border-b-[1px] border-[#000000]/10">
                      <th className="text-[12px] lg:text-[13px] text-[#000000]/60 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                        Stocks
                      </th>

                      <th className="text-[12px] min-w-[120px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Current Price (₹)
                      </th>

                      <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Order
                      </th>
                      <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Quantity
                      </th>
                      <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Order Type
                      </th>
                      <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {stockDetails.map((ele, i) => {
                      return (
                        <tr
                          key={i}
                          className={`border-b-[1px]   border-[#000000]/10 last-of-type:border-none`}
                        >
                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8 ">
                            {ele.tradingSymbol}
                          </td>

                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-normal text-center py-3 px-5 ">
                            {getLTPForSymbol(ele.tradingSymbol)}
                          </td>

                          <td
                            className={
                              ele?.transactionType?.toLowerCase() === "buy"
                                ? "text-[#16A085] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                                : ele?.transactionType?.toLowerCase() === "sell"
                                ? "text-[#E43D3D] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                                : "text-[#000000]/80 text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                            }
                          >
                            {ele.transactionType?.toLowerCase()}
                          </td>
                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-2 lg:px-5 ">
                            <div className="flex flex-row items-center justify-between text-[12px] text-[#000000]/80 font-poppins">
                              <button
                                onClick={() =>
                                  handleDecreaseStockQty(
                                    ele.tradingSymbol,
                                    ele.tradeId
                                  )
                                }
                                disabled={ele.quantity <= 1}
                                className="cursor-pointer disabled:cursor-not-allowed hover:bg-black hover:text-white px-1 py-[2px] rounded-[4px]"
                              >
                                <MinusIcon className="  h-4 w-4" />
                              </button>

                              <input
                                type="text"
                                value={ele.quantity}
                                onChange={(e) =>
                                  handleQuantityInputChange(
                                    ele.tradingSymbol,
                                    e.target.value,
                                    ele.tradeId
                                  )
                                }
                                className="flex flex-1 items-center justify-center w-[45px] h-[28px] font-poppins text-[15px] mx-1 text-center border border-gray-300 rounded"
                              />

                              <button
                                onClick={() =>
                                  handleIncreaseStockQty(
                                    ele.tradingSymbol,
                                    ele.tradeId
                                  )
                                }
                                className="cursor-pointer hover:bg-black hover:text-white px-1 py-[2px] rounded-[4px]"
                              >
                                <PlusIcon className=" h-4 w-4" />
                              </button>
                            </div>
                          </td>
                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize">
                            {ele.orderType?.toLowerCase()}
                            {ele.orderType === "LIMIT" ||
                            ele.orderType === "STOP" ? (
                              <span className="capitalize">({ele.price})</span>
                            ) : null}
                          </td>
                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium flex flex-row justify-center text-center py-3 px-3 lg:py-4 lg:px-5 ">
                            <Trash2
                              size={18}
                              className="cursor-pointer hover:text-[#ff0000]"
                              onClick={() =>
                                handleSelectStock(
                                  ele.tradingSymbol,
                                  ele.tradeId
                                )
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <DialogFooter className=" sm:justify-between flex px-4 pt-4 pb-12  shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.06)]">
            <div className=" flex flex-row items-center">
              <div className=" text-[15px] leading-[22px] text-black font-semibold lg:text-base lg:leading-[40px] font-sans">
                Confirm Total Amount :
              </div>
              <div className="px-4 py-1 ml-6 lg:py-1 lg:px-8 text-lg text-[#000000]/80 font-bold border border-[#000000]/20 rounded-md">
                ₹{calculateTotalAmount()}
              </div>
            </div>

            <button
              className="w-[180px]  px-8 py-3 ml-4 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
              onClick={placeOrder}
            >
              {loading === true ? (
                <LoadingSpinner />
              ) : (
                <span className="text-[18px] font-medium text-[#ffffff] font-poopins ">
                  Place Order
                </span>
              )}
            </button>
          </DialogFooter>
          <div className="flex items-center justify-between w-full rounded-b-[8px] absolute bg-[#f9f9f9] h-[30px] bottom-0 px-6 py-1">
            <div className="text-[12px] font-normal font-poppins text-[#818282]">
              Alphaquark
            </div>

            <div className="flex items-center justify-evenly divide-x-[1px] divide-[#000000]/10">
              <a
                href={`${fullUrl}/disclaimer`}
                className="flex items-center justify-center px-2 text-[12px] text-[#818282] underline font-normal font-poppins"
                target="_blank"
                rel="noreferrer noopener"
              >
                Disclaimer
              </a>
              <a
                href={`${fullUrl}/terms-and-conditions-by-alphaquark`}
                className=" flex items-center justify-center px-2 text-[12px] text-[#818282] underline font-normal font-poppins"
                target="_blank"
                rel="noreferrer noopener"
              >
                T&C by Alphaquark
              </a>
              <a
                href={`${fullUrl}/terms-and-conditions-by-advisor`}
                className=" flex items-center justify-center px-2 text-[12px] text-[#818282] underline  font-normal font-poppins"
                target="_blank"
                rel="noreferrer noopener"
              >
                T&C by Advisor
              </a>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={openReviewTrade} onOpenChange={setOpenReviewTrade}>
      <DrawerContent className="focus:outline-none max-h-[560px] md:max-h-[690px] ">
        <div
          onClick={() => {
            setOpenReviewTrade(false);
            getCartAllStocks();
          }}
          className="absolute top-4 right-3 flex items-center justify-center "
        >
          <XIcon className="h-5 w-5 text-gray-900" />
        </div>
        <div className="pt-4 h-full ">
          <div className="px-6 flex flex-row justify-between items-center ">
            <div className="text-xl text-black font-semibold leading-[40px] font-sans">
              Review Trade Details
            </div>
          </div>
          {/* <div className="my-3 py-2 px-6 bg-[#000000]/10 text-[16px] text-[#000000]/70 font-normal font-poppins">
            Complete your transaction
          </div> */}

          {/* <div className="mt-3 px-6 flex flex-col items-start">
            <div className="flex flex-row items-center ">
              <div className=" text-[15px] leading-[22px] text-black font-semibold lg:text-base lg:leading-[40px] font-sans">
                Total Amount :
              </div>
              <div className="px-4 py-1 ml-6 lg:py-2 lg:px-8 text-[15px] text-[#000000]/80 font-bold border border-[#000000]/20 rounded-md">
                ₹{calculateTotalAmount()}
              </div>
            </div>
          </div> */}
          <div className="px-6 flex flex-row items-center">
            {parseFloat(calculateTotalAmount()) > parseFloat(funds) ? (
              <div className="mt-3 text-[12px] text-[#EA882D]  leading-[18px] text-red font-semibold lg:text-base font-sans">
                Note : Orders may be rejected due to insufficient broker balance
                of {parseFloat(funds).toFixed(2)}.
              </div>
            ) : null}
          </div>
          <div className="">
            <div className=" w-full h-[290px]  border-t-[1px] border-[#000000]/10 mt-2 overflow-auto custom-scroll">
              {/* <table className={`w-full `}>
                <thead className="bg-[#f5f5f5]  sticky top-0 z-20 ">
                  <tr className="border-b-[1px]  border-[#000000]/10">
                    <th className="text-[12px] min-w-[100px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                      Stocks
                    </th>

                    <th className="text-[12px] min-w-[120px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                      Current Price (₹)
                    </th>

                    <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                      Order (₹)
                    </th>
                    <th className="text-[12px] lg:text-[14px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                      Quantity
                    </th>
                    <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                      Order Type
                    </th>
                    <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {stockDetails.map((ele, i) => {
                    return (
                      <tr
                        key={i}
                        className={`border-b-[1px]   border-[#000000]/10 last-of-type:border-none`}
                      >
                        <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8 ">
                          {ele.tradingSymbol}
                        </td>

                        <td className="text-[15px]  text-[#000000]/80 font-poppins font-normal text-center py-3 px-5 ">
                          {getLTPForSymbol(ele.tradingSymbol)}
                        </td>

                        <td
                          className={
                            ele?.transactionType?.toLowerCase() === "buy"
                              ? "text-[#16A085] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                              : ele?.transactionType?.toLowerCase() === "sell"
                              ? "text-[#E43D3D] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                              : "text-[#000000]/80 text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                          }
                        >
                          {ele.transactionType?.toLowerCase()}
                        </td>
                        <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-2 lg:px-5 ">
                          <div className="flex flex-row items-center justify-between text-[12px] text-[#000000]/80 font-poppins">
                            <button
                              onClick={() =>
                                handleDecreaseStockQty(
                                  ele.tradingSymbol,
                                  ele.tradeId
                                )
                              }
                              disabled={ele.quantity <= 1}
                              className="cursor-pointer disabled:cursor-not-allowed hover:bg-black hover:text-white px-1 rounded-[4px]"
                            >
                              -
                            </button>

                            <input
                              type="text"
                              value={ele.quantity}
                              onChange={(e) =>
                                handleQuantityInputChange(
                                  ele.tradingSymbol,
                                  e.target.value,
                                  ele.tradeId
                                )
                              }
                              className="flex flex-1 items-center justify-center w-[45px] h-[28px] font-poppins text-[15px] mx-1 text-center border border-gray-300 rounded"
                            />

                            <button
                              onClick={() =>
                                handleIncreaseStockQty(
                                  ele.tradingSymbol,
                                  ele.tradeId
                                )
                              }
                              className="cursor-pointer hover:bg-black hover:text-white px-1 rounded-[4px]"
                            >
                              +
                            </button>
                          </div>
                        </td>
                        <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize ">
                          <span>
                            {ele.orderType?.toLowerCase()}
                            {ele.orderType === "LIMIT" ||
                            ele.orderType === "STOP" ? (
                              <span>({ele.price})</span>
                            ) : null}
                          </span>
                        </td>
                        <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium flex flex-row justify-center text-center py-[14px] px-3 lg:py-4 lg:px-5 ">
                          <div className="flex items-center justify-center">
                            <Trash2
                              size={18}
                              className="cursor-pointer hover:text-[#ff0000]"
                              onClick={() =>
                                handleSelectStock(
                                  ele.tradingSymbol,
                                  ele.tradeId
                                )
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table> */}

              {stockDetails.map((ele) => (
                <div className="flex items-center  border-b-[1px] border-[#000000]/10  py-4 px-4 bg-white font-poppins">
                  <div className="flex flex-1 items-start flex-col space-y-[6px] overflow-hidden">
                    <div className="text-[14px] truncate w-full   text-[#000000]/80 font-poppins font-semibold">
                      {ele.tradingSymbol}
                    </div>
                    <div
                      className={`${
                        ele.transactionType?.toLowerCase() === "buy"
                          ? "bg-[#16A085]/10 text-[#16A085] "
                          : "bg-[#EA2D3F]/10 text-[#EA2D3F]"
                      } flex items-center px-2 py-[1px] rounded-[4px]`}
                    >
                      <span className=" text-[14px] lg:text-[12px]  px-1 py-0  font-poppins font-medium capitalize">
                        {ele.transactionType?.toLowerCase()}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center justify-between pr-4 text-[12px] text-[#000000]/80 font-poppins">
                    <button
                      onClick={() =>
                        handleDecreaseStockQty(ele.tradingSymbol, ele.tradeId)
                      }
                      disabled={ele.quantity <= 1}
                      className="cursor-pointer disabled:cursor-not-allowed hover:bg-black hover:text-white px-1 py-[2px] rounded-[3px]"
                    >
                      <MinusIcon className=" h-3 w-3" />
                    </button>

                    <input
                      type="text"
                      value={ele.quantity}
                      onChange={(e) =>
                        handleQuantityInputChange(
                          ele.tradingSymbol,
                          e.target.value,
                          ele.tradeId
                        )
                      }
                      className="flex flex-1 items-center justify-center w-[52px] h-[22px] font-poppins text-[15px] mx-1 text-center border border-gray-300 rounded"
                    />

                    <button
                      onClick={() =>
                        handleIncreaseStockQty(ele.tradingSymbol, ele.tradeId)
                      }
                      className="cursor-pointer hover:bg-black hover:text-white px-1 py-[2px] rounded-[3px]"
                    >
                      <PlusIcon className=" h-3 w-3" />
                    </button>
                  </div>

                  <div className="flex pl-4 flex-col items-end space-y-[6px]">
                    <div className="text-[14px]  text-[#000000] font-poppins font-medium">
                      ₹{getLTPForSymbol(ele.tradingSymbol)}
                    </div>
                    <div className="text-[14px]  text-[#000000] font-poppins font-medium capitalize">
                      {ele.orderType?.toLowerCase()}
                      {ele.orderType === "LIMIT" || ele.orderType === "STOP" ? (
                        <span>({ele.price})</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <DrawerFooter className="pt-2 flex flex-col  w-full shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.06)]">
          <div className="flex  items-center justify-between space-x-4">
            <div className="flex flex-col items-start ">
              <div className=" min-w-[110px] text-[10px] leading-[22px] text-black font-semibold lg:text-base lg:leading-[24px] font-sans">
                Total Amount :
              </div>
              <div className="py-1 text-[18px] text-[#000000]/80 font-semibold   rounded-[4px]">
                ₹{calculateTotalAmount()}
              </div>
            </div>

            <button
              className=" w-full px-8 py-3 bg-black text-white text-base font-semiboold rounded-md cursor-pointer"
              onClick={placeOrder}
            >
              {loading === true ? (
                <LoadingSpinner />
              ) : (
                <span className="text-[18px] font-semibold text-[#ffffff] font-poopins ">
                  Place Order
                </span>
              )}
            </button>
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ReviewTradeModel;
