import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { XIcon } from "lucide-react";
import axios from "axios";
import { BiSolidOffer } from "react-icons/bi";
import { TiDocumentText } from "react-icons/ti";
import { IoIosArrowForward } from "react-icons/io";
import CryptoJS from "crypto-js";

import server from "../../utils/serverConfig";
import Checked from "../../assests/checked.svg";
import LoadingSpinner from "../../components/LoadingSpinner";
import IsMarketHours from "../../utils/isMarketHours";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import { fetchFunds } from "../../FunctionCall/fetchFunds";

const style = {
  offerSelected:
    "py-4 px-4 border-2 border-[#008000] rounded-md cursor-pointer",
  offerNotSelected:
    "py-4 px-4 border-2 border-[#000000]/20 rounded-md cursor-pointer",
};
const UserStrategySubscribeModal = ({
  fileName,
  userEmail,
  clientCode,
  apiKey,
  secretKey,
  jwtToken,
  broker,
  getStrategyDetails,
  strategyDetails,
  setOpenSubscribeModel,
  latestRebalance,
  setOpenSucessModal,
  setOrderPlacementResponse,
  setBrokerModel,
}) => {
  const [selectedOffer, setSelectedOffer] = useState("3 Months");
  const [openStrategy, setOpenStrategy] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmOrder, setConfirmOrder] = useState(false);

  const { getLTPForSymbol: getLTPForSymbolA } = useWebSocketCurrentPrice(
    latestRebalance.adviceEntries
  );

  const checkValidApiAnSecret = (data) => {
    const bytesKey = CryptoJS.AES.decrypt(data, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8);
    if (Key) {
      return Key;
    }
  };

  const [funds, setFunds] = useState(null);

  useEffect(() => {
    const getFunds = async () => {
      const fetchedFunds = await fetchFunds(
        broker,
        clientCode,
        apiKey,
        jwtToken,
        secretKey
      );
      if (fetchedFunds) {
        setFunds(fetchedFunds);
      } else {
        console.error("Failed to fetch funds.");
      }
    };

    // Call the function when the component mounts or when relevant props change
    if (broker && (clientCode || jwtToken)) {
      getFunds();
    }
  }, [broker, clientCode, apiKey, jwtToken, secretKey]);

  const [invetAmount, setInvestAmount] = useState();
  const [continueLoading, setContinueLoading] = useState(false);

  const matchCode = () => {
    setContinueLoading(true);
    if (couponCode.toUpperCase() === "ALPHA100") {
      let data = JSON.stringify({
        email: userEmail,
      });

      let config = {
        method: "put",
        url: `${server.server.baseUrl}api/model-portfolio/subscribe-strategy/${strategyDetails?._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          getStrategyDetails();
        })
        .catch((error) => {
          console.log(error);
        });

      let data2 = JSON.stringify({
        userEmail: userEmail,
        model: strategyDetails?.model_name,
        advisor: strategyDetails?.advisor,
        model_id: latestRebalance.model_Id,
        subscriptionAmountRaw: [
          {
            amount: invetAmount,
            dateTime: new Date(),
          },
        ],
      });

      let config2 = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}rebalance/insert-user-doc`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data2,
      };

      axios
        .request(config2)
        .then((response) => {
          getStrategyDetails();
          setOpenStrategy(true);
          setContinueLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const [calculatedPortfolioData, setCaluculatedPortfolioData] = useState([]);
  const [calculatedLoading, setCalculateLoading] = useState(false);

  const calculateRebalance = () => {
    setCalculateLoading(true);
    let data = JSON.stringify({
      userEmail: userEmail,
      userBroker: broker,
      modelName: strategyDetails?.model_name,
      model_id: latestRebalance.model_Id,
      advisor: strategyDetails?.advisor,
      userFund: funds?.data?.availablecash,
    });
    console.log("data", data);
    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}rebalance/calculate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        console.log("res", response);
        if (response.data) {
          setCaluculatedPortfolioData(response.data);
          setCalculateLoading(false);
          setConfirmOrder(true);
        } else {
          setCaluculatedPortfolioData([]);
          setCalculateLoading(false);
          setConfirmOrder(false);
        }
      })
      .catch((error) => {
        setCalculateLoading(false);
        console.log(error);
      });
  };

  const dataArray =
    calculatedPortfolioData?.length !== 0
      ? [
          ...Object.entries(calculatedPortfolioData?.buy).map(
            ([symbol, qty]) => ({
              symbol,
              qty,
              orderType: "BUY",
              exchange: symbol.endsWith("-EQ") ? "NSE" : "BSE", // Set exchange based on suffix
            })
          ),
          ...Object.entries(calculatedPortfolioData?.sell).map(
            ([symbol, qty]) => ({
              symbol,
              qty,
              orderType: "SELL",
              exchange: symbol.endsWith("-EQ") ? "NSE" : "BSE", // Set exchange based on suffix
            })
          ),
        ]
      : [];

  const { getLTPForSymbol: getLTPForSymbolB } =
    useWebSocketCurrentPrice(dataArray);

  const totalInvestmentValue = dataArray
    .filter((item) => item.orderType === "BUY")
    .reduce((total, item) => {
      const currentPrice = getLTPForSymbolB(item.symbol);
      const investment = item.qty * currentPrice;
      return total + investment;
    }, 0);

  const cashRequired = dataArray.reduce((totalCash, item) => {
    const currentPrice = getLTPForSymbolB(item.symbol); // Get the current price for the symbol
    if (item.orderType === "BUY") {
      // Buy order: Add buy price * quantity
      totalCash += item.qty * currentPrice;
    } else if (item.orderType === "SELL") {
      // Sell order: Subtract sell price * quantity * 80%
      totalCash -= item.qty * currentPrice * 0.8;
    }
    return totalCash;
  }, 0);

  const convertResponse = (dataArray) => {
    return dataArray.map((item) => {
      return {
        transactionType: item.orderType,
        exchange: item.exchange,
        segment: "EQUITY",
        productType: "DELIVERY",
        orderType: "MARKET",
        price: 0,
        tradingSymbol: item.symbol,
        quantity: item.qty,
        priority: 0,
        user_broker: broker,
      };
    });
  };

  const stockDetails = convertResponse(dataArray);

  const placeOrder = () => {
    const isMarketHours = IsMarketHours();
    setLoading(true);
    if (broker === undefined) {
      setBrokerModel(true);
      setLoading(false);
    } else if (funds?.meesage === "Invalid Token" && funds?.success === false) {
    } else if (!isMarketHours) {
      toast.error("Orders cannot be placed after Market hours.", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      return;
    } else {
      if (broker === "IIFL Securities") {
        let data = JSON.stringify({
          clientCode: clientCode,
          modelName: strategyDetails?.model_name,
          advisor: strategyDetails?.advisor,
          model_id: latestRebalance.model_Id,
          unique_id: calculatedPortfolioData?.uniqueId,
          user_broker: broker,
          user_email: userEmail,
          trades: stockDetails,
        });

        let config2 = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}rebalance/process-trade`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config2)
          .then((response) => {
            setOrderPlacementResponse(response.data.results);
            const updateData = {
              modelId: latestRebalance.model_Id,
              orderResults: response.data.results,
              modelName: strategyDetails?.model_name,
              userEmail: userEmail,
            };

            return axios.post(
              `${server.server.baseUrl}api/model-portfolio-db-update`,
              updateData
            );
          })
          .then((updateResponse) => {
            setLoading(false);
            setOpenSucessModal(true);
            setOpenSubscribeModel(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (broker === "ICICI Direct") {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          secretKey: checkValidApiAnSecret(secretKey),
          sessionToken: jwtToken,
          modelName: strategyDetails?.model_name,
          advisor: strategyDetails?.advisor,
          model_id: latestRebalance.model_Id,
          unique_id: calculatedPortfolioData?.uniqueId,
          user_broker: broker,
          user_email: userEmail,
          trades: stockDetails,
        });

        let config2 = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}rebalance/process-trade`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config2)
          .then((response) => {
            setOrderPlacementResponse(response.data.results);
            const updateData = {
              modelId: latestRebalance.model_Id,
              orderResults: response.data.results,
              modelName: strategyDetails?.model_name,
              userEmail: userEmail,
            };

            return axios.post(
              `${server.server.baseUrl}api/model-portfolio-db-update`,
              updateData
            );
          })
          .then((updateResponse) => {
            setLoading(false);
            setOpenSucessModal(true);
            setOpenSubscribeModel(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (broker === "Upstox") {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          secretKey: checkValidApiAnSecret(secretKey),
          accessToken: jwtToken,
          modelName: strategyDetails?.model_name,
          advisor: strategyDetails?.advisor,
          model_id: latestRebalance.model_Id,
          unique_id: calculatedPortfolioData?.uniqueId,
          user_broker: broker,
          user_email: userEmail,
          trades: stockDetails,
        });

        let config2 = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}rebalance/process-trade`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config2)
          .then((response) => {
            setOrderPlacementResponse(response.data.results);
            const updateData = {
              modelId: latestRebalance.model_Id,
              orderResults: response.data.results,
              modelName: strategyDetails?.model_name,
              userEmail: userEmail,
            };

            return axios.post(
              `${server.server.baseUrl}api/model-portfolio-db-update`,
              updateData
            );
          })
          .then((updateResponse) => {
            setLoading(false);
            setOpenSucessModal(true);
            setOpenSubscribeModel(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (broker === "Angel One") {
        let data = JSON.stringify({
          apiKey: apiKey,
          jwtToken: jwtToken,
          modelName: strategyDetails?.model_name,
          advisor: strategyDetails?.advisor,
          model_id: latestRebalance.model_Id,
          unique_id: calculatedPortfolioData?.uniqueId,
          user_broker: broker,
          user_email: userEmail,
          trades: stockDetails,
        });

        let config2 = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}rebalance/process-trade`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config2)
          .then((response) => {
            setOrderPlacementResponse(response.data.results);
            const updateData = {
              modelId: latestRebalance.model_Id,
              orderResults: response.data.results,
              modelName: strategyDetails?.model_name,
              userEmail: userEmail,
            };

            return axios.post(
              `${server.server.baseUrl}api/model-portfolio-db-update`,
              updateData
            );
          })
          .then((updateResponse) => {
            setLoading(false);
            setOpenSucessModal(true);
            setOpenSubscribeModel(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 px-2 ">
      <div className="relative">
        <div
          className="absolute right-2 top-6  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
          onClick={() => setOpenSubscribeModel(false)}
        >
          <XIcon className="w-6 h-6 mr-2  " />
        </div>

        <div className="flex flex-col w-full h-[600px] lg:w-[820px] lg:h-full   bg-white  border-[#000000]/20 rounded-md pt-6 overflow-hidden">
          <div className="capitalize flex  w-full pb-4 text-[18px] lg:text-[22px]  text-[#000000] font-sans font-bold px-8 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] ">
            {openStrategy === true ? "Review Trade Details of" : "Invest in"}{" "}
            {fileName?.replaceAll(/_/g, " ")}
          </div>
          {openStrategy === true ? (
            <div className="flex flex-col space-y-3 h-[480px]  overflow-y-scroll py-4 ">
              {confirmOrder === true ? (
                <div className="px-2 lg:px-8">
                  <div className="text-lg font-semibold font-poppins">
                    Amount of cash required to place this order today : ₹{" "}
                    {parseFloat(cashRequired).toFixed(2)}
                  </div>
                  <div className="mt-2 test-sm text-[#000000]/40">
                    Adjusted to maintain stocks/ETFs in the suggested proportion
                  </div>
                </div>
              ) : null}

              <div>
                {confirmOrder === true ? (
                  <table className={`mt-4 w-full px-2 lg:px-8`}>
                    <thead className="bg-[#f5f5f5]  sticky top-0 z-20 ">
                      <tr className="border-b-[1px]   border-[#000000]/10">
                        <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                          Constituents
                        </th>

                        <th className="text-[12px] min-w-[120px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                          Current Price (₹)
                        </th>

                        <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                          Qunatity
                        </th>

                        <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                          Order Type
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataArray?.map((ele, i) => {
                        return (
                          <tr
                            key={i}
                            className={`border-b-[1px]   border-[#000000]/10 last-of-type:border-none`}
                          >
                            <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8 ">
                              {ele.symbol}
                            </td>

                            <td className="text-[15px]  text-[#000000]/80 font-poppins font-normal text-center py-3 px-5 ">
                              ₹ {getLTPForSymbolB(ele.symbol)}
                            </td>

                            <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-2 lg:px-5 ">
                              {ele.qty}
                            </td>

                            <td
                              className={
                                ele?.orderType?.toLowerCase() === "buy"
                                  ? "text-[#16A085] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                                  : ele?.orderType?.toLowerCase() === "sell"
                                  ? "text-[#E43D3D] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                                  : "text-[#000000]/80 text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                              }
                            >
                              {ele.orderType?.toLowerCase()}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <table className={`mt-4 w-full px-2 lg:px-8`}>
                    <thead>
                      <tr className="border-b-[1px] border-t-[1px]  border-[#000000]/10">
                        <th className="text-[12px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                          Constituents
                        </th>

                        <th className="text-[12px] min-w-[120px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                          Current Price (₹)
                        </th>

                        <th className="text-[12px] min-w-[90px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                          Weights(%)
                        </th>

                        <th className="text-[12px] min-w-[90px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                          Order Type
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {latestRebalance?.adviceEntries.map((ele, i) => {
                        return (
                          <tr
                            key={i}
                            className={`border-b-[1px]   border-[#000000]/10 last-of-type:border-none`}
                          >
                            <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8 ">
                              {ele.symbol}
                            </td>

                            <td className="text-[15px]  text-[#000000]/80 font-poppins font-normal text-center py-3 px-5 ">
                              ₹ {getLTPForSymbolA(ele.symbol)}
                            </td>

                            <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-2 lg:px-5 ">
                              {parseFloat(ele.value * 100).toFixed(2)}
                            </td>

                            <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 ">
                              Buy
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          ) : (
            <div className="flex flex-col space-y-3 h-[480px]  overflow-y-scroll px-2 lg:px-8 py-4 ">
              <div className="px-6 flex flex-row justify-between py-3 border-2 border-[#000000]/20 rounded-md">
                <div className="flex items-center">
                  <img
                    src={Checked}
                    alt="Checked Icon"
                    className="w-4 h-4 md:w-6 h-6 mr-2"
                  />
                  <span> Email</span>
                </div>
                <span className="font-semibold"> {userEmail}</span>
              </div>

              <div className="px-6 py-3 border-2 border-[#000000]/20 rounded-md">
                <div className="text-lg font-semibold">Select a plan</div>
                <div className="text-sm text-[#000000]/70">
                  Subscription will renew automatically
                </div>
                <div className="flex flex-row mt-8">
                  <div
                    className={
                      selectedOffer === "3 Months"
                        ? style.offerSelected
                        : style.offerNotSelected
                    }
                    onClick={() => setSelectedOffer("3 Months")}
                  >
                    <div>3 months</div>
                    <div>₹ 1300/m</div>
                  </div>
                  <div
                    className={
                      selectedOffer === "6 Months"
                        ? `ml-3 ${style.offerSelected}`
                        : `ml-3 ${style.offerNotSelected}`
                    }
                    onClick={() => setSelectedOffer("6 Months")}
                  >
                    <div>6 months</div>
                    <div>₹ 1083/m</div>
                  </div>
                </div>
              </div>
              {openCouponModal === true ? (
                <div className=" border-2 border-[#000000]/20 rounded-md">
                  <div className="px-6 py-4 ">
                    <div>Enter Coupon Code</div>
                    <input
                      type="text"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      className="px-4 w-full mt-4 py-3 text-balck bg-white border-2 border-[#000000]/20 rounded-md"
                      placeholder="Enter coupon code here"
                    />
                  </div>
                </div>
              ) : (
                <div className=" border-2 border-[#000000]/20 rounded-md">
                  <div className="px-6 py-2 flex flex-row justify-between border-dashed border-b-2 border-[#000000]/20">
                    <div className="flex flex-row items-center">
                      <TiDocumentText />
                      <span className="ml-2">Amount to pay</span>
                    </div>
                    <div>
                      ₹ {selectedOffer === "3 Months" ? "3900" : "6498"}
                    </div>
                  </div>

                  <div
                    className="px-6 py-2 flex flex-row justify-between cursor-pointer"
                    onClick={() => setOpenCouponModal(true)}
                  >
                    <div className="flex flex-row items-center">
                      <BiSolidOffer />
                      <span className="ml-2">Apply Offer</span>
                    </div>
                    <IoIosArrowForward />
                  </div>
                </div>
              )}
              <div className="px-2 py-4 ">
                <div>
                  Min Invested Amount{" "}
                  <span className="ml-1 text-red-500 text-[14px]">*</span>
                </div>
                <input
                  type="number"
                  value={invetAmount}
                  onChange={(e) => setInvestAmount(e.target.value)}
                  className="px-4 w-full mt-4 py-3 text-black bg-white border-2 border-[#000000]/20 rounded-md"
                  placeholder="Enter Amount here"
                />
                <div className="text-black">
                  Min Invested Amount is equal or greater than{" "}
                  {strategyDetails?.minInvestment}.
                </div>
              </div>
              <div className="mt-8">
                {" "}
                <button
                  disabled={
                    !couponCode ||
                    !(invetAmount >= strategyDetails?.minInvestment)
                  }
                  onClick={matchCode}
                  className="disabled:bg-[#000000]/30 disabled:cursor-not-allowed flex flex-row items-center justify-center py-2 w-full mb-3 rounded-lg bg-black/100 text-white font-medium hover:bg-black/90 ease-linear duration-150"
                >
                  <div className="ml-4 text-white font-medium text-lg font-poppins ">
                    <div className="flex flex-row items-center justify-center">
                      {continueLoading === true ? (
                        <LoadingSpinner />
                      ) : (
                        "Continue"
                      )}
                    </div>
                  </div>
                </button>
              </div>
            </div>
          )}
          {openStrategy === true ? (
            <div className="flex flex-col space-y-4 w-full  shadow-[0px_-4px_4px_0px_#0000000f] py-4 px-2 lg:px-8 ">
              {confirmOrder === true ? (
                <div className="flex flex-row justify-between">
                  <div>
                    <div>Total Investment</div>
                    <div>₹ {parseFloat(totalInvestmentValue).toFixed(2)}</div>
                  </div>
                  <div className="flex justify-end space-x-5 ">
                    <button
                      className=" bg-[#ffffff] rounded-[4px] py-2 px-3 lg:py-3 lg:px-8 text-[14px] lg:text-[16px] text-center text-[#000000] font-poppins font-medium disabled:cursor-not-allowed border-4 border-[#000000]/20"
                      onClick={() => setConfirmOrder(false)}
                    >
                      Back
                    </button>
                    <button
                      className=" disabled:bg-[#000000]/50 bg-[#000000] rounded-[4px] py-2 px-3 lg:py-3 lg:px-8 text-[14px] lg:text-[16px] text-center text-[#ffffff] font-poppins font-medium disabled:cursor-not-allowed"
                      onClick={placeOrder}
                    >
                      {loading === true ? (
                        <LoadingSpinner />
                      ) : (
                        <span className="text-[18px] font-medium text-[#ffffff] font-poopins ">
                          Place Order
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row justify-between">
                  <div>
                    <div>Min. Investment Amount :</div>
                    <div>
                      {" "}
                      {parseFloat(
                        latestRebalance?.totalInvestmentvalue
                      ).toFixed(2)}
                    </div>
                  </div>

                  <div className="flex justify-end space-x-5 ">
                    <button
                      className=" bg-[#ffffff] rounded-[4px] py-2 px-3 lg:py-3 lg:px-8 text-[14px] lg:text-[16px] text-center text-[#000000] font-poppins font-medium disabled:cursor-not-allowed border-4 border-[#000000]/20"
                      onClick={() => {
                        setOpenStrategy(false);
                        setOpenSubscribeModel(false);
                      }}
                    >
                      Back
                    </button>
                    <button
                      className=" disabled:bg-[#000000]/50 bg-[#000000] rounded-[4px] py-2 px-3 lg:py-3 lg:px-8 text-[14px] lg:text-[16px] text-center text-[#ffffff] font-poppins font-medium disabled:cursor-not-allowed"
                      onClick={calculateRebalance}
                    >
                      {calculatedLoading === true ? (
                        <LoadingSpinner />
                      ) : (
                        "Confirm Details"
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UserStrategySubscribeModal;
