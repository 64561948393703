import React from "react";
import { useNavigate } from "react-router-dom";

import MPF_1 from "../../assests/mpf_1.png";
import { ChevronRightIcon } from "lucide-react";

const ModalPFCard = ({ modalPFDark, modelName, repair }) => {
  const navigate = useNavigate();

  const handleCardClick = (modelName) => {
    navigate(`/strategy/${modelName.toLowerCase().replace(/ /g, "_")}`);
  };
  return (
    <div
      className={`bg-[#000000]/10 hover:bg-[#000000]/20 w-full shadow-[3px_5px_20px_0px_rgba(0,0,0,0.06)]  grid grid-cols-7 px-10 py-5`}
    >
      <div className="flex items-center space-x-4 col-span-2">
        <img src={MPF_1} alt="Portfolio" className="w-12 h-10" />
        <div className="flex  flex-col">
          <div
            className={`text-[#ffffff]/80 font-medium font-poppins text-[16px] capitalize`}
          >
            {modelName}
          </div>
          {repair ? (
            <div className=" w-[55px] flex items-center justify-center font-medium font-poppins  rounded-[3px] bg-[#DE8846] text-[10px] text-[#ffffff]">
              Repair
              <ChevronRightIcon className="text-[#ffffff] h-3 w-3" />
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <p className={`text-[#ffffff]/80 font-medium font-poppins text-[16px]`}>
          ₹7.5L
        </p>
      </div>
      <div className="flex items-center justify-center">
        <p className={`text-[#ffffff]/80 font-medium font-poppins text-[16px]`}>
          ₹16L
        </p>
      </div>
      <div className="flex items-center justify-center">
        <p className={`text-[#ffffff]/80 font-medium font-poppins text-[16px]`}>
          120%
        </p>
      </div>
      <div className="flex items-center justify-center ">
        <p className={`text-[#7CFA8B] font-semibold font-poppins text-[16px]`}>
          +25%
        </p>
      </div>
      <div className="flex items-center justify-end">
        <div
          className={`text-[#ffffff]/80 border-[1px] border-[#ffffff]/40 rounded-[4px] px-4 py-1 font-medium font-poppins text-[14px] cursor-pointer`}
          onClick={() => handleCardClick(modelName)}
        >
          View
        </div>
      </div>
    </div>
  );
};

export default ModalPFCard;
