import axios from "axios";
import CryptoJS from "crypto-js";

import server from "../utils/serverConfig";

const checkValidApiAnSecret = (data) => {
  const bytesKey = CryptoJS.AES.decrypt(data, "ApiKeySecret");
  const Key = bytesKey.toString(CryptoJS.enc.Utf8);
  if (Key) {
    return Key;
  }
};

export const fetchFunds = async (
  broker,
  clientCode,
  apiKey,
  jwtToken,
  secretKey,
  viewToken,
  sid,
  serverId
) => {
  let data, url;

  switch (broker) {
    case "IIFL Securities":
      if (!clientCode) return;
      data = JSON.stringify({ clientCode });
      url = `${server.ccxtServer.baseUrl}iifl/margin`;
      break;
    case "ICICI Direct":
      if (!apiKey || !jwtToken || !secretKey) return;
      data = JSON.stringify({
        apiKey: checkValidApiAnSecret(apiKey),
        sessionToken: jwtToken,
        secretKey: checkValidApiAnSecret(secretKey),
      });
      url = `${server.ccxtServer.baseUrl}icici/funds`;
      break;
    case "Upstox":
      if (!apiKey || !jwtToken || !secretKey) return;
      data = JSON.stringify({
        apiKey: checkValidApiAnSecret(apiKey),
        accessToken: jwtToken,
        apiSecret: checkValidApiAnSecret(secretKey),
      });
      url = `${server.ccxtServer.baseUrl}upstox/funds`;
      break;
    case "Angel One":
      if (!apiKey || !jwtToken) return;
      data = JSON.stringify({
        apiKey,
        jwtToken,
      });
      url = `${server.ccxtServer.baseUrl}funds`;
      break;
    case "Zerodha":
      if (!jwtToken) return;
      data = JSON.stringify({
        apiKey: "b0g1r806oitsamoe",
        accessToken: jwtToken,
      });
      url = `${server.ccxtServer.baseUrl}zerodha/funds`;
      break;
    case "Hdfc Securities":
      if (!apiKey || !jwtToken) return;
      data = JSON.stringify({
        apiKey: checkValidApiAnSecret(apiKey),
        accessToken: jwtToken,
      });
      url = `${server.ccxtServer.baseUrl}hdfc/funds`;
      break;
    case "Kotak":
      if (!jwtToken || !apiKey || !secretKey || !viewToken || !sid || !serverId)
        return;
      data = JSON.stringify({
        consumerKey: checkValidApiAnSecret(apiKey),
        consumerSecret: checkValidApiAnSecret(secretKey),
        accessToken: jwtToken,
        viewToken,
        exchange: "NSE",
        segment: "CASH",
        product: "ALL",
        sid,
        serverId,
      });
      url = `${server.ccxtServer.baseUrl}kotak/funds`;
      break;
    default:
      return; // If the broker is not recognized
  }

  try {
    const response = await axios.post(url, data, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
