import React, { useState, useEffect } from "react";
import { GitForkIcon, Info, Gauge } from "lucide-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import CryptoJS from "crypto-js";

import { auth } from "../../firebase";
import Alpha100 from "../../assests/alpha-100.png";
import server from "../../utils/serverConfig";
import UserStrategySubscribeModal from "./UserStrategySubscribeModal";
import formatCurrency from "../../utils/formatCurrency";
import ConnectBroker from "../UserHomePage/connectBroker";
import RecommendationSuccessModal from "../StockRecommendation/RecommendationSuccessModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import { fetchFunds } from "../../FunctionCall/fetchFunds";
import TokenExpireBrokarModal from "../StockRecommendation/TokenExpireBrokarModal";

const style = {
  selected:
    "flex items-center text-[18px]  font-sans text-black font-bold lg:leading-[42px]",
  unselected:
    "flex items-center text-[18px]  font-sans font-medium text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const StrategyList = () => {
  const navigate = useNavigate();
  const [allStrategy, setAllStrategy] = useState([]);
  const [openSubscribeModel, setOpenSubscribeModel] = useState(false);
  const [brokerModel, setBrokerModel] = useState(false);
  const [openSuccessModal, setOpenSucessModal] = useState(false);
  const [orderPlacementResponse, setOrderPlacementResponse] = useState();
  const [strategyDetails, setStrategyDetails] = useState(null);
  const [latestRebalance, setLatestRebalance] = useState(null);
  const [fileName, setFileName] = useState(null);

  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [userDetails, setUserDetails] = useState();

  const getUserDeatils = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`)
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserDeatils();
  }, [userEmail, server.server.baseUrl]);

  const [broker, setBroker] = useState("");
  useEffect(() => {
    if (userDetails) {
      setBroker(userDetails.user_broker);
    }
  }, [userDetails]);

  const clientCode = userDetails && userDetails?.clientCode;
  const apiKey = userDetails && userDetails?.apiKey;
  const jwtToken = userDetails && userDetails?.jwtToken;
  const my2pin = userDetails && userDetails?.my2Pin;
  const secretKey = userDetails && userDetails?.secretKey;
  const userId = userDetails && userDetails?._id;
  const mobileNumber = userDetails && userDetails?.phone_number;
  const panNumber = userDetails && userDetails?.panNumber;

  const [openTokenExpireModel, setOpenTokenExpireModel] = useState(null);

  const dateString = userDetails && userDetails.token_expire;
  // Format the moment object as desired
  const expireTokenDate = moment(dateString).format("YYYY-MM-DD HH:mm:ss");

  const today = new Date();
  const todayDate = moment(today).format("YYYY-MM-DD HH:mm:ss");

  const checkValidApiAnSecret = (data) => {
    const bytesKey = CryptoJS.AES.decrypt(data, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8);
    if (Key) {
      return Key;
    }
  };

  const getAllStrategy = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/model-portfolio/portfolios/AlphaQuarkTest`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("res", response);

        setAllStrategy(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllStrategy();
  }, []);

  const handleCardClick = (modelName) => {
    navigate(`/strategy/${modelName.toLowerCase().replace(/ /g, "_")}`);
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const [funds, setFunds] = useState({});

  const getAllFunds = async () => {
    const fetchedFunds = await fetchFunds(
      broker,
      clientCode,
      apiKey,
      jwtToken,
      secretKey
    );
    if (fetchedFunds) {
      setFunds(fetchedFunds);
    } else {
      console.error("Failed to fetch funds.");
    }
  };

  useEffect(() => {
    // Call the function when the component mounts or when relevant props change
    if (broker && (clientCode || jwtToken)) {
      getAllFunds();
    }
  }, [broker, clientCode, apiKey, jwtToken, secretKey]);

  const [loading, setLoading] = useState(null);
  const handleSubscribe = (strategy) => {
    const isFundsEmpty =
      funds?.meesage === "Invalid Token" && funds?.success === false;
    if (broker === "Zerodha") {
      if (todayDate > expireTokenDate || isFundsEmpty) {
        setOpenTokenExpireModel(true);
      } else {
        setStrategyDetails(strategy);
        setOpenSubscribeModel(true);
        setFileName(strategy.model_name);
        setLoading(strategy.model_name);
      }
    } else {
      if (broker === "") {
        setBrokerModel(true);
      } else if (todayDate > expireTokenDate || isFundsEmpty) {
        setOpenTokenExpireModel(true);
      } else {
        setStrategyDetails(strategy);
        setOpenSubscribeModel(true);
        setFileName(strategy.model_name);
        setLoading(strategy.model_name);
      }
    }
  };

  const getStrategyDetails = () => {
    if (fileName !== null) {
      axios
        .get(
          `${
            server.server.baseUrl
          }api/model-portfolio/portfolios/strategy/${fileName?.replaceAll(
            /_/g,
            " "
          )}`
        )
        .then((res) => {
          const portfolioData = res.data;
          setStrategyDetails(portfolioData);
          if (
            portfolioData &&
            portfolioData.model &&
            portfolioData.model.rebalanceHistory.length > 0
          ) {
            const latest = portfolioData.model.rebalanceHistory.sort(
              (a, b) => new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
            )[0];
            setLatestRebalance(latest);
          }
          setLoading(null);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getStrategyDetails();
  }, [fileName]);

  return (
    <div className="max-h-[calc(100vh-60px)] md:max-h-[calc(100vh-100px)] lg:min-h-screen w-full items-center justify-center overflow-scroll custom-scroll bg-[#F9F9F9] ">
      <Toaster position="top-center" reverseOrder={true} />
      {isLoading ? (
        <div className="flex flex-col relative  w-full min-h-screen  bg-[#f9f9f9]">
          <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
            <svg
              className="h-10 w-10 text-[#000000] animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        <div>
          <div className="px-4  md:px-10 flex flex-row  justify-between border-b border-[#000000]/20  h-[50px] lg:h-[60px] font-poppins">
            <div className="flex flex-row space-x-6 lg:space-x-2">
              <div
                className={`
              ${style.selected} flex items-center justify-center`}
              >
                All Strategies
              </div>
            </div>
          </div>

          <div className="h-[calc(100vh-170px)] overflow-auto md:h-auto pb-[40px] md:pb-0 w-full">
            <div className="h-full overflow-auto custom-scrollbar md:overflow-auto w-full grid gap-y-4 pb-4 px-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 md:gap-y-6 md:gap-x-3 xl:grid-cols-3 lg:gap-4 lg:pt-6 lg:px-[50px] lg:pb-12">
              {Array.isArray(allStrategy) && allStrategy.length > 0 ? (
                allStrategy.map((ele, i) => {
                  const isSubscribed = ele?.subscribed_by?.includes(userEmail);
                  return (
                    <div
                      key={i}
                      className="relative font-poppins w-full rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4"
                    >
                      <div className="flex items-start gap-x-4 p-4">
                        <div className="overflow-hidden rounded-lg">
                          <img
                            src={
                              ele?.image
                                ? `${server.server.baseUrl}${ele.image}`
                                : Alpha100
                            }
                            alt={ele.model_name}
                            className="lg:h-[55px] md:w-[90px] md:h-[10px] sm:w-[30px] sm:h-[10px] object-cover"
                          />
                        </div>
                        <div>
                          <h2 className="text-lg font-semibold text-[#000000]">
                            {ele.model_name}
                          </h2>
                          <p className="mt-1 text-[10px] md:text-[10px] text-[#95989C] font-medium font-poppins text-left">
                            {ele?.overView.split(" ").slice(0, 10).join(" ")}
                          </p>
                        </div>
                      </div>

                      <div className="flex w-full mt-[6px] border-t-[1px] border-b-[1px] border-[#000000]/10">
                        <div className="flex items-center flex-col space-y-1 w-1/4 py-1.5 pl-5 lg:pl-5 border-r-[1px] border-[#000000]/10">
                          <div className="text-[10px] text-[#000000]/70 font-normal font-poppins capitalize">
                            CAGR
                          </div>
                          <p className="text-[14px] lg:text-[16px] text-[#16A05E] font-poppins font-semibold">
                            +75.2%
                          </p>
                        </div>

                        <div className="flex flex-col space-y-1 w-1/4 items-center py-1.5 lg:px-2 border-r-[1px] border-[#000000]/10">
                          <div className="text-[10px] text-[#000000]/70 font-normal font-poppins text-center">
                            2Y Returns
                          </div>
                          <p className="text-[14px] lg:text-[16px] text-[#16A05E] font-poppins font-semibold">
                            +775.2%
                          </p>
                        </div>

                        <div className="flex flex-col space-y-1 w-2/4 items-center py-1.5 lg:px-1 rounded-md">
                          <div className="text-[10px] text-[#000000]/70 font-normal font-poppins">
                            Minimum investment
                          </div>
                          <p className="text-[14px] lg:text-[16px] text-[#000000] font-poppins font-semibold">
                            ₹ {formatCurrency(ele.minInvestment)}
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-col items-start justify-between px-5 mt-4">
                        <div className="w-full group relative flex items-start justify-between text-base text-[#000000]/80 font-poppins font-semibold">
                          <div
                            className="flex items-center cursor-pointer"
                            onClick={() => handleCardClick(ele.model_name)}
                          >
                            <Info size={16} />
                            <p className="text-[14px] lg:text-[13px] px-2 lg:px-2 py-0 font-medium">
                              Monthly Fees: ₹2500
                            </p>
                          </div>

                          <div className="flex items-center px-3 py-[1px] rounded-md">
                            <Gauge size={16} className="text-red-500" />
                            <span className="text-[14px] lg:text-[13px] px-2 lg:px-2 py-0 font-medium">
                              Highly Risky
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="p-4">
                        <div className="flex space-x-1 sm:space-x-2 md:space-x-1 xxl:space-x-2 w-full">
                          <button
                            className="w-full py-2 px-1 rounded-md bg-white border-[#000000]/20 border-[2px] text-black text-xs sm:text-[15px] lg:text-xs xxl:text-[15px] font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear"
                            onClick={() => handleCardClick(ele.model_name)}
                          >
                            Performance
                          </button>
                          {isSubscribed ? (
                            <button
                              className="w-full flex items-center justify-center py-2 px-1 rounded-md bg-black text-white text-xs sm:text-[15px] lg:text-xs xxl:text-[15px] font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear"
                              onClick={() => handleCardClick(ele.model_name)}
                            >
                              View More
                            </button>
                          ) : (
                            <button
                              className="w-full flex items-center justify-center py-2 px-1 rounded-md bg-black text-white text-xs sm:text-[15px] lg:text-xs xxl:text-[15px] font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear"
                              onClick={() => handleSubscribe(ele)}
                            >
                              {loading === ele?.model_name &&
                              openSubscribeModel === true ? (
                                <LoadingSpinner />
                              ) : (
                                " Invest Now"
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="flex flex-col items-center justify-center space-y-6  w-full relative  bg-[#f9f9f9] h-full">
                  <div className="flex flex-row items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
                    <GitForkIcon size={50} className="w-[60px] lg:w-[80px]" />
                  </div>
                  <div className="flex flex-col  justify-center space-y-3 lg:space-y-5 items-center px-6 lg:w-[800px]">
                    <div className="text-black text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                      No Strategies Available Now
                    </div>
                    <div className="text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[30px] font-normal font-poppins px-[10px] lg:px-[60px]">
                      When your advisor create any strategy it appears here
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {openSubscribeModel === true && latestRebalance !== null ? (
        <UserStrategySubscribeModal
          setOpenSubscribeModel={setOpenSubscribeModel}
          userEmail={userEmail}
          getStrategyDetails={getAllStrategy}
          strategyDetails={strategyDetails}
          fileName={fileName}
          latestRebalance={latestRebalance}
          userDetails={userDetails}
          setOpenSucessModal={setOpenSucessModal}
          setOrderPlacementResponse={setOrderPlacementResponse}
          setBrokerModel={setBrokerModel}
          clientCode={clientCode}
          apiKey={apiKey}
          secretKey={secretKey}
          jwtToken={jwtToken}
          broker={broker}
        />
      ) : null}
      {openSuccessModal && (
        <RecommendationSuccessModal
          setOpenSucessModal={setOpenSucessModal}
          orderPlacementResponse={orderPlacementResponse}
          setStockDetails={setStrategyDetails}
          openSuccessModal={openSuccessModal}
        />
      )}
      {brokerModel === true ? (
        <ConnectBroker
          uid={userDetails && userDetails._id}
          userDetails={userDetails && userDetails}
          setBrokerModel={setBrokerModel}
          getUserDeatils={getUserDeatils}
          broker={broker}
          setBroker={setBroker}
          brokerModel={brokerModel}
        />
      ) : null}
      {openTokenExpireModel === true ? (
        <TokenExpireBrokarModal
          openTokenExpireModel={openTokenExpireModel}
          setOpenTokenExpireModel={setOpenTokenExpireModel}
          userId={userId && userId}
          apiKey={apiKey}
          secretKey={secretKey}
          checkValidApiAnSecret={checkValidApiAnSecret}
          clientCode={clientCode}
          my2pin={my2pin}
          panNumber={panNumber}
          mobileNumber={mobileNumber}
          broker={broker}
          getUserDeatils={getUserDeatils}
        />
      ) : null}
    </div>
  );
};

export default StrategyList;
