import React from "react";

import DashboardLayout from "../DashboardLayout";
import StrategyDetails from "./StrategyDetails";
import StrategyDetailsWithPortfolioData from "./StrategyDetailsWithPortfolioData";

const SingleStrategyDetailsPage = () => {
  return (
    <DashboardLayout>
      <StrategyDetails />
      {/* <StrategyDetailsWithPortfolioData /> */}
    </DashboardLayout>
  );
};

export default SingleStrategyDetailsPage;
