import React from 'react'
import DashboardLayout from "../DashboardLayout";
import PricingPage from "./PricingPage"

const Pricing = () => {
  return (

    <DashboardLayout>
<PricingPage/>

    </DashboardLayout>

  )
}

export default Pricing