import React, { useState, useEffect } from "react";
import axios from "axios";
import server from "../../utils/serverConfig";
import NestedTable from "./NestedTable";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { XIcon } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { CSVLink } from "react-csv";
import CSVIcon from "../../assests/csv_icon.svg";

const dummyDetails = [
  {
    _id: { email: "john.doe@example.com" },
    holding: {
      quantity: 150,
      avgPrice: 1200,
      pnl: 2500, // Unrealized Profit
    },
  },
  {
    _id: { email: "jane.smith@example.com" },
    holding: {
      quantity: 75,
      avgPrice: 1500,
      pnl: -300, // Unrealized Loss
    },
  },
  {
    _id: { email: "alice.johnson@example.com" },
    holding: {
      quantity: 200,
      avgPrice: 1800,
      pnl: 0, // No P&L
    },
  },
  {
    _id: { email: "michael.brown@example.com" },
    holding: {
      quantity: 50,
      avgPrice: 2200,
      pnl: 450, // Unrealized Profit
    },
  },
  {
    _id: { email: "emily.white@example.com" },
    holding: {
      quantity: 100,
      avgPrice: 1100,
      pnl: -150, // Unrealized Loss
    },
  },
  {
    _id: { email: "daniel.garcia@example.com" },
    holding: {
      quantity: 120,
      avgPrice: 1300,
      pnl: 600, // Unrealized Profit
    },
  },
  {
    _id: { email: "laura.martinez@example.com" },
    holding: {
      quantity: 180,
      avgPrice: 1400,
      pnl: 0, // No P&L
    },
  },
  {
    _id: { email: "samuel.taylor@example.com" },
    holding: {
      quantity: 90,
      avgPrice: 1600,
      pnl: -450, // Unrealized Loss
    },
  },
  {
    _id: { email: "sophia.wilson@example.com" },
    holding: {
      quantity: 210,
      avgPrice: 1700,
      pnl: 1200, // Unrealized Profit
    },
  },
  {
    _id: { email: "henry.moore@example.com" },
    holding: {
      quantity: 60,
      avgPrice: 1250,
      pnl: -200, // Unrealized Loss
    },
  },
];

// Use this data in your component where you map over 'details'

const HoldingsTable = ({
  data,
  groupedData,
  adminEmail,
  advisorName,
  email_password,
  loadingHoldingData,
}) => {
  const [openRow, setOpenRow] = useState(null);

  const toggleRow = (symbol) => {
    setOpenRow(openRow === symbol ? null : symbol);
  };

  const [openRecommendationModel, setOpenRecommendationModel] = useState(false);
  const [allUserDetails, setAllUserDeatils] = useState();
  const [selectSymbol, setSelectSymbol] = useState();
  const [selectUserEmail, setSelectEmail] = useState();

  const [loading, setLoading] = useState(false);

  const handleSendAdvice = () => {
    setLoading(true);

    const adviceData = allUserDetails.map((user) => ({
      email: user._id.email,
      trade_given_by: adminEmail,
      email_password: email_password,
      // trade_given_by_fileName: fileName,
      advisor_name: advisorName,
      Symbol: user.holding.symbol,
      Exchange: user.holding.exchange,
      Type: "SELL", // or "SELL", depending on your logic
      OrderType: "MARKET", // You might want to adjust this
      ProductType: "DELIVERY", // You might want to adjust this
      Segment: "EQUITY", // Assuming it's always equity
      Price: 0,
      date: moment(user.last_updated).format(),
      Quantity: user.Net_Traded_Quantity,
      // Advised_Range_Lower: user.holding.ltp * 0.98, // Example: 2% below current price
      // Advised_Range_Higher: user.holding.ltp * 1.02, // Example: 2% above current price
    }));

    let config = {
      method: "post",
      url: `${server.server.baseUrl}api/send-reco`,
      headers: {
        "Content-Type": "application/json",
      },
      data: adviceData,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        toast.success("Advice has been sent successfully", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        setOpenRecommendationModel(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in sending Advice !", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setLoading(false);
      });
  };

  const csvHeaders = [
    { label: "client_email", key: "client_email" },
    { label: "stock_name", key: "stock_name" },
    { label: "type", key: "type" },
    { label: "holding_qty", key: "holding_qty" },
    { label: "avg_holding_price", key: "avg_holding_price" },
    { label: "unrealized_pnl", key: "unrealized_pnl" },
  ];

  const formatData = (symbol, items, type = "Buy") => {
    return items.map((item) => ({
      client_email: item?._id.email,
      stock_name: symbol,
      type: type,
      holding_qty: item?.holding.quantity,
      avg_holding_price: item?.holding.avgPrice,
      unrealized_pnl: item?.holding.pnl ? item?.holding.pnl : 0,
    }));
  };

  // console.log("Grouped data", groupedData);

  return (
    <div className="bg-[#ffffff] w-full h-[calc(100vh-419px)] py-0 rounded-b-lg">
      {data && data.length > 0 ? (
        <div className="w-full h-[100%] bg-[#ffffff] overflow-auto custom-scroll rounded-b-lg">
          <table className="w-full">
            <thead className="bg-[#f5f5f5] z-20 text-sm text-left sticky top-0">
              <tr className="border-b-[1px] border-[#000000]/10">
                <th className="text-[13px] min-w-[100px] lg:w-auto text-[#000000]/80 font-poppins font-medium px-3 py-3 lg:py-3 lg:px-[50px]">
                  Stock Name
                </th>
                <th className="text-[13px] min-w-[120px] lg:w-auto text-[#000000]/80 text-center font-poppins font-medium px-3 py-3 lg:py-3 lg:px-5">
                  No. of accts
                </th>
                <th className="text-[13px] min-w-[150px] lg:w-auto text-[#000000]/80 text-center font-poppins font-medium px-3 py-3 lg:py-3 lg:px-5">
                  Type
                </th>
                <th className="text-[13px] min-w-[150px] lg:w-auto text-[#000000]/80 text-center font-poppins font-medium px-3 py-3 lg:py-3 lg:px-5">
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {Object.entries(groupedData).map(([symbol, items]) => (
                <React.Fragment key={symbol}>
                  <tr className="border-t-[1px] z-10 border-[#000000]/10">
                    <td className="text-[15px] text-[#000000] font-poppins font-medium text-left px-3 py-2 lg:py-3 lg:px-[50px]">
                      <span className="flex items-center">
                        {openRow === symbol ? (
                          <AiFillCaretDown
                            className="mr-2 h-4 w-4 cursor-pointer"
                            onClick={() => toggleRow(symbol)}
                          />
                        ) : (
                          <AiFillCaretRight
                            className="mr-2 h-4 w-4 cursor-pointer"
                            onClick={() => toggleRow(symbol)}
                          />
                        )}
                        {symbol}
                      </span>
                    </td>
                    <td className="text-[15px] text-[#000000] font-poppins  font-medium px-3 py-2 lg:py-3 lg:px-5">
                      <div className="flex items-center justify-center space-x-2">
                        <span className="flex items-center justify-center">
                          {items.length}
                        </span>
                        {items.length >= 10 && (
                          <CSVLink
                            data={formatData(symbol, items)}
                            headers={csvHeaders}
                            filename={`holding_list_${moment().format(
                              "YYYYMMDD_HHmmss"
                            )}.csv`}
                            className="group relative px-1   flex  text-white rounded-[4px] justify-center items-center cursor-pointer"
                          >
                            <img
                              src={CSVIcon}
                              alt="CSV File Icon"
                              width={15}
                              className=""
                            />
                          </CSVLink>
                        )}
                      </div>
                    </td>
                    <td className="text-[15px] text-[#16A085] font-poppins font-medium text-center px-3 py-2 lg:py-3 lg:px-5">
                      Buy
                    </td>
                    <td className="text-[15px]  text-[#000000] font-poppins font-medium text-center px-3 py-2 lg:py-3 lg:px-5">
                      <div
                        className="w-[60px] mx-auto border-[1px] rounded-[4px] py-[2px] border-[#000000]/20 cursor-pointer"
                        onClick={() => {
                          setOpenRecommendationModel(true);
                          setAllUserDeatils(items);
                          setSelectSymbol(symbol);
                        }}
                      >
                        Sell
                      </div>
                    </td>
                  </tr>
                  {openRow === symbol && (
                    <tr className="z-10 w-full">
                      <td colSpan={6} className="px-4 md:px-[44px] py-2">
                        <div className="border-[1px] border-[#000000]/10 w-full rounded-lg">
                          <NestedTable
                            details={items}
                            setOpenRecommendationModel={
                              setOpenRecommendationModel
                            }
                            setAllUserDeatils={setAllUserDeatils}
                            setSelectEmail={setSelectEmail}
                            symbol={symbol}
                            setSelectSymbol={setSelectSymbol}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className=" hidden lg:block w-full h-full max-h-[calc(100vh-360px)] lg:h-[calc(100vh-300px)] overflow-auto custom-scroll mb-4">
          <div className="pt-16 flex flex-col items-center justify-center">
            <div className="text-xl lg:text-[26px] font-semibold font-poppins flex items-center justify-center">
              No Holdings Yet
            </div>
            <p className="mt-4 w-[450px] text-center text-[14px] lg:text-[16px] font-normal text-[#000000]/60 font-sans flex items-center justify-center">
              Your clients haven't placed any orders yet.
            </p>
            <Link
              to="/admin/send-recommendation-status"
              className="mt-4 py-2 px-6 bg-black text-[18px] lg:text-lg  text-white font-medium rounded-md cursor-pointer"
            >
              View Sent Advice
            </Link>
          </div>
        </div>
      )}

      {openRecommendationModel === true ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 px-3 md:px-0 ">
          <div className="relative animate-modal">
            <div
              className="absolute right-1 top-2 md:top-4 md:right-2  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
              onClick={() => {
                setOpenRecommendationModel(false);
                setSelectEmail();
                setAllUserDeatils();
                setSelectSymbol();
              }}
            >
              <XIcon className="w-6 h-6 mr-2" />
            </div>
            <div className="w-full max-w-[520px] px-4 sm:px-12 py-8 sm:py-12 bg-white  border-[#000000]/20 rounded-md">
              {openRow ? (
                <div className="text-[22px] font-medium font-poppins text-center ">
                  Are you sure you want to send a sell recommendation for
                  <span className="ml-1 text-[22px] font-semibold font-poppins text-center capitalize">
                    {selectSymbol} stock{" "}
                  </span>
                  to client{" "}
                  <span className="ml-1 text-[22px] font-semibold font-poppins text-center">
                    {selectUserEmail}
                  </span>
                  ?
                </div>
              ) : (
                <div className="text-[15px] md:text-[22px] font-medium font-poppins text-center ">
                  Are you sure you want to send a sell recommendation for
                  <span className="ml-1 text-[15px] md:text-[22px] font-semibold font-poppins text-center capitalize">
                    {selectSymbol} stock{" "}
                  </span>
                  to all clients?
                </div>
              )}

              <div className="mt-6 text-[18px] font-normal font-poppins text-center ">
                <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                  <div
                    onClick={() => {
                      setOpenRecommendationModel(false);
                      setSelectEmail();
                      setAllUserDeatils();
                      setSelectSymbol();
                    }}
                    className=" bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-base sm:text-[18px] font-medium font-poppins rounded-md px-4 sm:px-10 py-3 hover:scale-[1.04] transition-all duration-150 ease-linear cursor-pointer"
                  >
                    Cancel
                  </div>
                  <div
                    onClick={handleSendAdvice}
                    className=" bg-[#ff0000] text-white text-base sm:text-[18px] font-medium font-poppins rounded-md px-4 sm:px-10 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
                  >
                    {loading === true ? (
                      <span className="flex flex-row justify-center">
                        <svg
                          className="h-7 w-7 text-[#ffffff] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      " Send Advice"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HoldingsTable;
