import React from "react";

const HoldingCard = ({ qty, avgPrice, symbol, pnl, totalInvested, ltp }) => {
  return (
    <div className="flex flex-col space-y-2 w-full bg-white  border-b-[1px] border-[#000000]/10 py-3 px-6">
      <div className="flex justify-between">
        <div className="flex space-x-[10px]">
          <div className="flex items-end space-x-1.5">
            <div className="text-[14px] font-light font-poppins text-[#000000]/70">
              Qty.
            </div>
            <span className="text-[15px] font-medium font-poppins text-[#000000]/80">
              {qty}
            </span>
          </div>
          <span className="text-[16px] font-bold">.</span>
          <div className="flex items-end space-x-1.5">
            <div className="text-[14px] font-light font-poppins text-[#000000]/70">
              Avg.
            </div>
            <span className="text-[15px] font-medium font-poppins text-[#000000]/80">
              ₹{avgPrice}
            </span>
          </div>
        </div>

        <div
          className={`text-[15px] font-medium font-poppins tracking-wider ${
            pnl > 0
              ? "text-[#16A085]"
              : pnl < 0
              ? "text-[#E43D3D]"
              : "text-[#000000]/80"
          }`}
        >
          {pnl > 0
            ? `${Math.abs((pnl / totalInvested) * 100).toFixed(2)}%`
            : pnl < 0
            ? `-${Math.abs((pnl / totalInvested) * 100).toFixed(2)}%`
            : `-`}
        </div>
      </div>

      <div className="flex justify-between">
        <div className="text-[15px] font-medium font-poppins text-[#000000]/80">
          {symbol}
        </div>
        <div
          className={`text-[15px] font-medium font-poppins tracking-wider ${
            pnl > 0
              ? "text-[#16A085]"
              : pnl < 0
              ? "text-[#E43D3D]"
              : "text-[#000000]/80"
          }`}
        >
          {pnl > 0
            ? `+₹ ${pnl.toFixed(2)}`
            : pnl < 0
            ? `-₹ ${Math.abs(pnl.toFixed(2))}`
            : "0.00"}
        </div>
      </div>

      <div className="flex justify-between">
        <div className="flex items-end space-x-1.5">
          <div className="text-[14px] font-light font-poppins text-[#000000]/70">
            Invested
          </div>
          <span className="text-[15px] font-medium font-poppins text-[#000000]/80">
            ₹{totalInvested.toFixed(2)}
          </span>
        </div>
        <div className="flex space-x-1.5">
          <div className="text-[14px] font-light font-poppins text-[#000000]/70">
            LTP
          </div>
          <span className="text-[15px] font-medium font-poppins text-[#000000]/80">
            ₹{ltp.toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default HoldingCard;
