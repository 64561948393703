import React, { useState, useEffect } from "react";
import { CircleCheck } from "lucide-react";
import Alpha100 from "../../assests/alpha-100.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import server from "../../utils/serverConfig";
import { GitForkIcon, Info, Gauge } from "lucide-react";

const style = {
  selected:
    "flex items-center text-[18px]  font-sans text-black font-bold lg:leading-[42px]",
  unselected:
    "flex items-center text-[18px]  font-sans font-medium text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};
const PricingPage = () => {
  const navigate = useNavigate();
  const [allStrategy, setAllStrategy] = useState([]);

  const getAllStrategy = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/model-portfolio/portfolios/AlphaQuarkTest`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("res", response);

        setAllStrategy(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllStrategy();
  }, []);

  const handleCardClick = (modelName) => {
    navigate(`/strategy/${modelName.toLowerCase().replace(/ /g, "_")}`);
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (

    <div className="flex flex-col relative w-full min-h-screen bg-[#f9f9f9]">

      <div className="px-5 lg:px-14 flex flex-row justify-center border-b border-[#000000]/20 h-[50px] lg:h-[60px] font-poppins ">
        <div className="flex flex-row space-x-6 lg:space-x-8">

          <button className="text-center pb-2 border-b-4 border-transparent hover:border-black hover:text-black-500">
            Monthly
          </button>

          <button className="text-center pb-2 border-b-4 border-transparent hover:border-black hover:text-black-500">
            Quarterly
          </button>
          <button className="text-center pb-2 border-b-4 border-transparent hover:border-black hover:text-black-500">
            Half-yearly
          </button>
          <button className="text-center pb-2 border-b-4 border-transparent hover:border-black hover:text-black-500">
            Yearly
          </button>
        </div>
      </div>

      {/* Main Content Section */}

      <div >
        <div className="text-center mb-3 mt-8">
          <h1 className="text-2xl font-bold text-black">Bespoke Pricing Plans</h1>
        </div>
        <div className="grid grid-rows-1 grid-cols-1 sm:grid-cols-1 md:grid-cols-3 pt-4 pb-[20px] sm:pl-4 md:pl-12 md:pr-10 ">

          <div className="relative font-poppins w-[95%] h-[98%] rounded-xl  bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4 ">
            <div className="flex items-start pl-8 p-4 md:items-center">
              <div className="overflow-hidden rounded-lg">
                <h2 className=" text-2xl font-semibold text-[#000000]">STARTER</h2>

                <div className='flex items-end gap-2'>
                  <h2 className="text-3xl font-semibold text-[#000000]">₹499</h2>

                  <p className="mt-1 text-[15px] text-[#95989C] font-lg text-left">
                    /month
                  </p>
                </div>
              </div>
              <div>

              </div>
            </div>

            <div className="flex flex-col items-center p-4 space-y-4 w-90 mt-[6px]">
              <div className='w-full border-t-[1px] border-b-[1px] border-[#000000]/10'></div>
              <div className="flex flex-row text-xs space-x-1  text-gray-600 font-medium">
                <CircleCheck size={16}  className="text-white fill-green-700" /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>
              <div className="flex flex-row text-xs space-x-1 text-gray-600 font-medium">
                <CircleCheck size={16} className="text-white fill-green-700"  /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>

              <div className="flex flex-row text-xs space-x-1 text-gray-600 font-medium">
                <CircleCheck size={16} className="text-white fill-green-700"  /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>

              <div className="flex flex-row text-xs space-x-1 text-gray-600 font-medium">
                <CircleCheck size={16} className="text-white fill-green-700"  /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>

              <div className="flex flex-row text-xs space-x-1 text-gray-600 font-medium">
                <CircleCheck size={16} className="text-white fill-green-700"  /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>
            </div>


            <div className="p-4">
              <div className="flex justify-center">
                <button className="w-[18rem] h-10 py-2 px-1 rounded-md bg-white border-black border-[2px] text-black text-xs font-medium hover:bg-black hover:text-white hover:border-black transition-all duration-150 ease-linear">
                  Get Started
                </button>
              </div>
            </div>
          </div>
          {/* CARD 2  */}

          <div className="relative font-poppins w-[95%] h-[98%]   rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4 ">
            <div className="flex items-start pl-8 p-4">
              <div className="overflow-hidden rounded-lg">
                <h2 className=" text-2xl font-semibold text-[#000000]">PRO</h2>

                <div className='flex items-end gap-2'>
                  <h2 className="text-3xl font-semibold text-[#000000]">₹999</h2>

                  <p className="mt-1 text-[15px] text-[#95989C] font-lg text-left">
                    /month
                  </p>
                </div>
              </div>
              <div>

              </div>
            </div>

            <div className="flex flex-col items-center p-4 space-y-4 w-full mt-[6px]">
              <div className='w-full border-t-[1px] border-b-[1px] border-[#000000]/10'></div>
              <div className="flex flex-row text-xs space-x-1  text-gray-600 font-medium">
                <CircleCheck size={16} className="text-white fill-green-700"  /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>
              <div className="flex flex-row text-xs space-x-1 text-gray-600 font-medium">
                <CircleCheck size={16} className="text-white fill-green-700"  /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>

              <div className="flex flex-row text-xs space-x-1 text-gray-600 font-medium">
                <CircleCheck size={16} className="text-white fill-green-700"  /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>

              <div className="flex flex-row text-xs space-x-1 text-gray-600 font-medium">
                <CircleCheck size={16} className="text-white fill-green-700"  /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>

              <div className="flex flex-row text-xs space-x-1 text-gray-600 font-medium">
                <CircleCheck size={16} className="text-white fill-green-700"  /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>
            </div>


            <div className="p-4">
              <div className="flex justify-center">
                <button className="w-[18rem] h-10 py-2 px-1 rounded-md bg-white border-black border-[2px] text-black text-xs font-medium hover:bg-black hover:text-white hover:border-black transition-all duration-150 ease-linear">
                  Get Started
                </button>
              </div>
            </div>
          </div>

          {/* card 3 */}
          <div className="relative font-poppins w-[95%] h-[98%]   rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4 ">
            <div className="flex items-start pl-8 p-4">
              <div className="overflow-hidden rounded-lg">
                <h2 className=" text-2xl font-semibold text-[#000000]">PLATINUM</h2>

                <div className='flex items-end gap-2'>
                  <h2 className="text-3xl font-semibold text-[#000000]">₹19,999</h2>

                  <p className="mt-1 text-[15px] text-[#95989C] font-lg text-left">
                    /month
                  </p>
                </div>
              </div>
              <div>

              </div>
            </div>

            <div className="flex flex-col items-center p-4 space-y-4 w-full mt-[6px]">
              <div className='w-full border-t-[1px] border-b-[1px] border-[#000000]/10'></div>
              <div className="flex flex-row text-xs space-x-1  text-gray-600 font-medium">
                <CircleCheck size={16} className="text-white fill-green-700" /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>
              <div className="flex flex-row text-xs space-x-1 text-gray-600 font-medium">
                <CircleCheck size={16} className="text-white fill-green-700" /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>

              <div className="flex flex-row text-xs space-x-1 text-gray-600 font-medium">
                <CircleCheck size={16} className="text-white fill-green-700" /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>

              <div className="flex flex-row text-xs space-x-1 text-gray-600 font-medium">
                <CircleCheck size={16} className="text-white fill-green-700" /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>

              <div className="flex flex-row text-xs space-x-1 text-gray-600 font-medium">
                <CircleCheck size={16} className="text-white fill-green-700" /> <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>
            </div>


            <div className="p-4">
              <div className="flex justify-center">
                <button className="w-[18rem] h-10 py-2 px-1 rounded-md bg-white border-black border-[2px] text-black text-xs font-medium hover:bg-black hover:text-white hover:border-black transition-all duration-150 ease-linear">
                  Get Started
                </button>
              </div>
            </div>
          </div>

        </div>
        </div>


      <div className="flex items-center justify-center my-3">
        <div className="w-[25%] bg-gray-200 h-[1px]"></div>
        <h1 className="mx-7 text-2xl font-bold text-black">Model Portfolio Pricing Plans</h1>
        <div className="w-[25%] bg-gray-200 h-[1px]"></div>
      </div>


      <div className="grid grid-rows-1 overflow-hidden sm:overflow-hidden md:overflow-hidden lg:overflow-hidden ">

        <div className="max-h-[calc(100vh-60px)] md:max-h-[calc(100vh-100px)] lg:min-h-screen w-full items-center justify-center overflow-scroll custom-scroll bg-[#F9F9F9] ">
          {isLoading ? (
            <div className="flex flex-col relative  w-full min-h-screen  bg-[#f9f9f9]">
              <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
                <svg
                  className="h-10 w-10 text-[#000000] animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            </div>
          ) : (
            <div>
              <div >
                <div className="h-full overflow-hidden sm:overflow-hidden md:overflow-auto w-full grid gap-y-4 pb-4 px-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 md:gap-y-6 md:gap-x-3 xl:grid-cols-3 lg:gap-4 lg:pt-6 lg:px-[50px] lg:pb-12">
                  {allStrategy.length !== 0 ? (
                    allStrategy.map((ele, i) => (
                      <div
                        className={`relative font-poppins w-full rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)]  py-4`}

                      // key={i}
                      //   onClick={() => handleCardClick(ele.model_name)}
                      >
                        <div className="flex items-start gap-x-4 p-4">
                          <div className="overflow-hidden rounded-lg">
                            <img
                              src={
                                ele?.image
                                  ? `${server.server.baseUrl}${ele.image}`
                                  : Alpha100
                              }
                              alt={ele.model_name}
                              className=" lg:h-[55px] md:w-[90px] md:h-[10px] sm:w-[30px] sm:h-[10px]  object-cover "
                            />
                          </div>
                          <div>
                            <h2 className="text-lg font-semibold text-[#000000]">
                              {ele.model_name}
                            </h2>
                            <p className=" mt-1 text-[10px] md:text-[10px] text-[#95989C] font-medium font-poppins text-left ">
                              {/* {ele.overView.split(" ").slice(0, 10).join(" ")} */}
                              We're registered research analyst the creating
                              Thematic & Quantamental the...
                            </p>
                          </div>
                        </div>

                        <div className="flex  w-full mt-[6px] border-t-[1px] border-b-[1px] border-[#000000]/10">
                          <div className="flex items-center flex-col  space-y-1 w-1/4 py-1.5 pl-5 lg:pl-5 border-r-[1px] border-[#000000]/10">
                            <div className="text-[10px] text-[#000000]/70 font-normal font-poppins capitalize">
                              CAGR
                            </div>
                            <p className="text-[14px] lg:text-[16px] text-[#16A05E] font-poppins font-semibold ">
                              +75.2%
                            </p>
                          </div>

                          <div className="flex flex-col space-y-1 w-1/4 items-center py-1.5 lg:px-2 border-r-[1px] border-[#000000]/10">
                            <div className="text-[10px] text-[#000000]/70 font-normal font-poppins text-center">
                              2Y Returns
                            </div>
                            <p className="text-[14px] lg:text-[16px] text-[#16A05E] font-poppins font-semibold ">
                              +775.2%
                            </p>
                          </div>

                          <div className="flex flex-col space-y-1 w-2/4 items-center py-1.5 lg:px-1 rounded-md">
                            <div className="text-[10px] text-[#000000]/70 font-normal font-poppins">
                              Minimum investment
                            </div>
                            <p className="text-[14px] lg:text-[16px]  text-[#000000] font-poppins font-semibold ">
                              ₹1,29,500
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-col items-start justify-between px-5 mt-4">
                          <div className="w-full group relative flex items-start justify-between text-base text-[#000000]/80 font-poppins font-semibold">
                            <div
                              className="flex items-center cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCardClick(ele.model_name);
                              }}
                            >
                              <Info size={16} />
                              <p className="text-[14px] lg:text-[13px] px-2 lg:px-2 py-0 font-medium">
                                Monthly Fees: ₹250
                              </p>
                            </div>

                            <div className="flex items-center px-3 py-[1px] rounded-md">
                              <Gauge size={16} className="text-red-500" />
                              <span className="text-[14px] lg:text-[13px] px-2 lg:px-2 py-0 font-medium ">
                                Highly Risky
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="p-4">
                          <div className="flex space-x-1 sm:space-x-2 md:space-x-1 xxl:space-x-2 w-full">
                            <button
                              className="w-full py-2 px-1 rounded-md bg-white border-[#000000]/20 border-[2px] text-black text-xs sm:text-[15px] lg:text-xs xxl:text-[15px] font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear"
                              key={i}
                              onClick={() => handleCardClick(ele.model_name)}
                            >
                              Performance
                            </button>

                            <button className="w-full flex items-center justify-center py-2 px-1 rounded-md bg-black text-white text-xs sm:text-[15px] lg:text-xs xxl:text-[15px] font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear">
                              Invest Now
                            </button>
                          </div>
                        </div>
                      </div>

                    ))

                  ) : (

                    <div className="flex flex-col items-center justify-center space-y-6  w-full relative  bg-[#f9f9f9] h-full">
                      <div className="flex flex-row items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
                        <GitForkIcon size={50} className="w-[60px] lg:w-[80px]" />
                      </div>
                      <div className="flex flex-col space-y-3 lg:space-y-5 items-center px-6 lg:w-[800px]">
                        <div className="text-black text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                          No Strategies Available Now
                        </div>
                        <div className="text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[30px] font-normal font-poppins px-[10px] lg:px-[60px]">
                          When your advisor create any strategy it appears here
                        </div>
                      </div>
                    </div>


                  )}


                </div>

              </div>

            </div>
          )}
        </div>

      </div>
    </div>




  )
}

export default PricingPage