import React, { useEffect, useState } from "react";
import { UserIcon, XIcon, ChevronDown, ChevronUp } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import server from "../utils/serverConfig";
import axios from "axios";
import TelegramSteps from "./UserHomePage/TelegramSteps";
import { CountryCode } from "../utils/CountryCode";
import { useMediaQuery } from "../hooks/use-media-query";
import { Dialog, DialogContent, DialogFooter } from "../components/ui/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
} from "../components/ui/drawer";
import LoadingSpinner from "../components/LoadingSpinner";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-normal",
  inputBox:
    "w-full px-[18px] lg:px-6 py-2.5  bg-white text-[14px] lg:text-lg  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-1 hover:ring-2 ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-normal peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full   ",
  selectDiv2:
    "flex items-center px-2 py-2  rounded-lg mx-3 md-3 text-black-500  hover:bg-black/10 hover:text-black-500  ease-linear duration-150 cursor-pointer",
};

const UserProfileModal = ({
  showUserProfileModal,
  setShowUserProfileModal,
  getUserDeatils,
  userDetails,
}) => {
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userTelegram, setUserTelegram] = useState("");

  const [profileCompletion, setProfileCompletion] = useState(0);
  const circumference = 2 * Math.PI * 35; // Adjust radius as needed

  const [loading, setLoading] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  useEffect(() => {
    if (userDetails.email !== "" && userDetails.email !== null) {
      setUserEmail(userDetails.email);
    }

    if (userDetails.name !== "" && userDetails.name !== null) {
      setUserName(userDetails.name);
    }

    if (
      userDetails?.phone_number !== "" &&
      userDetails?.phone_number !== 0 &&
      userDetails?.phone_number !== null
    ) {
      setUserPhoneNumber(userDetails?.phone_number?.toString());
    }
    if (userDetails.telegram_id !== "" && userDetails.telegram_id !== null) {
      setUserTelegram(userDetails.telegram_id);
    }

    if (
      userDetails.profile_completion !== "" &&
      userDetails.profile_completion !== null
    ) {
      setProfileCompletion(userDetails.profile_completion);
    }
  }, []);

  const handleUserProfile = () => {
    var phoneNumber = countryCode + userPhoneNumber;
    setLoading(true);
    if (userPhoneNumber.trim() === "") {
      toast.error("Please enter a phone number.", {
        duration: 5000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      setLoading(false);
    } else if (userPhoneNumber.length < 10) {
      toast.error("Phone number must be at least 10 numbers.", {
        duration: 5000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      setLoading(false);
    } else {
      let data = JSON.stringify({
        uid: userDetails && userDetails._id,
        phoneNumber: phoneNumber,
        telegramId: userTelegram,
        profileCompletion:
          phoneNumber && userTelegram
            ? 100
            : phoneNumber
            ? 75
            : userTelegram
            ? 75
            : 0,
        userName: userName,
        advisor: "AlphaQuarkTest",
      });
      let config = {
        method: "put",
        url: `${server.server.baseUrl}api/user/update/user-details`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          setLoading(false);
          getUserDeatils();
          setShowSuccessMsg(true);
          toast.success("Your profile has been successfully Completed.", {
            duration: 5000,
            style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (showSuccessMsg) {
      const toRef = setTimeout(() => {
        setShowSuccessMsg(false);
        setShowUserProfileModal(false);
      }, 4000);

      return () => clearTimeout(toRef); // Clear timeout on cleanup
    }
  }, [showSuccessMsg]);

  const profileCompletion2 =
    userDetails && typeof userDetails.profile_completion === "number"
      ? userDetails.profile_completion
      : 0;

  const strokeDashoffset =
    circumference - (profileCompletion2 / 100) * circumference;

  const [countryCode, setCountryCode] = useState("+91");
  const [showCountryCode, setShowCountryCode] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  var initials;
  var fullName = userDetails && userDetails?.name;
  initials =
    fullName &&
    fullName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();

  // Filter the country codes based on the search query
  const filteredCountryCodes = CountryCode.filter((ele) =>
    `${ele.value} ${ele.label}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const isDesktop = useMediaQuery("(min-width: 830px)");

  if (isDesktop) {
    return (
      <Dialog
        open={showUserProfileModal}
        onOpenChange={setShowUserProfileModal}
      >
        <DialogContent className=" min-w-[90vw] flex lg:h-[90vh] justify-center lg:px-[40px] lg:py-12 select-none">
          <Toaster position="top-center" reverseOrder={true} />

          <div className="w-full lg:w-[50%]">
            <TelegramSteps />
          </div>
          <div className=" w-full lg:w-[50%] ">
            <div className=" lg:px-10 flex items-center lg:space-x-4">
              <div className="relative flex items-center justify-center rounded-full ">
                <svg className="transform -rotate-90 w-[90px] h-[90px]">
                  <circle
                    cx="45"
                    cy="45"
                    r="35"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="transparent"
                    className="text-gray-200"
                  />
                  <circle
                    cx="45"
                    cy="45"
                    r="35"
                    stroke="currentColor"
                    strokeWidth="6"
                    fill="transparent"
                    className="text-[#16A085]"
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                  />
                </svg>
                <div className="absolute flex items-center justify-center rounded-full ">
                  {userDetails?.image_url ? (
                    <img
                      src={userDetails?.image_url}
                      alt="Profile Image"
                      width={60}
                      className="rounded-full"
                    />
                  ) : (
                    <div className="text-black font-semibold font-poppins text-[26px] ">
                      {initials || userDetails?.email.slice(0, 2).toUpperCase()}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <h3 className="text-[15px] lg:text-[18px] leading-[20px] text-[#000000] font-semibold font-sans">
                  {profileCompletion}% Profile Completed
                </h3>
                <p className="text-[12px] text-[#000000]/50 leading-[16px] font-normal font-poppins w-[200px]">
                  Please provide the below info for any trade issue.
                </p>
              </div>
            </div>

            <div className="px-6 lg:px-12 text-[18px] font-normal font-poppins text-center ">
              <div className="grid lg:grid-cols-1 gap-x-4 gap-y-2">
                <div className={style.inputStartDiv}>
                  <input
                    id="userEmail"
                    type="text"
                    placeholder="Your Email Id"
                    className={`${style.inputBox}`}
                    // className="mt-4 w-full py-3 px-6 text-lg border border-[#000000]/20 rounded-md"
                    value={userEmail}
                    disabled={profileCompletion >= 25}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                  <label
                    htmlFor="userEmail"
                    className={`${style.firstHeading} ${style.labelFloat}`}
                  >
                    Email Id
                    {/* <span className="text-[14px] text-red-500 ml-1">*</span>   */}
                  </label>
                </div>

                <div className={style.inputStartDiv}>
                  <input
                    id="userName"
                    type="text"
                    placeholder="Enter Client Name"
                    className={`${style.inputBox} `}
                    // className="mt-4 w-full py-3 px-6 text-lg border border-[#000000]/20 rounded-md"
                    value={userName}
                    // disabled={profileCompletion >= 50}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  <label
                    htmlFor="userName"
                    className={`${style.firstHeading} ${style.labelFloat}`}
                  >
                    User Name
                    {/* <span className="text-[14px] text-red-500 ml-1">*</span>  */}
                  </label>
                </div>

                <div
                  className={`flex flex-row items-center ${style.inputStartDiv} `}
                >
                  <div
                    className={`mt-3 mr-2 w-[20%] text-[#000000]/80 flex flex-row items-center justify-center relative cursor-pointer ring-1 hover:ring-2 ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9] rounded-[6px] py-2.5`}
                    onClick={() => setShowCountryCode(!showCountryCode)}
                  >
                    <div className="text-[#000000]/80">{countryCode}</div>

                    <div
                      className={`pl-1 ease-linear duration-150 text-[#000000]/25`}
                    >
                      {showCountryCode ? (
                        <ChevronUp size={20} />
                      ) : (
                        <ChevronDown size={20} />
                      )}
                    </div>
                  </div>
                  {showCountryCode && (
                    <div className="absolute right-0 top-14 bg-white flex flex-col rounded-lg w-[100%] pt-2 pb-2 z-10 box-border border-2 border-solid border-[ rgba(185, 192, 187, 0.7)] max-h-72 overflow-y-auto">
                      <input
                        type="text"
                        placeholder="Search..."
                        className="mx-3 px-3 py-2 mb-2 border rounded-md"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      {filteredCountryCodes.length > 0 ? (
                        filteredCountryCodes.map((ele, index) => (
                          <div
                            key={index}
                            className={`pl-3 font-medium ${style.selectDiv2}`}
                            onClick={() => {
                              setCountryCode(ele.value);
                              setShowCountryCode(false);
                            }}
                          >
                            {ele.value} {ele.label}
                          </div>
                        ))
                      ) : (
                        <div className="pl-3 font-medium text-gray-500">
                          No results found
                        </div>
                      )}
                    </div>
                  )}
                  <div className={`${style.inputStartDiv}`}>
                    <input
                      id="userPhoneNumber"
                      type="number"
                      placeholder="Your Phone Number"
                      className={`${style.inputBox}`}
                      // className="mt-4 w-full py-3 px-6 text-lg border border-[#000000]/20 rounded-md"
                      value={
                        userPhoneNumber && userPhoneNumber.startsWith("91")
                          ? userPhoneNumber && userPhoneNumber.substring(2)
                          : userPhoneNumber
                      }
                      onChange={(e) => setUserPhoneNumber(e.target.value)}
                    />
                    <label
                      htmlFor="userPhoneNumber"
                      className={`${style.firstHeading} ${style.labelFloat}`}
                    >
                      Phone Number
                      {/* <span className="text-[14px] text-red-500 ml-1">*</span> */}
                    </label>
                  </div>
                  {/* 
                <span className="absolute right-3 top-6 text-[14px] underline font-medium text-blue-500 font-poppins cursor-pointer">
                  Send OTP
                </span>
                */}
                </div>

                <div className={`${style.inputStartDiv} `}>
                  <input
                    id="userTelegram"
                    type="text"
                    placeholder="Your Telegram Username"
                    className={`${style.inputBox}`}
                    // className="mt-4 w-full py-3 px-6 text-lg border border-[#000000]/20 rounded-md"
                    value={userTelegram}
                    onChange={(e) => setUserTelegram(e.target.value)}
                  />
                  <label
                    htmlFor="userTelegram"
                    className={`${style.firstHeading} ${style.labelFloat}`}
                  >
                    Telegram Username (Optional)
                    {/* <span className="text-[14px] text-red-500 ml-1">*</span> */}
                  </label>
                  {/* 
                  <span className="absolute right-3 top-6 text-[14px] underline font-medium text-blue-500 font-poppins cursor-pointer">
                    Send OTP
                  </span>
                */}
                </div>
              </div>

              <button
                onClick={handleUserProfile}
                className="w-full flex items-center justify-center mt-6 bg-black disabled:cursor-not-allowed disabled:bg-[#000000]/50 text-white text-[18px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
              >
                {loading === true ? (
                  <LoadingSpinner />
                ) : (
                  " Save your Profile Data"
                )}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={showUserProfileModal} onOpenChange={setShowUserProfileModal}>
      <DrawerContent className="focus:outline-none max-h-[540px]">
        <div
          onClick={() => setShowUserProfileModal(false)}
          className="absolute top-2 right-3 flex items-center justify-center "
        >
          <XIcon className="h-5 w-5 text-gray-900" />
        </div>

        <div className="p-4 h-full flex flex-col overflow-y-auto">
          <Toaster position="top-center" reverseOrder={true} />

          <div className="w-full ">
            <TelegramSteps />
          </div>
          <div className=" w-full ">
            <div className=" px-0 lg:px-4 flex items-center lg:space-x-2">
              <div className="relative flex items-center justify-center rounded-full scale-[0.85]">
                <svg className="transform -rotate-90 w-[90px] h-[90px]">
                  <circle
                    cx="45"
                    cy="45"
                    r="35"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="transparent"
                    className="text-gray-200"
                  />
                  <circle
                    cx="45"
                    cy="45"
                    r="35"
                    stroke="currentColor"
                    strokeWidth="6"
                    fill="transparent"
                    className="text-[#16A085]"
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                  />
                </svg>
                <div className="absolute flex items-center justify-center rounded-full ">
                  {userDetails?.image_url ? (
                    <img
                      src={userDetails?.image_url}
                      alt="Profile Image"
                      width={60}
                      className="rounded-full"
                    />
                  ) : (
                    <div className="text-black font-semibold font-poppins text-[26px] ">
                      {initials || userDetails?.email.slice(0, 2).toUpperCase()}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <h3 className="text-[15px] lg:text-[18px] leading-[20px] text-[#000000] font-semibold font-sans">
                  {profileCompletion}% Profile Completed
                </h3>
                <p className="text-[12px] text-[#000000]/50 leading-[16px] font-normal font-poppins w-[200px]">
                  Please provide the below info for any trade issue.
                </p>
              </div>
            </div>

            <div className="px-2 lg:px-12 text-[18px] font-normal font-poppins text-center ">
              <div className="grid lg:grid-cols-1 gap-x-4 gap-y-2">
                <div className={style.inputStartDiv}>
                  <input
                    id="userEmail"
                    type="text"
                    placeholder="Your Email Id"
                    className={`${style.inputBox}`}
                    // className="mt-4 w-full py-3 px-6 text-lg border border-[#000000]/20 rounded-md"
                    value={userEmail}
                    disabled={profileCompletion >= 25}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                  <label
                    htmlFor="userEmail"
                    className={`${style.firstHeading} ${style.labelFloat}`}
                  >
                    Email Id
                    {/* <span className="text-[14px] text-red-500 ml-1">*</span>   */}
                  </label>
                </div>

                <div className={style.inputStartDiv}>
                  <input
                    id="userName"
                    type="text"
                    placeholder="Enter Client Name"
                    className={`${style.inputBox}`}
                    // className="mt-4 w-full py-3 px-6 text-lg border border-[#000000]/20 rounded-md"
                    value={userName}
                    disabled={profileCompletion >= 50}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  <label
                    htmlFor="userName"
                    className={`${style.firstHeading} ${style.labelFloat}`}
                  >
                    User Name
                    {/* <span className="text-[14px] text-red-500 ml-1">*</span>  */}
                  </label>
                </div>

                <div
                  className={`flex flex-row items-center ${style.inputStartDiv} `}
                >
                  <div
                    className={`mt-3 mr-2 w-[20%] text-[#000000]/80 text-[14px] lg:text-[18px] flex flex-row items-center justify-center relative cursor-pointer ring-1 hover:ring-2 ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9] rounded-[6px] py-2.5`}
                    onClick={() => setShowCountryCode(!showCountryCode)}
                  >
                    <div className="text-[#000000]/80">{countryCode}</div>

                    <div
                      className={`pl-1 ease-linear duration-150 text-[#000000]/25`}
                    >
                      {showCountryCode ? (
                        <ChevronUp size={20} />
                      ) : (
                        <ChevronDown size={20} />
                      )}
                    </div>
                  </div>
                  {showCountryCode && (
                    <div className="absolute right-0 top-14 bg-white flex flex-col rounded-lg w-[100%] pt-2 pb-2 z-10 box-border border-2 border-solid border-[ rgba(185, 192, 187, 0.7)] max-h-72 overflow-y-auto">
                      <input
                        type="text"
                        placeholder="Search..."
                        className="mx-3 px-3 py-2 mb-2 border rounded-md"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      {filteredCountryCodes.length > 0 ? (
                        filteredCountryCodes.map((ele, index) => (
                          <div
                            key={index}
                            className={`pl-3 font-medium ${style.selectDiv2}`}
                            onClick={() => {
                              setCountryCode(ele.value);
                              setShowCountryCode(false);
                            }}
                          >
                            {ele.value} {ele.label}
                          </div>
                        ))
                      ) : (
                        <div className="pl-3 font-medium text-gray-500">
                          No results found
                        </div>
                      )}
                    </div>
                  )}
                  <div className={`${style.inputStartDiv}`}>
                    <input
                      id="userPhoneNumber"
                      type="number"
                      placeholder="Your Phone Number"
                      className={`${style.inputBox}`}
                      // className="mt-4 w-full py-3 px-6 text-lg border border-[#000000]/20 rounded-md"
                      value={
                        userPhoneNumber && userPhoneNumber.startsWith("91")
                          ? userPhoneNumber && userPhoneNumber.substring(2)
                          : userPhoneNumber
                      }
                      onChange={(e) => setUserPhoneNumber(e.target.value)}
                    />
                    <label
                      htmlFor="clienuserPhoneNumbertEmail"
                      className={`${style.firstHeading} ${style.labelFloat}`}
                    >
                      Phone Number
                      {/* <span className="text-[14px] text-red-500 ml-1">*</span> */}
                    </label>
                  </div>
                  {/* 
                <span className="absolute right-3 top-6 text-[14px] underline font-medium text-blue-500 font-poppins cursor-pointer">
                  Send OTP
                </span>
                */}
                </div>

                <div className={`${style.inputStartDiv} `}>
                  <input
                    id="userTelegram"
                    type="text"
                    placeholder="Your Telegram Username"
                    className={`${style.inputBox}`}
                    // className="mt-4 w-full py-3 px-6 text-lg border border-[#000000]/20 rounded-md"
                    value={userTelegram}
                    onChange={(e) => setUserTelegram(e.target.value)}
                  />
                  <label
                    htmlFor="userTelegram"
                    className={`${style.firstHeading} ${style.labelFloat}`}
                  >
                    Telegram Username
                    {/* <span className="text-[14px] text-red-500 ml-1">*</span> */}
                  </label>
                  {/* 
                  <span className="absolute right-3 top-6 text-[14px] underline font-medium text-blue-500 font-poppins cursor-pointer">
                    Send OTP
                  </span>
                */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <DrawerFooter className="pt-2 flex flex-col  w-full">
          {userDetails && userDetails.profile_completion === 100 ? (
            <button
              onClick={handleUserProfile}
              className="w-full mt-6 bg-black disabled:cursor-not-allowed disabled:bg-[#000000]/50 text-white text-[18px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
            >
              Edit your Profile Data
            </button>
          ) : (
            <button
              onClick={handleUserProfile}
              disabled={!userPhoneNumber}
              className="w-full mt-6 bg-black disabled:cursor-not-allowed disabled:bg-[#000000]/50 text-white text-[18px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
            >
              {loading === true ? (
                <LoadingSpinner />
              ) : (
                " Save your Profile Data"
              )}
            </button>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default UserProfileModal;
