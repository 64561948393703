import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Plus,
  SearchIcon,
  CircleCheckBigIcon,
  XIcon,
  ChevronLeftIcon,
  SendIcon,
  PencilIcon,
  Trash2Icon,
  PlusIcon,
} from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import server from "../../utils/serverConfig";
import { v4 as uuidv4 } from "uuid";
import FormatDateTime from "../../utils/formatDateTime";
import { DatePicker } from "../../components/ui/date-picker";
import moment from "moment";
import NewSendAdviceModel from "../AdminHome/NewSendAdviceModal";
import { set } from "date-fns";
import LoadingSpinner from "../../components/LoadingSpinner";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full   px-3 py-3  bg-white text-[16px]  peer text-gray-900 placeholder:text-gray-400  placeholder:text-[14px] placeholder:font-normal   font-medium rounded-md mt-1 ring-1 hover:ring-2 ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",
  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full   ",
};

const ClientListNewDetailsTable = ({
  userId,
  groupName,
  adminEmail,
  advisorName,
  email_password,
  allClientsDataByGroup,
  isClientDataLoading,
  getAllClientsData,
  adminRationale
}) => {
  const data = allClientsDataByGroup && allClientsDataByGroup;
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [addClientModal, setAddClientModal] = useState(false);
  const [creationDate, setCreationDate] = useState(moment.now());
  const [newClientData, setNewClientData] = useState({
    clientId: uuidv4().slice(0, 7),
    clientEmail: "",
    clientName: "",
    phone: "",
    location: "",
    telegram: "",
    pan: "",
  });

  const [sendAdviceModal, setSendAdviceModal] = useState(false);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setIsAllSelected(selectedRows.length === filteredData.length);
  }, [selectedRows, filteredData]);

  const handleCheckboxChange = (item, isChecked) => {
    if (isChecked) {
      // Add to selectedRows
      setSelectedRows((prevSelected) => [...prevSelected, item]);
    } else {
      // Remove from selectedRows
      setSelectedRows((prevSelected) =>
        prevSelected.filter((row) => row.email !== item.email)
      );
    }
  };

  const handleSelectAllChange = (isChecked) => {
    if (isChecked) {
      // Select all items (store the entire objects)
      setSelectedRows(filteredData);
    } else {
      // Deselect all
      setSelectedRows([]);
    }
    setIsAllSelected(isChecked);
  };

  useEffect(() => {
    setFilteredData(
      data.filter((user) =>
        user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, data]);

  const openAddClientModal = () => {
    setAddClientModal(true);
  };

  const closeAddClientModal = () => {
    setAddClientModal(false);
  };

  const formatGroupName = (name) => {
    // Split the string by underscores
    const words = name.split("_");

    // Capitalize the first letter of each word and join them with spaces
    const formattedName = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return formattedName;
  };

  const handleAddNewClient = async () => {
    const allData = {
      clientId: newClientData.clientId,
      clientName: newClientData.clientName,
      email: newClientData.clientEmail,
      phone: newClientData.phone,
      groups: ["All Client", groupName],
      location: newClientData.location,
      telegram: newClientData.telegram,
      pan: newClientData.pan,
      creationDate: FormatDateTime(creationDate),
      comments: "",
      subscriptions: [],
    };

    // console.log("New Client data:", allData);

    try {
      // Send a POST request to add the new client
      const response = await fetch(
        `${server.server.baseUrl}api/add-new-client-to-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            clientData: allData,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Client added successfully:", result);

        // Reset the newClient form
        setNewClientData({
          clientId: uuidv4().slice(0, 7),
          clientName: "",
          clientEmail: "",
          phone: "",
          location: "",
          telegram: "",
          pan: "",
        });

        setAddClientModal(false);
        getAllClientsData(userId);
        toast.success("Client added to Group Successfully !!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      } else {
        console.error("Failed to add client:", result.message);
        setAddClientModal(false);
        toast.error("Error in adding New Client !", {
          duration: 3000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      console.error("Error adding client:", error);
      toast.error("Error in adding New Client !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleSendAdviceFromClientList = () => {
    if (selectedRows.length === 0) {
      toast.error("Please select the user(s) to send advice to.", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      setSendAdviceModal(false);
    } else {
      setSendAdviceModal(true);
    }
  };

  const closeSendAdviceModal = () => {
    setSendAdviceModal(false);
  };

  return (
    <div className="relative py-5 min-h-screen w-full items-center justify-center bg-[#F9F9F9] ">
      <Toaster position="top-center" reverseOrder={true} />

      <Link
        to="/admin/client-list"
        className="absolute top-[55px] left-5 flex items-center justify-center cursor-pointer  px-2 rounded-md  "
      >
        <ChevronLeftIcon className="h-4 w-4 text-[#000000]/60 cursor-pointer hover:scale-[1.07] " />
        <span className="text-xs font-medium font-poppins text-[#000000]/60 ">
          back
        </span>
      </Link>
      <div className="flex flex-row items-center justify-between px-8">
        <div className=" text-[22px] font-bold font-sans text-black capitalize">
          {groupName?.replaceAll(/_/g, " ")}
        </div>

        <div className="flex space-x-3">
          <div
            onClick={openAddClientModal}
            className=" py-2 px-3 bg-white border-[#000000] border-[2px] rounded-md flex flex-row justify-center items-center cursor-pointer "
          >
            <PlusIcon className="w-5 h-5 text-[#000000]" />{" "}
            <span className="pl-3 font-semibold text-[14px] font-poppins text-[#000000]">
              Add Client
            </span>
          </div>
          <div
            onClick={handleSendAdviceFromClientList}
            className=" py-2 px-4 bg-black border-[#000000] border-[2px] rounded-md flex flex-row justify-center items-center cursor-pointer "
          >
            <SendIcon className="w-5 h-5 text-white" />
            <span className="pl-3 font-medium text-[14px] font-poppins text-white">
              Send Advice
            </span>
          </div>
        </div>
      </div>

      {isClientDataLoading && allClientsDataByGroup.length === 0 ? (
        <div className="flex  items-center justify-center h-full w-full">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="mt-6 w-full h-full border-t-[1px]  border-[#000000]/10  max-h-[calc(100vh-225px)] lg:max-h-[calc(100vh-90px)] overflow-auto mb-4 ">
          <table className={`w-full`}>
            <thead className="bg-[#f5f5f5] z-20  sticky top-0">
              <tr className="border-b-[1px]  border-[#000000]/10">
                <th className="text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-[10px]">
                  <input
                    type="checkbox"
                    className="accent-[#16A085] w-4 h-4 rounded-[2px]"
                    checked={isAllSelected}
                    onChange={(e) => handleSelectAllChange(e.target.checked)}
                  />
                </th>
                <th className="text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-[10px]">
                  Client Id
                </th>
                <th className="text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins text-left font-medium py-3 px-[10px]">
                  Client Email
                </th>
                <th className="text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins text-left font-medium py-3 px-[10px]">
                  Client Name
                </th>
                <th className="text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-[10px]">
                  Phone
                </th>
                <th className="text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins text-left font-medium py-3 px-[10px]">
                  Location
                </th>

                <th className="text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins text-left font-medium py-3 px-[10px]">
                  Telegram Id
                </th>
                <th className="text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins text-left font-medium py-3 px-[10px]">
                  KYC
                </th>
                <th className="text-[13px] border-r-[1px]  border-[#000000]/10 text-[#000000]/80 font-poppins font-medium py-3 px-[10px]">
                  Acc Creation Date
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData &&
                filteredData?.map((item) => (
                  <tr
                    key={item.clientId}
                    className="border-b-[1px]   border-[#000000]/10"
                  >
                    <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-center py-4  px-[10px] ">
                      {" "}
                      <input
                        type="checkbox"
                        checked={selectedRows.some(
                          (row) => row.email === item.email
                        )}
                        onChange={(e) =>
                          handleCheckboxChange(item, e.target.checked)
                        }
                        className="accent-[#16A085] w-4 h-4 rounded-[2px]"
                      />
                    </td>
                    <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-center py-4  px-[10px] ">
                      {item.clientId}
                    </td>
                    <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-left  py-4  px-[10px] ">
                      {item.email}
                    </td>
                    <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium  py-4 text-left  px-[10px] ">
                      {item.clientName}
                    </td>

                    <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-right py-4  px-[10px] ">
                      {item.phone}
                    </td>

                    <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-left py-4  px-[10px] ">
                      {item.location}
                    </td>
                    <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-left py-4  px-[10px] ">
                      {item.telegram ? item.telegram : "-"}
                    </td>
                    <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-left py-4  px-[10px] ">
                      {item.pan ? item.pan : "-"}
                    </td>
                    <td className="text-[15px] border-r-[1px]  border-[#000000]/10 text-[#000000] font-poppins font-medium text-center py-4  px-[10px] ">
                      {FormatDateTime(item.creationDate)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {addClientModal === true ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 ">
          <div className="relative animate-modal">
            <div
              className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
              onClick={closeAddClientModal}
            >
              <XIcon className="w-6 h-6 mr-2" />
            </div>
            <div className="w-full sm:w-[650px] px-8 sm:px-12 py-8 bg-white  border-[#000000]/20 rounded-md">
              <div className="text-[26px] font-semibold font-poppins text-center ">
                Add New Client
              </div>
              <div className="mt-6 text-[18px] font-normal font-poppins text-center ">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6">
                  <div className={`flex flex-col`}>
                    <label
                      htmlFor="clientId"
                      className={`text-[#000000] text-sm  text-left font-medium`}
                    >
                      Client Id
                      <span className="ml-1 text-red-500 text-[14px]">*</span>
                    </label>
                    <input
                      id="clientId"
                      name="clientId"
                      type="text"
                      placeholder="Enter Client Id"
                      className={`${style.inputBox}`}
                      value={newClientData.clientId}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div className={`flex flex-col`}>
                    <label
                      htmlFor="clientEmail"
                      className={`text-[#000000] text-sm  text-left font-medium`}
                    >
                      Client Email
                      <span className="ml-1 text-red-500 text-[14px]">*</span>
                    </label>
                    <input
                      id="clientEmail"
                      name="clientEmail"
                      type="text"
                      placeholder="Enter Client Email"
                      className={`${style.inputBox}`}
                      value={newClientData.clientEmail}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div className={`flex flex-col`}>
                    <label
                      htmlFor="clientName"
                      className={`text-[#000000] text-sm  text-left font-medium`}
                    >
                      Client Name
                      <span className="ml-1 text-red-500 text-[14px]">*</span>
                    </label>
                    <input
                      id="clientName"
                      name="clientName"
                      type="text"
                      placeholder="Enter Client Name"
                      className={`${style.inputBox}`}
                      value={newClientData.clientName}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>

                  <div className={`flex flex-col`}>
                    <label
                      htmlFor="phone"
                      className={`text-[#000000] text-sm  text-left font-medium`}
                    >
                      Phone Number
                      <span className="ml-1 text-red-500 text-[14px]">*</span>
                    </label>
                    <input
                      id="phone"
                      name="phone"
                      type="number"
                      placeholder="Enter Phone Number"
                      className={`${style.inputBox}`}
                      value={newClientData.phone}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>

                  <div className={`flex flex-col`}>
                    <label
                      htmlFor="location"
                      className={`text-[#000000] text-sm  text-left font-medium`}
                    >
                      Location
                    </label>
                    <input
                      id="location"
                      name="location"
                      type="text"
                      placeholder="Enter Location"
                      className={`${style.inputBox}`}
                      value={newClientData.location}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div className={`flex flex-col`}>
                    <label
                      htmlFor="telegram"
                      className={`text-[#000000] text-sm  text-left font-medium`}
                    >
                      Telegram Id
                      {/* <span className="ml-1 text-red-500 text-[14px]">*</span> */}
                    </label>
                    <input
                      id="telegram"
                      name="telegram"
                      type="text"
                      placeholder="Enter Telegram Id"
                      className={`${style.inputBox}`}
                      value={newClientData.telegram}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>

                  <div className={`flex flex-col`}>
                    <label
                      htmlFor="pan"
                      className={`text-[#000000] text-sm  text-left font-medium`}
                    >
                      KYC
                      {/* <span className="ml-1 text-red-500 text-[14px]">*</span> */}
                    </label>
                    <input
                      id="pan"
                      name="pan"
                      type="text"
                      placeholder="Enter KYC"
                      className={`${style.inputBox}`}
                      value={newClientData.pan}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>

                  <div className={`flex flex-col`}>
                    <label
                      htmlFor="creationDate"
                      className={`text-[#000000] text-sm  text-left font-medium`}
                    >
                      Creation Date
                      <span className="ml-1 text-red-500 text-[14px]">*</span>
                    </label>
                    <div className="mt-1">
                      <DatePicker
                        className="bg-white py-[14px] "
                        date={creationDate}
                        setDate={setCreationDate}
                        placeholder={"Choose Creation Date"}
                      />
                    </div>
                  </div>
                </div>

                <button
                  disabled={
                    !newClientData.clientId ||
                    !newClientData.phone ||
                    !newClientData.clientName ||
                    !newClientData.clientEmail
                  }
                  onClick={handleAddNewClient}
                  className="w-full disabled:cursor-not-allowed disabled:bg-[#000000]/20 mt-6 bg-black text-white text-[18px] font-medium font-poppins rounded-md px-10 py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
                >
                  Add New Client
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {sendAdviceModal === true ? (
        <NewSendAdviceModel
          fileName={groupName}
          closeSendAdviceModal={closeSendAdviceModal}
          data={selectedRows}
          adminEmail={adminEmail}
          advisorName={advisorName}
          email_password={email_password}
          setSendAdviceModal={setSendAdviceModal}
          setSelectedRows={setSelectedRows}
          adminRationale={adminRationale}
        />
      ) : null}
    </div>
  );
};

export default ClientListNewDetailsTable;
