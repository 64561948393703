import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";

import MPF_1 from "../../assests/mpf_1.png";
import server from "../../utils/serverConfig";
import LoadingSpinner from "../../components/LoadingSpinner";

let interval;

const StackingCards = ({
  userEmail,
  userBroker,
  data,
  setOpenRebalanceModal,
  setCaluculatedPortfolioData,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    // startFlipping()
  };

  const CARD_OFFSET = 10;
  const SCALE_FACTOR = 0.06;
  const OPACITY_FACTOR = 0.12;
  const [cards, setCards] = useState(data);
  let currentFlipIndex = 0;

  useEffect(() => {
    startFlipping();

    return () => clearInterval(interval);
  }, []);

  const startFlipping = () => {
    currentFlipIndex = 0; // Start with the first index

    interval = setInterval(() => {
      setCards((prevCards) => {
        const newArray = [...prevCards]; // Create a copy of the array
        newArray.unshift(newArray.pop()); // Move the last element to the front

        // Call handleDotClick with the current flip index
        handleDotClick(currentFlipIndex);

        // Increment the index and reset if it exceeds the cards length - 1
        currentFlipIndex = (currentFlipIndex + 1) % cards.length;

        return newArray;
      });
    }, 4000);
  };
  const [loading, setLoading] = useState(false);
  const handleAcceptRebalance = () => {
    setLoading(true);
    let data = JSON.stringify({
      userEmail: userEmail,
      userBroker: userBroker,
      modelName: "Ravi",
    });
    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}rebalance/calculate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setCaluculatedPortfolioData(response.data);
        setOpenRebalanceModal(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <div className="relative  h-60 w-60 md:h-60 md:w-96">
      {cards.map((card, index) => {
        return (
          <motion.div
            key={index}
            className="absolute bg-[#2A587C]  w-60 h-full md:w-96 px-6 py-8 rounded-xl  shadow-xl   shadow-black/[0.1]  flex flex-col justify-between"
            style={{
              transformOrigin: "top center",
            }}
            animate={{
              top: index * -CARD_OFFSET,
              scale: 1 - index * SCALE_FACTOR,
              opacity: 1 - index * OPACITY_FACTOR, // decrease scale for cards that are behind
              zIndex: cards.length - index, //  decrease z-index for the cards that are behind
            }}
            transition={{
              duration: 0.4,
            }}
          >
            <span className="text-[10px] text-[#D0D2D5] font-normal font-poppins">
              Rebalance Update
            </span>
            <div className="flex space-x-4">
              {card?.image ? (
                <img
                  src={`${server.server.baseUrl}${card?.image}`}
                  alt={card?.model_name}
                  className="w-[70px] h-[60px]"
                />
              ) : (
                <img
                  src={MPF_1}
                  alt={card?.model_name}
                  className="w-[70px] h-[60px]"
                />
              )}

              <div className="flex flex-col space-y-1">
                <div className="text-[18px] text-[#ffffff] font-semibold font-poppins ">
                  {card?.model_name}
                </div>
                <div className="text-[12px] text-[#D0D2D5] font-normal font-poppins ">
                  {card?.overView.split(" ").slice(0, 10).join(" ")}
                </div>
              </div>
            </div>
            <button
              className=" w-[160px] px-4 py-2 bg-white text-[#000000]/80 font-medium text-[14px] font-poppins rounded"
              onClick={handleAcceptRebalance}
            >
              {loading === true ? <LoadingSpinner /> : "See All Updates"}
            </button>

            <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex items-center space-x-2">
              {cards.map((_, index) => (
                <div
                  key={index}
                  className={` cursor-pointer w-2 h-2 rounded-full bg-[#ffffff]/20 flex items-center justify-center ${
                    index === currentIndex
                      ? "w-6 h-3 bg-[#ffffff]/20 text-blue-700 p-[1px]"
                      : ""
                  }`}
                >
                  {index === currentIndex && (
                    <span className="text-[9px] text-[#ffffff] font-medium font-poppins ">
                      {currentIndex + 1}/{cards.length}
                    </span>
                  )}
                </div>
              ))}
            </div>
          </motion.div>
        );
      })}
    </div>
  );
};

export default StackingCards;
