import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  X,
  CircleCheckBigIcon,
  PlusIcon,
  CloudUploadIcon,
  XIcon,
} from "lucide-react";
import Excel from "../../assests/Icon/Excel.svg";
import { useNavigate } from "react-router-dom";
import TradeFailureCard from "./TradeFailureCard";
import toast, { Toaster } from "react-hot-toast";
import server from "../../utils/serverConfig";
import NoTradeFailureModal from "./NoTradeFailureModal";
import TradeInconsistencyCard from "./TradeInconsistencyCard";
import NotificationModal from "./NotificationModal";
import OpenPositionsTable from "./OpenPositionsTable";
// import { FaCaretRight } from "react-icons/fa";

import CSVIcon from "../../assests/csv_icon.svg";
import BellIcon from "../../assests/bell_icon.svg";
import HoldingsTable from "./HoldingsTable";
import GroupListBlock from "./GroupListBlock";
import AdminEmptyState from "./AdminEmptyState";
import FloatAdviceTable from "./FloatAdviceTable";
import ImprovedExcelLikeTable from "./FloatNewTable";
import { io } from "socket.io-client";

const style = {
  selected:
    "   text-[14px] lg:text-[16px] text-black font-bold  border-b-[3px] border-black cursor-pointer",
  unselected:
    "   font-medium  text-[14px] lg:text-[16px]  text-[#000000]/40 cursor-pointer",
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full   px-3 py-3  bg-white text-[16px]  peer text-gray-900 placeholder:text-gray-400 placeholder:font-normal   font-medium rounded-md mt-1.5 ring-1 hover:ring-2 ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full   ",
  selected_2:
    "flex items-center text-[16px] leading-[14px] lg:text-[18px]  font-poppins text-black font-semibold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected_2:
    "flex items-center text-[16px] leading-[14px] font-medium font-poppins lg:text-[18px]  text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const ClientListNew = ({
  userId,
  adminEmail,
  advisorName,
  email_password,
  disclaimerData,
  adminRationale
}) => {
  const navigate = useNavigate();

  const [allCsvFileData, setAllCsvFileData] = useState();
  const [allGroupsData, setAllGroupsData] = useState([]);
  const [allClientsData, setAllClientsData] = useState([]);

  const getAllGroupsData = async (userId) => {
    try {
      const response = await fetch(
        `${server.server.baseUrl}api/all-groups/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch groups");
      }

      const data = await response.json();

      // // Assuming the group names are in the `data` array
      const groupNames = data.data;

      setAllGroupsData(data.data);
      // const allClientsDetails = groupNames.flatMap((group) => group.data);
      // console.log("Cleints Details data:", allClientsDetails);

      // setAllClientsData(allClientsDetails);
    } catch (error) {
      console.error("Error fetching group names:", error);
      return [];
    }
  };

  const getAllClientsData = async (userId) => {
    try {
      const response = await fetch(
        `${server.server.baseUrl}api/all-clients/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch cleint data");
      }

      const data = await response.json();

      // // Assuming the group names are in the `data` array
      const clientsData = data.data;

      setAllClientsData(clientsData);
    } catch (error) {
      console.error("Error fetching Client data:", error);
      return [];
    }
  };

  const getAllCsvData = useCallback(() => {
    if (userId) {
      axios
        .get(`${server.server.baseUrl}api/csv-upload/${userId}`)
        .then((res) => {
          setAllCsvFileData(res.data.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [userId]);

  useEffect(() => {
    getAllCsvData();
    getAllGroupsData(userId);
    getAllClientsData(userId);
  }, [getAllCsvData]);
  const csvFor = "client";

  const filteredClientCsv =
    allCsvFileData && allCsvFileData.filter((item) => item.csvFor === "client");

  const [show, setShow] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [showComponent, setShowComponent] = useState(false);
  const [newFileName, setNewFileName] = useState("");
  const [uploading, setUploading] = useState(false);

  // Function to handle file selection
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setCsvFile(selectedFile);
    }
  };

  const cancelUpload = () => {
    setUploading(false);
    setCsvFile(null);
  };

  // Function to handle CSV file upload
  const handleFileUpload = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (!csvFile) {
      console.error("No file selected.");
      return;
    }
    if (!newFileName || newFileName?.length < 0) {
      console.error("List Name not Specified !!");
      return;
    }
    const formattedFileName = newFileName?.toLowerCase().replace(/ /g, "_");
    const formData = new FormData();
    formData.append("csv", csvFile);
    formData.append("userId", userId);
    formData.append("csvFor", csvFor);
    formData.append("fileName", formattedFileName);

    try {
      const response = await axios.post(
        `${server.server.baseUrl}api/csv-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
        setShowComponent(true);
        setShow(false);
        closeClientListModal();
        setCsvFile(null);
        navigate(
          `/admin/client-list/${newFileName?.toLowerCase().replace(/ /g, "_")}`
        );
        toast.success("Client List Added Successfully !!", {
          duration: 3000,
          style: {
        background: "white",
        color: "#1e293b",
        maxWidth: "500px",
        fontWeight: 600,
        fontSize: "13px",
        padding: "10px 20px",
      },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      toast.error("Error Adding Client List !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  useEffect(() => {
    if (showComponent) {
      setShowComponent(true);
      const toRef = setTimeout(() => {
        setShowComponent(false);
        setShow(false);
        clearTimeout(toRef);
      }, 3000);
    }
  }, [showComponent]);

  const [loadingSampleCsv, setLoadingSampleCsv] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedTab, setSelectedTab] = useState("missed-trades");

  const downloadCSV = () => {
    setLoadingSampleCsv(true);

    setTimeout(() => {
      const csvContent = `email,name,client_id,advisor_tag,\njohndoe@example.com,Ravendra Prajapati,68451704,Long Term`;
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "sampleClient.csv";
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      setLoadingSampleCsv(false);
      setShowSuccessMessage(true);

      // Reset success message after 3 seconds
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    }, 2000); // Simulate a 2-second delay for download
  };

  const [clientListModal, setClientListModal] = useState(false);

  const openClientListModal = () => {
    setClientListModal(true);
  };

  const closeClientListModal = () => {
    setClientListModal(false);
  };

  const handleDivClickFileUpload = () => {
    document.getElementById("fileInput").click();
  };

  const [tradeFailureModal, setTradeFailureModal] = useState(false);
  const [tradeInconsistencyModal, setTradeInconsistencyModal] = useState(false);
  const [missedTradesModal, setMissedTradesModal] = useState(false);

  const [notificationModal, setNotificationModal] = useState(false);
  const [clientListDownloadModal, setClientListDownloadModal] = useState(false);

  const openNotificationModal = () => {
    setNotificationModal(true);
  };

  const closeNotificationModal = () => {
    setNotificationModal(false);
  };

  const openClientListDownloadModal = () => {
    setClientListDownloadModal(true);
  };

  const closeClientListDownloadModal = () => {
    setClientListDownloadModal(false);
  };

  const [allRecommendations, setAllRecommendations] = useState([]);

  const getAllRecommendations = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/trade-history/trade-history-by-trade-given-by?trade_given_by=${adminEmail}`,
    };

    axios
      .request(config)
      .then((response) => {
        setAllRecommendations(response.data.trades);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllRecommendations();
  }, []);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const [selectedUser, setSelectedUser] = useState("");
  const [userEmailCount, setUserEmailCount] = useState("");

  const filteredInconsistencyTrades = allRecommendations?.filter((trade) => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    const tradeDate = new Date(trade.date);

    return (
      ((trade.trade_place_status === "Placed" &&
        trade.Quantity !== trade.tradedQty) ||
        (trade.trade_place_status === "complete" &&
          trade.Quantity !== trade.tradedQty) ||
        (trade.trade_place_status === "Executed" &&
          trade.Quantity !== trade.tradedQty)) &&
      tradeDate >= sevenDaysAgo
    );
  });

  const inconsistencyTradesEmailCounts = Array.from(
    new Set(filteredInconsistencyTrades?.map((trade) => trade.user_email))
  );
  const filteredFailuresTrades = allRecommendations?.filter((trade) => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    const tradeDate = new Date(trade.date);

    return trade.trade_place_status === "rejected" && tradeDate >= sevenDaysAgo;
  });

  const failuresTradesEmailCounts = Array.from(
    new Set(filteredFailuresTrades?.map((trade) => trade.user_email))
  );

  const filteredMissedTrades = allRecommendations?.filter((trade) => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    const tradeDate = new Date(trade.date);

    return (
      trade.trade_place_status === "recommend" && tradeDate >= sevenDaysAgo
    );
  });

  const missedTradesEmailCounts = Array.from(
    new Set(filteredMissedTrades?.map((trade) => trade.user_email))
  );

  const [selectedPositionTab, setSelectedPositionTab] = useState("holdings");

  const csvHeaders = [
    { label: "group_name", key: "group_name" },
    { label: "email", key: "email" },
    { label: "name", key: "name" },
    { label: "client_id", key: "client_id" },
    { label: "advisor_tag", key: "advisor_tag" },
    { label: "portfolio_size", key: "portfolio_size" },
    { label: "updated_at", key: "updated_at" },
  ];

  const allClientInSingleList =
    filteredClientCsv &&
    filteredClientCsv?.flatMap((file) =>
      file.data.map((client) => ({
        group_name: file.fileName,
        email: client.email,
        name: client.name,
        client_id: client.client_id,
        advisor_tag: client.advisor_tag,
        portfolio_size: client.user_broker ? client.user_broker : "-",
        updated_at: client.updated_at ? client.updated_at : "-",
      }))
    );

  const [showFloatAdviceTableModal, setShowFloatAdviceTableModal] =
    useState(false);

  const closeFloatModal = () => {
    setShowFloatAdviceTableModal(false);
  };

  const openFloatModal = () => {
    setShowFloatAdviceTableModal(true);
  };

  // all client holding data
  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [loadingHoldingData, setLoadingHoldingData] = useState(false);
  const getAllHoldingsData = useCallback(() => {
    setLoadingHoldingData(true);
    if (adminEmail) {
      axios
        .get(`${server.server.baseUrl}api/portfolio`, {
          params: { email: adminEmail },
        })
        .then((res) => {
          setData(res.data.filteredData);
          setLoadingHoldingData(false);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, []);

  useEffect(() => {
    getAllHoldingsData();
  }, [getAllHoldingsData]);

  useEffect(() => {
    const grouped = data.reduce((acc, item) => {
      if (item.holding) {
        const symbol = item.holding.symbol;
        if (!acc[symbol]) {
          acc[symbol] = [];
        }
        acc[symbol].push(item);
      }
      return acc;
    }, {});
    setGroupedData(grouped);
  }, [data]);

  // all client positions data
  const [positionsData, setPositionsData] = useState([]);
  const [positionsGroupedData, setPositionsGroupedData] = useState({});

  const getAllPositionsData = useCallback(() => {
    if (adminEmail) {
      let config = {
        method: "get",
        url: `${server.server.baseUrl}api/positions/admin/specific-admin?email=${adminEmail}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setPositionsData(response.data.data ? response.data.data : []);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    getAllPositionsData();
  }, [getAllPositionsData]);

  useEffect(() => {
    const grouped =
      positionsData &&
      positionsData?.reduce((acc, item) => {
        if (item.Symbol) {
          const symbol = item.Symbol;
          if (!acc[symbol]) {
            acc[symbol] = [];
          }
          acc[symbol].push(item);
        }
        return acc;
      }, {});
    setPositionsGroupedData(grouped);
  }, [positionsData]);

  const [ltp, setLtp] = useState([]);

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize WebSocket connection
    const newSocket = io("wss://ccxt.alphaquark-case.com", {
      transports: ["websocket"],
      query: { EIO: "4" },
    });

    newSocket.on("connect", () => {
      // console.log("Connected to Socket.IO server");
    });

    newSocket.on("market_data", (data) => {
      setLtp((prev) => {
        const index = prev.findIndex(
          (item) => item.tradingSymbol === data.stockSymbol
        );
        if (index !== -1) {
          const newLtp = [...prev];
          newLtp[index] = {
            ...newLtp[index],
            lastPrice: data.last_traded_price,
          };
          return newLtp;
        } else {
          const updatedLtp = [
            ...prev,
            {
              tradingSymbol: data.stockSymbol,
              lastPrice: data.last_traded_price,
            },
          ];
          return updatedLtp;
        }
      });
    });

    newSocket.on("disconnect", () => {
      // console.log("Disconnected from Socket.IO server");
    });

    const pingInterval = setInterval(() => {
      if (newSocket.connected) {
        newSocket.emit("ping");
      }
    }, 20000);

    setSocket(newSocket);

    return () => {
      if (newSocket) newSocket.disconnect();
    };
  }, []);

  const getCurrentPrice = () => {
    if (!positionsData || positionsData.length === 0) {
      // console.log("No trades available to fetch prices for");
      return;
    }

    const uniqueSymbols = new Set();
    const symbolsToSubscribe = positionsData
      .map((trade) => ({
        exchange: trade.Exchange,
        tradingSymbol: trade.Symbol,
      }))
      .filter((order) => {
        if (uniqueSymbols.has(order.tradingSymbol)) {
          return false;
        } else {
          uniqueSymbols.add(order.tradingSymbol);
          return true;
        }
      });

    symbolsToSubscribe.forEach((symbol) => {
      const data = {
        symbol: symbol.tradingSymbol,
        exchange: symbol.exchange,
      };

      axios
        .post("https://ccxt.alphaquark-case.com/websocket/subscribe", data)
        .then((response) => {
          // console.log(`Subscribed to ${symbol.tradingSymbol}:`, response.data);
        })
        .catch((error) => {
          // console.error(`Error subscribing to ${symbol.tradingSymbol}:`, error);
        });
    });
  };

  useEffect(() => {
    if (positionsData && positionsData.length > 0) {
      getCurrentPrice();
    }
  }, [positionsData]);

  const getLTPForSymbol = (symbol) => {
    // Remove suffixes from the input symbol
    const ltpOne = ltp.find((item) => {
      return item.tradingSymbol === symbol;
    })?.lastPrice;
    return ltpOne ? ltpOne.toFixed(2) : "-";
  };

  const [isEmptyState, setIsEmptyState] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsEmptyState(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="relative rounded-lg  w-full min-h-screen  bg-[#f9f9f9] py-24 text-3xl font-semibold font-sans flex items-center justify-center">
          <svg
            className="h-10 w-10 text-[#000000] animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : (
        <>
          {allGroupsData.length !== 0 ? (
            <div className="flex max-h-screen overflow-scroll lg:overflow-hidden  lg:min-h-screen w-full items-center justify-center bg-neutral-200 pt-6 lg:px-10 ">
              <Toaster position="top-center" reverseOrder={true} />
              {isLoading ? (
                <div className="relative rounded-lg  w-full min-h-screen  bg-[#f9f9f9] py-24 text-3xl font-semibold font-sans flex items-center justify-center">
                  <svg
                    className="h-10 w-10 text-[#000000] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : (
                allGroupsData.length > 0 && (
                  <div className="relative flex flex-col  w-full h-full ">
                    {/* Send Advice and Client List  Blocks */}
                    <div className="flex flex-wrap lg:flex-nowrap space-y-4 lg:space-y-0 lg:space-x-4 h-auto lg:h-[330px] ">
                      {/* New Group Block  */}

                      <GroupListBlock
                        userId={userId}
                        allClientsData={allClientsData}
                        allGroupsData={allGroupsData}
                        adminEmail={adminEmail}
                        advisorName={advisorName}
                        email_password={email_password}
                        adminRationale={adminRationale}
                      />

                      {/* Trade  Inconsistency Block  */}

                      {filteredFailuresTrades?.length === 0 &&
                      filteredInconsistencyTrades?.length === 0 &&
                      filteredMissedTrades?.length === 0 ? (
                        <NoTradeFailureModal />
                      ) : (
                        <div className="flex flex-col h-auto lg:h-[330px] w-full lg:w-[55%] bg-[#ffffff]   rounded-lg">
                          <div className="flex space-x-1 overflow-x-auto md:space-x-6 gap-y-3 gap-x-3 w-full border-b-[1px] border-[#000000]/10  h-[50px] xl:h-[50px] px-4  rounded-t-lg ">
                            {filteredFailuresTrades?.length !== 0 ? (
                              <div
                                className={`${
                                  selectedTab === "trade-failure"
                                    ? style.selected
                                    : style.unselected
                                } flex font-sans items-center justify-center min-w-[150px] `}
                                onClick={() => {
                                  setSelectedTab("trade-failure");
                                }}
                              >
                                Trade Failures
                                {filteredFailuresTrades &&
                                  failuresTradesEmailCounts.length > 0 && (
                                    <span
                                      className={`ml-2 flex items-center text-[14px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-6 h-6 rounded-full ${
                                        selectedTab !== "trade-failure"
                                          ? "bg-[#000000]/30"
                                          : "bg-[#E43D3D]"
                                      }`}
                                    >
                                      {filteredFailuresTrades
                                        ? failuresTradesEmailCounts.length
                                        : ""}
                                    </span>
                                  )}
                              </div>
                            ) : (
                              <div
                                className={`flex font-sans items-center justify-center  font-medium  text-sm xl:text-[16px]  text-[#000000]/40  cursor-not-allowed  `}
                              >
                                Trade Failures
                              </div>
                            )}

                            {filteredInconsistencyTrades?.length !== 0 ? (
                              <div
                                className={`${
                                  selectedTab === "trade-inconsistency"
                                    ? ` ${style.selected}`
                                    : ` ${style.unselected}`
                                } flex font-sans items-center justify-center min-w-[200px] `}
                                onClick={() => {
                                  setSelectedTab("trade-inconsistency");
                                }}
                              >
                                Trade Inconsistencies
                                {filteredInconsistencyTrades &&
                                  inconsistencyTradesEmailCounts?.length >
                                    0 && (
                                    <span
                                      className={`ml-2 flex items-center text-[14px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-6 h-6 rounded-full ${
                                        selectedTab !== "trade-inconsistency"
                                          ? "bg-[#000000]/30"
                                          : "bg-[#E43D3D]"
                                      }`}
                                    >
                                      {filteredInconsistencyTrades
                                        ? inconsistencyTradesEmailCounts?.length
                                        : ""}
                                    </span>
                                  )}
                              </div>
                            ) : (
                              <div
                                className={`flex font-sans items-center justify-center  font-medium  text-sm xl:text-[16px]  text-[#000000]/40  cursor-not-allowed min-w-[160px]`}
                              >
                                Trade Inconsistencies
                              </div>
                            )}
                            {filteredMissedTrades?.length !== 0 ? (
                              <div
                                className={`${
                                  selectedTab === "missed-trades"
                                    ? ` ${style.selected}`
                                    : ` ${style.unselected}`
                                } flex font-sans items-center justify-center min-w-[150px]  `}
                                onClick={() => {
                                  setSelectedTab("missed-trades");
                                }}
                              >
                                Missed Trades
                                {filteredMissedTrades &&
                                  missedTradesEmailCounts?.length > 0 && (
                                    <span
                                      className={`ml-2 flex items-center text-[14px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-6 h-6 rounded-full ${
                                        selectedTab !== "missed-trades"
                                          ? "bg-[#000000]/30"
                                          : "bg-[#E43D3D]"
                                      }`}
                                    >
                                      {filteredMissedTrades
                                        ? missedTradesEmailCounts?.length
                                        : ""}
                                    </span>
                                  )}
                              </div>
                            ) : (
                              <div
                                className={`flex font-sans items-center justify-center  font-medium  text-sm xl:text-[16px]  text-[#000000]/40  cursor-not-allowed`}
                              >
                                Missed Trades
                              </div>
                            )}
                          </div>

                          <div className="w-full grid grid-cols-5 items-center px-4 h-[50px] border-b-[1px] border-[#000000]/10 ">
                            <div className="text-[#000000]/60 text-[14px] font-poppins font-medium col-span-1">
                              Issues
                            </div>
                            <div className=" text-[#000000]/60 text-[14px] font-poppins font-medium col-span-3">
                              Email Id
                            </div>
                            <div className=" text-[#000000]/60 text-[14px] font-poppins font-medium col-span-1">
                              Actions
                            </div>
                          </div>

                          {selectedTab === "trade-failure" && (
                            <div className="flex flex-col  h-[calc(330px-110px)] overflow-y-scroll custom-scroll  ">
                              {Array.from(
                                new Set(
                                  filteredFailuresTrades?.map(
                                    (trade) => trade.user_email
                                  )
                                )
                              ).map((email, i) => {
                                const ele = filteredFailuresTrades?.find(
                                  (trade) => trade?.user_email === email
                                );
                                const emailCount =
                                  filteredFailuresTrades?.filter(
                                    (trade) => trade?.user_email === email
                                  ).length;
                                return (
                                  <TradeFailureCard
                                    userEmail={ele?.user_email}
                                    emailCount={emailCount}
                                    tradeFailureModal={tradeFailureModal}
                                    setTradeFailureModal={setTradeFailureModal}
                                    selectedTab={selectedTab}
                                    setSelectedTab={setSelectedTab}
                                    key={i}
                                    filteredFailuresTrades={
                                      filteredFailuresTrades &&
                                      filteredFailuresTrades
                                    }
                                    filteredInconsistencyTrades={
                                      filteredInconsistencyTrades &&
                                      filteredInconsistencyTrades
                                    }
                                    filteredMissedTrades={
                                      filteredMissedTrades &&
                                      filteredMissedTrades
                                    }
                                    setSelectedUser={setSelectedUser}
                                    selectedUser={selectedUser}
                                    setUserEmailCount={setUserEmailCount}
                                    userEmailCount={userEmailCount}
                                  />
                                );
                              })}
                            </div>
                          )}

                          {selectedTab === "trade-inconsistency" && (
                            <div className="flex flex-col  h-[calc(330px-110px)] overflow-y-scroll custom-scroll  ">
                              {Array.from(
                                new Set(
                                  filteredInconsistencyTrades?.map(
                                    (trade) => trade?.user_email
                                  )
                                )
                              ).map((email, i) => {
                                const ele = filteredInconsistencyTrades?.find(
                                  (trade) => trade?.user_email === email
                                );
                                const emailCount =
                                  filteredInconsistencyTrades?.filter(
                                    (trade) => trade?.user_email === email
                                  ).length;
                                return (
                                  <TradeInconsistencyCard
                                    userEmail={ele?.user_email}
                                    emailCount={emailCount}
                                    tradeInconsistencyModal={
                                      tradeInconsistencyModal
                                    }
                                    setTradeInconsistencyModal={
                                      setTradeInconsistencyModal
                                    }
                                    selectedTab={selectedTab}
                                    setSelectedTab={setSelectedTab}
                                    key={i}
                                    filteredFailuresTrades={
                                      filteredFailuresTrades &&
                                      filteredFailuresTrades
                                    }
                                    filteredInconsistencyTrades={
                                      filteredInconsistencyTrades &&
                                      filteredInconsistencyTrades
                                    }
                                    filteredMissedTrades={
                                      filteredMissedTrades &&
                                      filteredMissedTrades
                                    }
                                    setSelectedUser={setSelectedUser}
                                    selectedUser={selectedUser}
                                    userEmailCount={userEmailCount}
                                    setUserEmailCount={setUserEmailCount}
                                  />
                                );
                              })}
                            </div>
                          )}

                          {selectedTab === "missed-trades" && (
                            <div className="flex flex-col  h-[calc(330px-110px)] overflow-y-scroll custom-scroll  ">
                              {Array.from(
                                new Set(
                                  filteredMissedTrades?.map(
                                    (trade) => trade?.user_email
                                  )
                                )
                              ).map((email, i) => {
                                const ele = filteredMissedTrades?.find(
                                  (trade) => trade?.user_email === email
                                );
                                const emailCount = filteredMissedTrades?.filter(
                                  (trade) => trade?.user_email === email
                                ).length;
                                return (
                                  <TradeInconsistencyCard
                                    userName="User-1"
                                    userEmail={ele?.user_email}
                                    emailCount={emailCount}
                                    tradeInconsistencyModal={tradeFailureModal}
                                    setTradeInconsistencyModal={
                                      setTradeFailureModal
                                    }
                                    selectedTab={selectedTab}
                                    setSelectedTab={setSelectedTab}
                                    key={i}
                                    filteredFailuresTrades={
                                      filteredFailuresTrades &&
                                      filteredFailuresTrades
                                    }
                                    filteredInconsistencyTrades={
                                      filteredInconsistencyTrades &&
                                      filteredInconsistencyTrades
                                    }
                                    filteredMissedTrades={
                                      filteredMissedTrades &&
                                      filteredMissedTrades
                                    }
                                    setSelectedUser={setSelectedUser}
                                    selectedUser={selectedUser}
                                    userEmailCount={userEmailCount}
                                    setUserEmailCount={setUserEmailCount}
                                  />
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    {/* Open Positions Table  */}

                    <div className="h-full bg-white flex flex-col justify-between mt-4 lg:h-[calc(100vh-370px)] lg:pt-2 lg:mt-4 rounded-lg">
                      <div className=" grid grid-cols-2  md:space-x-6 md:flex flex-row lg:px-8   border-b border-[#000000]/10  h-[50px] lg:h-[50px] font-poppins">
                        <button
                          className={`
                  ${
                    selectedPositionTab === "holdings"
                      ? ` ${style.selected_2}`
                      : ` ${style.unselected_2}`
                  }
                 px-4 lg:min-w-[120px] justify-center`}
                          onClick={() => {
                            setSelectedPositionTab("holdings");
                          }}
                        >
                          Holdings
                        </button>
                        <button
                          className={`
                  ${
                    selectedPositionTab === "positions"
                      ? ` ${style.selected_2}`
                      : ` ${style.unselected_2}`
                  }
                 px-4 lg:min-w-[120px] justify-center `}
                          onClick={() => {
                            setSelectedPositionTab("positions");
                          }}
                        >
                          Positions
                        </button>
                      </div>

                      {selectedPositionTab === "holdings" && (
                        <HoldingsTable
                          adminEmail={adminEmail}
                          advisorName={advisorName}
                          email_password={email_password}
                          groupedData={groupedData}
                          data={data}
                          loadingHoldingData={loadingHoldingData}
                        />
                      )}

                      {selectedPositionTab === "positions" && (
                        <div className="flex items-center justify-center h-[calc(100vh-460px)] pb-2">
                          <OpenPositionsTable
                            adminEmail={adminEmail}
                            advisorName={advisorName}
                            email_password={email_password}
                            groupedData={positionsGroupedData}
                            data={positionsData}
                            getLTPForSymbol={getLTPForSymbol}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )
              )}

              {/* Floating Button for Send Advice Table 

          <div
            onClick={openFloatModal}
            className="z-50 absolute bottom-16 lg:bottom-6 right-[44px] flex items-center justify-center bg-black cursor-pointer shadow-2xl rounded-full h-[60px] w-[60px]"
          >
            <SendIcon className="text-white h-6 w-6" />
          </div>
           */}

              {showFloatAdviceTableModal && (
                <div className="w-full fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 ">
                  <div className="relative mt-6 md:pb-4  max-w-[100vw] w-full bg-[#ffffff] animate-modal">
                    <div
                      className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
                      onClick={closeFloatModal}
                    >
                      <XIcon className="w-6 h-6 mr-2" />
                    </div>
                    <div className="w-full px-4 pt-10 pb-4 md:px-8 md:py-8 bg-white  border-[#000000]/20 ">
                      <div className=" text-[18px] lg:text-[26px] font-semibold font-sans text-center capitalize ">
                        Send Advice by Email
                      </div>

                      <div className="flex flex-col w-full mt-4  h-[600px] bg-white">
                        {/*  
                    <FloatAdviceTable />
               
                    */}
                        <ImprovedExcelLikeTable />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              {isEmptyState ? (
                <div className="relative rounded-lg  w-full min-h-screen  bg-[#f9f9f9] py-24 text-3xl font-semibold font-sans flex items-center justify-center">
                  <svg
                    className="h-10 w-10 text-[#000000] animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              ) : (
                <AdminEmptyState
                  adminEmail={adminEmail}
                  disclaimerData={disclaimerData}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ClientListNew;
